import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { startCase } from "lodash";
import { Fade } from "react-reveal";

import { PageContainer } from "shared";
import {
  Backdrop,
  BackPage,
  NoDisplay,
  SectionContent,
  WalletBalance,
} from "components";
import {
  BuyAirtime,
  BuyData,
  BuyCable,
  BuyPower,
  BuyPin,
  BuyBetting,
  ConvertAirtime,
} from "./exports";

export default function Index() {
  const { currentUser } = useSelector((state) => state.auth || {});
  const account = useSelector((state) => state.account || {});
  const { user_details } = useSelector((state) => state.profile || {});
  const { utilityAuth } = useSelector((state) => state.utils);

  const { current_user: userUid } = currentUser || {};
  const { nairaWallet } = account || {};

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const pages = [
      "buy-airtime",
      "buy-data",
      "buy-cables",
      "buy-electricity",
      "buy-pin",
      "fund-betting-account",
      "convert-airtime",
    ];
    if (!pages.includes(id)) {
      navigate("/utilities");
    }
  }, [id]);

  const pagesArr = [
    {
      id: "buy-airtime",
      page: BuyAirtime,
      title: "Buy Airtime",
      enabledProp: utilityAuth?.["enableAirtime"],
    },
    {
      id: "buy-data",
      page: BuyData,
      title: "Buy Data Bundle",
      enabledProp: utilityAuth?.["enableDataBundle"],
    },
    {
      id: "buy-cables",
      page: BuyCable,
      title: "Subscribe TV Cable",
      enabledProp: utilityAuth?.["enableTvCable"],
    },
    {
      id: "buy-electricity",
      page: BuyPower,
      title: "Subscribe Electricity",
      enabledProp: utilityAuth?.["enableElectricity"],
    },
    {
      id: "buy-pin",
      page: BuyPin,
      title: "Purchase Scratch Card Pin",
      enabledProp: utilityAuth?.["enableEducation"]?.waec,
    },
    {
      id: "fund-betting-account",
      page: BuyBetting,
      title: "Fund Betting Account",
      // enabledProp: utilityAuth?.["enableBetting"],
      enabledProp: true,
    },
    {
      id: "convert-airtime",
      page: ConvertAirtime,
      title: "Convert Airtime to Cash",
      enabledProp: utilityAuth?.["enableAirtimeToCash"],
    },
  ];

  const getPage = pagesArr?.find((d) => d.id === id);
  const CurrentPage = getPage?.page;
  const pageTitle = getPage?.title;
  const displayPage = getPage?.enabledProp;

  // if (!utilityAuth) {
  //   return <Backdrop open={!utilityAuth} />;
  // }

  return (
    <PageContainer header={startCase(pageTitle)}>
      <div className="relative">
        <BackPage handleClick={() => navigate(-1)} />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4 xl:gap-6">
          <div className="col-span-1 md:col-span-2">
            <SectionContent>
              {displayPage ? (
                <div className="p-2">
                  <div className="mb-4">
                    <WalletBalance />
                  </div>
                  <Fade>
                    <CurrentPage
                      nairaWallet={nairaWallet}
                      userEmail={user_details.email}
                      userPhone={user_details.phoneNumber}
                      userId={userUid}
                      displayPage={displayPage}
                    />
                  </Fade>
                </div>
              ) : (
                <NoDisplay type="purchase" />
              )}
            </SectionContent>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
