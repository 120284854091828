import React from "react";
import { SectionContent, SimpleIcon } from "components";
import { PageContainer } from "shared";
import { classNames, formatDate, useBreakpoint } from "utils";
import { useSelector } from "react-redux";

export default function Notifications() {
  const breakpoint = useBreakpoint();
  const { notifications } = useSelector((state) => state.notification || {});

  return (
    <PageContainer header="Notifications">
      <SectionContent>
        {notifications?.length ? (
          notifications?.map((item) => (
            <div
              className={classNames(
                "flex border-b border-slate-50 p-4",
                breakpoint.md ? "flex-row" : "flex-col"
              )}
            >
              <div className="flex h-7 w-7 items-center justify-center rounded-full bg-primary shadow">
                <SimpleIcon
                  icon="Notifications"
                  fontSize={16}
                  frontColor="text-white"
                  otherClass="iconShadow-alt"
                />
              </div>
              <div
                className={classNames(
                  "max-w-3xl flex-1",
                  breakpoint.md ? "ml-4" : "mt-4"
                )}
              >
                <div className="font-bold">{item.title}</div>
                <div className="mb-2">{item.message}</div>
                <div className="text-left text-xs font-bold">
                  {formatDate(item.dateCreated)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="bg-slate-50 p-4 font-bold">No notification</div>
        )}
      </SectionContent>
    </PageContainer>
  );
}
