import { useSelector } from "react-redux";

import { PageContainer } from "shared";
import ProductUtility, { PortalCard } from "./ProductUtility";
import ProductCard from "./ProductCard";
import WalleSection from "./WalleSection";
import ContactSection from "./ContactSection";
import { getFirstName } from "utils";
import { useGetPhoneList } from "api";
import PortalSection from "./PortalSection";

export default function Dashboard() {
  const {
    account,
    profile: { user_details },
  } = useSelector((state) => state);
  const { phoneList } = useSelector((state) => state.utils);

  useGetPhoneList();

  return (
    <PageContainer header={`Hello ${getFirstName(user_details?.displayName)}`}>
      <div className="grid grid-cols-1 gap-y-4 md:grid-cols-1 md:gap-6 lg:grid-cols-3 xl:p-0">
        <WalleSection account={account} />
        <ProductCard />
        <PortalSection />
      </div>
      <ProductUtility />
      {phoneList?.callPhoneNo && (
        <ContactSection phoneList={phoneList?.callPhoneNo} />
      )}
    </PageContainer>
  );
}
