import { Link } from "react-router-dom";
import Notification from "./Notification";
import Avatar from "./Avatar";

export default function NavbarTop({ logout, avatarUrl }) {
  return (
    <>
      <div as="nav" className="h-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-center md:justify-between">
            <Link to="/" className="flex items-center">
              <img
                src={require("../assets/images/logo-alt.png")}
                className="w-24 text-primary"
                alt=""
              />
            </Link>
            <div className="ml-4 flex items-center md:ml-6">
              <div className="hidden md:block">
                <Notification />
              </div>
              <div className="hidden md:block">
                <Avatar avatarUrl={avatarUrl} logout={logout} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
