import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as i from "../lib";

import {
  getCompletedTrans,
  getPendingTrans,
  getCompletedTransLoading,
  getPendingTransLoading,
  getTransCountLoaded,
  getUserTransCount,
} from "redux/reducers/transactionReducers";
import { formatDateToValue } from "utils";
import dayjs from "dayjs";

const auth = i.auth;

export const useUserTransCompletedForWallet = ({ col, limit = 4, dates }) => {
  const [queryDates, setQueryDates] = useState({
    startDate: null,
    endDate: null,
  });
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    const { startDate, endDate } = dates || {};
    setQueryDates({
      startDate: startDate ? formatDateToValue(startDate, "start") : null,
      endDate: endDate ? formatDateToValue(endDate, "end") : null,
    });
  }, [dates]);

  useEffect(() => {
    let unsub = () => {};
    dispatch(getCompletedTransLoading(true));
    try {
      if (userUid && col) {
        const completedQueries = [
          i.collection(i.db, col),
          ...(col === "transTransferFiat"
            ? [i.where("transCustomerIds", "array-contains", userUid)]
            : [i.where("customerId", "==", userUid)]),

          ...(queryDates?.startDate
            ? [i.where("dateCreated", ">=", queryDates.startDate)]
            : []),
          ...(queryDates?.endDate
            ? [i.where("dateCreated", "<=", queryDates.endDate)]
            : []),
          i.orderBy("dateCreated", "desc"),
          i.limit(limit),
        ];

        const q = i.query(...completedQueries);

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];

          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getCompletedTrans(t));
          dispatch(getCompletedTransLoading(false));
        });
      } else {
        dispatch(getCompletedTransLoading(false));
      }
    } catch (error) {
      // console.log(error);
      dispatch(getCompletedTransLoading(false));
    }
    return () => {
      unsub();
      dispatch(getCompletedTrans(null));
    };
  }, [dispatch, userUid, col, queryDates]);
};

export const useUserTransPendingForWallet = ({ pending_col }) => {
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub = () => {};
    dispatch(getPendingTransLoading(true));
    try {
      if (userUid && pending_col) {
        const pendingQueries = [
          i.collection(i.db, pending_col),
          i.where("customerId", "==", userUid),
          i.where("status", "in", ["pending", "active"]),
          i.orderBy("dateCreated", "desc"),
        ];

        const q = i.query(...pendingQueries);

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];

          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getPendingTrans(t));
          dispatch(getPendingTransLoading(false));
        });
      } else {
        dispatch(getPendingTransLoading(false));
      }
    } catch (error) {
      // console.log(error);
      dispatch(getPendingTransLoading(false));
    }
    return () => {
      unsub();
      dispatch(getPendingTrans(null));
    };
  }, [dispatch, userUid, pending_col]);
};

export const useUserTransAnalysisCount = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState({
    pending: 0,
    active: 0,
    approved: 0,
    declined: 0,
  });

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    dispatch(getTransCountLoaded(false));
    dispatch(getUserTransCount({}));
    if (userUid) {
      const counterFunc = async ({ collection, type }) => {
        const hourUpto = dayjs().subtract(3, "hour");
        // const hourUpto = dayjs().subtract(6, "day");

        const queries = [
          i.collection(i.db, collection),
          i.where("customerId", "==", userUid),
          i.where("status", "==", type),
          i.where("dateCreated", ">=", new Date(hourUpto)),
        ];

        const q = i.query(...queries);

        unsub = i.onSnapshot(q, (querySnapshot) => {
          dispatch(getUserTransCount({ [type]: querySnapshot?.size || 0 }));
          // count[type] = count?.[type] + querySnapshot?.size || 0;
        });
      };

      const allCounterFunc = async () => {
        if (userUid) {
          await counterFunc({
            collection: "pendingTransGiftcards",
            type: "pending",
          });
          await counterFunc({
            collection: "pendingTransGiftcards",
            type: "active",
          });
          await counterFunc({
            collection: "transGiftcards",
            type: "approved",
          });
          await counterFunc({
            collection: "transGiftcards",
            type: "declined",
          });

          // dispatch(getUserTransCount(count));
          dispatch(getTransCountLoaded(true));
        } else {
          dispatch(getUserTransCount({}));
        }
      };
      allCounterFunc();
    }
    return () => {
      if (unsub) unsub();
    };
  }, [dispatch, userUid]);
};
