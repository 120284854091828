import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import OTPInput from "otp-input-react";

import { AuthContainer } from "shared";
import { useRegister } from "api";
import { Button } from "components";
import { formatTime } from "utils";

// const counter = 5;

export default function RegisterOTP() {
  const [emailOtp, setemailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailcodeCounter, setEmailCodeCounter] = useState(0);
  const [phonecodeCounter, setPhoneCodeCounter] = useState(0);

  const [
    isLoading,
    isLoaded,
    verifyRegister,
    sendEmailCodeStatus,
    setSendEmailCodeStatus,
    sendPhoneCodeStatus,
    setSendPhoneCodeStatus,
    phoneOTPRef,
    resendEmailOtpCode,
    resendPhoneOtpCode,
  ] = useRegister();

  const { state } = useLocation();

  const navigate = useNavigate();

  const handleComparePIN = (e) => {
    setemailOtp(e);
  };

  const handleComparePhonePIN = (e) => {
    setPhoneOtp(e);
  };

  const handleVerify = () => {
    verifyRegister({
      values: { ...state },
      code: emailOtp,
      phoneCode: phoneOtp,
      phoneOTPRef: phoneOTPRef == null ? state.phoneOTPRef : phoneOTPRef,
      referralParam: state.marketingReferral || "organic",
    });
  };

  const handleResendEmailCode = () => {
    resendEmailOtpCode(state);
    setSendEmailCodeStatus(true);
  };

  const handleResendPhoneCode = () => {
    resendPhoneOtpCode(state);
    setSendEmailCodeStatus(true);
  };

  useEffect(() => {
    setSendEmailCodeStatus(true);
    setSendPhoneCodeStatus(true);

    if (!state) {
      navigate("/register", {
        replace: true,
      });
    }
  }, [navigate, setSendEmailCodeStatus, state, setSendPhoneCodeStatus]);

  const handleTimer = (timerStatus, setTimerStatus, timerCount, time) => {
    let downloadTimer;
    if (timerStatus) {
      let timeleft = time;
      downloadTimer = setInterval(() => {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          setTimerStatus(false);
        } else {
          timerCount(timeleft);
        }
        timeleft -= 1;
      }, 1000);
    }
    return () => {
      clearInterval(downloadTimer);
    };
  };
  useEffect(() => {
    handleTimer(
      sendEmailCodeStatus,
      setSendEmailCodeStatus,
      setEmailCodeCounter,
      60
    );
  }, [sendEmailCodeStatus, setSendEmailCodeStatus]);

  useEffect(() => {
    handleTimer(
      sendPhoneCodeStatus,
      setSendPhoneCodeStatus,
      setPhoneCodeCounter,
      180
    );
  }, [sendPhoneCodeStatus, setSendPhoneCodeStatus]);

  return (
    <AuthContainer
      title="Account Verification"
      subTitle="We'll like to verify you! This will only take a few minutes."
    >
      <div>
        <div className="my-2 w-[90%] rounded-sm bg-gray-100/60 px-3 pt-3 pb-5 shadow-sm">
          <p className="font-bold capitalize">Email verification</p>
          <p className="pt-1 pb-2 text-xs">
            Enter the verification code sent to {state?.email}
          </p>
          <OTPInput
            value={emailOtp}
            onChange={handleComparePIN}
            OTPLength={6}
            otpType="number"
            disabled={false}
            // secure={secure}
            inputClassName="border rounded-md border-gray-300 text-gray-500 !mr-1 !h-10 !w-10 focus:border-color-main outline-none focus:border-2 focus: rounded-sm"
          />
          <div className="my-1">
            <button
              className="text-xs text-color-main"
              onClick={handleResendEmailCode}
              disabled={sendEmailCodeStatus}
            >
              {emailcodeCounter <= 1 ? (
                <>Didn't receive code? Resend </>
              ) : (
                <>Retry send code - {formatTime(emailcodeCounter)}s</>
              )}
            </button>
          </div>
        </div>
        <div className="my-2 w-[90%] rounded-sm bg-gray-100/60 px-3 pt-3 pb-5 shadow-sm">
          <p className="font-bold capitalize">Phone Number verification</p>
          <p className="pt-1 pb-2 text-xs">
            Enter the verification code sent to +{state?.phoneNumber}
          </p>

          <OTPInput
            value={phoneOtp}
            onChange={handleComparePhonePIN}
            OTPLength={6}
            otpType="number"
            disabled={false}
            // secure={secure}
            inputClassName="border rounded-md border-gray-300 text-gray-500 !mr-1 !h-10 !w-10 focus:border-color-main outline-none focus:border-2 focus: rounded-sm"
          />
          <div className="my-1">
            <button
              className="text-xs text-color-main"
              onClick={handleResendPhoneCode}
              disabled={sendPhoneCodeStatus}
            >
              {phonecodeCounter <= 1 ? (
                <>Didn't receive code? Resend </>
              ) : (
                <>Retry send code - {formatTime(phonecodeCounter)}s</>
              )}
            </button>
          </div>
        </div>

        <div className="mt-6">
          <Button
            label="Verify"
            gradient
            handleClick={handleVerify}
            disabled={emailOtp.length < 6 || phoneOtp.length < 4}
            isSubmitting={isLoading}
            // fullWidth
          />
        </div>
      </div>
    </AuthContainer>
  );
}
