import DownloadButton from "components/DownloadButton";
import React from "react";
import { Link } from "react-router-dom";

export default function AuthContainer({
  title,
  subTitle,
  footer,
  showForgetPass,
  showDownloadAppButton,
  children,
}) {
  return (
    // h-screen
    <div className="flex h-full flex-row items-center justify-center bg-gradient-to-tr  from-bg_gradient_from to-bg_gradient_to">
      {/* flex */}
      {/* bg-gradient-to-tr from-bg_gradient_from to-bg_gradient_to  */}
      <div className="hidden h-screen w-1/2 items-center  justify-center md:block">
        <div className="fixed left-0 flex h-full w-full flex-col items-center justify-center md:w-1/2">
          <img
            src={require("../assets/images/logo.png")}
            className="w-[140px]"
            alt="kaapo logo"
          />
          <p className="mt-1 text-white">
            Experience Easy Global Gift Card Exchange.
          </p>
          <div className="mt-12">
            <img
              src={require("../assets/images/auth-image.png")}
              className="w-[400px]"
              alt="kaapo login"
            />
          </div>
        </div>
      </div>
      <div className="h-full w-full bg-white p-8 sm:w-5/6 md:w-1/2">
        <div className="m-auto flex h-full flex-col justify-start md:w-4/5 md:justify-center">
          <div className="my-4 flex flex-row items-center justify-center md:hidden">
            <img
              src={require("../assets/images/logo-alt.png")}
              className="w-[120px]"
              alt="kaapo alt logo"
            />
          </div>
          <h1 className="mb-1 text-2xl font-bold text-gray-800">{title}</h1>
          <p className="mb-7 text-sm font-normal text-gray-600">{subTitle}</p>
          <div className="mb-4">{children}</div>

          {footer && (
            <>
              <hr className="mb-6 border-t" />
              {showForgetPass && (
                <div className="text-center">
                  <Link
                    className="inline-block align-baseline text-sm text-color-main hover:text-blue-600"
                    to="/forget-password"
                  >
                    Forgot Password?
                  </Link>
                </div>
              )}
              <div className="text-center">
                <Link
                  className="inline-block align-baseline text-sm text-color-main hover:text-blue-600"
                  to={footer.link}
                >
                  {footer.text}
                </Link>
              </div>
            </>
          )}
        </div>
        {showDownloadAppButton && (
          <>
            <DownloadButton />
            <div className="my-4 flex flex-row items-center justify-center">
              <img
                src={require("../assets/images/kaapo-qr.png")}
                className="w-[100px]"
                alt="kaapo download qrcode"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
