import React from "react";
import { IconBox } from "components";

export default function ContactSection({ phoneList }) {
  return (
    <div className="mt-16">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="rounded-sm border border-gray-200 bg-white p-6 shadow-card-box">
            <div className="text-center">
              <h5 className="text-base font-bold tracking-wide text-gray-500">
                You still have a question?
              </h5>
              <p className="my-3 text-sm text-gray-400">
                If you cannot find a question in our FAQ, you can always contact
                us. We will answer to you shortly!
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-4 md:grid-cols-3">
              {[
                {
                  title: phoneList,
                  subTitle: "We are always happy to talk to you!",
                  icon: "Phone",
                  linkTo: `tel:${phoneList}`,
                },
                {
                  title: "Chat with us",
                  subTitle: "We are online 24/7!",
                  icon: "Chat",
                  // linkTo: "mailto:support@kaapo.ng",
                  linkTo: (onclick = `javascript:$zoho.salesiq.chat.start()`),
                },
                {
                  title: "support@kaapo.ng",
                  subTitle: "Get all the support you need!",
                  icon: "Mail",
                  linkTo: "mailto:support@kaapo.ng",
                },
              ].map((item) => (
                <a
                  href={item.linkTo}
                  key={item.title}
                  className="col-span-1 rounded bg-gray-100 p-6"
                >
                  <div className="flex flex-col items-center space-y-4 text-gray-400">
                    <IconBox icon={item.icon} gradient />
                    <div className="text-center">
                      <div className="font-bold text-gray-500">
                        {item.title}
                      </div>
                      <div className="mt-1 text-sm">{item.subTitle}</div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
