import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  useUserTransCompletedForWallet,
  useUserTransPendingForWallet,
} from "api";
import {
  PopupDrawer,
  TableData,
  TransactionSummary,
  BackdropSection,
} from "components";
import {
  WithdrawFiat,
  FundFiat,
  TransferFiat,
  Utilities,
  ConvertFiat,
  TransferBonus,
  Giftcards,
} from "./sub-transactions/TransactionProps";
import TransactionDrawer from "./TransactionDrawer";
import { classNames, useBreakpoint } from "utils";
import { useLocation } from "react-router-dom";

const tabList = [
  {
    name: "Withdraw Fiat",
    id: "withdrawal_fiat",
    collection: "transWithdrawals",
    pending_collectiion: "pendingTransWithdrawals",
    Page: WithdrawFiat,
  },
  {
    name: "Giftcard Trades",
    id: "giftcard_trades",
    collection: "transGiftcards",
    pending_collectiion: "pendingTransGiftcards",

    Page: Giftcards,
  },
  {
    name: "Utilities",
    id: "utilities",
    collection: "transUtilities",
    pending_collectiion: "pendingTransUtilities",
    Page: Utilities,
  },
  {
    name: "Fund Fiat Wallet",
    id: "fund_fiat_wallet",
    collection: "transDeposits",
    Page: FundFiat,
  },
  {
    name: "Transfer Fiat",
    id: "transfer_fiat",
    collection: "transTransferFiat",
    Page: TransferFiat,
  },
  {
    name: "Transfer Bonus",
    id: "transfer_bonus",
    collection: "transTransferBonus",
    Page: TransferBonus,
  },
  {
    name: "Convert Fiat",
    id: "convert_fiat",
    collection: "transConvertFiat",
    Page: ConvertFiat,
  },
];

export default function TransactionContent() {
  const breakpoint = useBreakpoint();
  const location = useLocation();
  const { state } = location || {};

  const [currentTrans, setCurrentTrans] = useState();

  const [selectTrans, setTrans] = useState(tabList[state?.page || 0]);

  const { Page, collection, pending_collectiion, id } = selectTrans || {};

  const {
    completedTrans,
    pendingTrans,
    isCompletedTransLoading,
    isPendingTransLoading,
  } = useSelector((state) => state.transaction);
  const { currentUser } = useSelector((state) => state.auth);
  const { giftcardDeclineReasons } = useSelector(
    (state) => state.product || {}
  );

  const { current_user } = currentUser;

  const [queryDates, setQueryDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [open, setOpenDrawer] = useState(false);

  const handleRowClick = (id, type) => {
    let summary;
    let singleT;

    if (type === "completed") {
      singleT = completedTrans.find((i) => i.id === id);
      summary = Page(current_user)?.summary(singleT, {
        giftcardDeclineReasons,
      });
    }
    if (type === "pending") {
      singleT = pendingTrans.find((i) => i.id === id);
      summary = Page(current_user)?.pending_summary?.(singleT) || {};
    }
    setCurrentTrans(summary);
    setOpenDrawer(true);
  };

  useUserTransCompletedForWallet({
    col: collection,
    limit: 50,
    dates: queryDates,
  });

  useUserTransPendingForWallet({
    pending_col: pending_collectiion,
  });

  const rows = Page(current_user)?.rows(completedTrans) || [];
  const pending_rows = pending_collectiion
    ? Page(current_user)?.pending_row(pendingTrans)
    : [];

  const columns = Page(current_user)?.columns;

  return (
    <div className="relative flex-1">
      <div className="h-full">
        <div className="bg-dark/5 h-full">
          <div className="">
            <>
              <div className="mb-8">
                {!isPendingTransLoading ? (
                  <>
                    {pending_collectiion && pending_rows?.length ? (
                      <>
                        <div className="mb-2 font-bold text-white">
                          Pending Transaction
                        </div>
                        <div className="w-full rounded-sm bg-white p-4 shadow-card-box">
                          <TableData
                            columns={columns}
                            rows={pending_rows}
                            hideFooter={false}
                            onRowClick={(id) => handleRowClick(id, "pending")}
                            coloredHeader
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ) : (
                  <div className="relative h-[200px] rounded-sm bg-white">
                    <BackdropSection open={true} />
                  </div>
                )}
              </div>
              {!isCompletedTransLoading ? (
                <>
                  {pending_rows?.length ? (
                    <div className="mb-2 font-bold">Completed Transaction</div>
                  ) : null}
                  <div
                    className={classNames(
                      "flex",
                      breakpoint?.md ? "flex-row" : "flex-col-reverse"
                    )}
                  >
                    <div
                      className={
                        "w-full rounded-sm bg-white p-4 shadow-card-box"
                      }
                    >
                      <TableData
                        columns={columns}
                        rows={rows}
                        hideFooter={false}
                        onRowClick={(id) => handleRowClick(id, "completed")}
                        coloredHeader
                      />
                    </div>
                    <TransactionDrawer
                      {...{
                        queryDates,
                        setQueryDates,

                        tabList,
                        setTrans,
                        selectTrans,
                        isTransactionListLoading: isCompletedTransLoading,
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="relative h-[200px] rounded-sm bg-white">
                  <BackdropSection open={true} />
                </div>
              )}
            </>
          </div>
        </div>
      </div>

      <PopupDrawer {...{ setOpenDrawer, open }}>
        <div className="rounded-sm bg-slate-50 p-2">
          <div className="bg-gray-50 p-0 px-4 py-4 sm:flex sm:px-4">
            <div className="flow-root">
              <h5 className="font-bold">Transaction Details</h5>
            </div>
          </div>

          <div className="relative grid gap-6 bg-white px-4 py-4 sm:gap-8 sm:p-4">
            <TransactionSummary summary={currentTrans} />
          </div>
        </div>
      </PopupDrawer>
    </div>
  );
}
