import React from "react";
import { IconButton as MuiIconButton } from "@mui/material";
import { classNames } from "utils";
import * as Icons from "@mui/icons-material";

export const IconButton = ({
  icon = "Home",
  handleClick,
  iconColor = "bg-primary-600",
}) => {
  const AppIcon = Icons[icon];
  return (
    <button
      className={`${iconColor} flex h-12 w-12 items-center justify-center rounded-full p-2 text-center text-white shadow-lg`}
      onClick={handleClick}
    >
      <AppIcon className={classNames("h-6 w-6")} />
    </button>
  );
};

export function SimpleIconButton({
  icon = "Home",
  fontSize = "medium",
  frontColor = "text-primary",
  otherClass = "",
  ...otherProps
}) {
  const AppIcon = Icons[icon];

  return (
    <MuiIconButton {...otherProps}>
      <AppIcon
        {...(typeof fontSize === "string"
          ? {
              fontSize,
            }
          : {
              sx: { fontSize },
            })}
        className={classNames(frontColor, otherClass)}
      />
    </MuiIconButton>
  );
}

export function BackPage({ handleClick }) {
  return (
    <button className="mb-2" onClick={handleClick}>
      <IconBox
        icon={"ArrowCircleLeft"}
        textColor="text-color-main"
        iconColor="bg-slate-50"
        shadowAlt
        fontSize={30}
        hover="hover:bg-slate-200"
      />
    </button>
  );
}

export function IconBox({
  icon = "Home",
  iconColor,
  textColor = "text-white",
  shadowAlt,
  gradient,
  fontSize = 20,
  hover,
  otherClass,
  CustomIcon,
}) {
  const AppIcon = Icons[icon];

  return (
    <div
      className={classNames(
        gradient
          ? `bg-gradient-to-l from-button_gradient_from to-button_gradient_to `
          : iconColor,
        hover ? hover : "",
        "inline-flex h-10 w-10 items-center justify-center rounded fill-white p-3 text-center shadow-lg md:h-10 md:w-10",
        otherClass
      )}
    >
      {CustomIcon ? (
        <CustomIcon />
      ) : (
        <AppIcon
          sx={{ fontSize }}
          className={classNames(
            "iconShadow-alt",
            // shadowAlt ? "iconShadow-alt" : "iconShadow",
            textColor
            // "text-base"
          )}
        />
      )}
    </div>
  );
}

export function SimpleIcon({
  icon = "Home",
  fontSize = "medium",
  frontColor = "text-primary",
  otherClass = "iconShadow-alt",
}) {
  const AppIcon = Icons[icon];

  return (
    <AppIcon
      {...(typeof fontSize === "string"
        ? {
            fontSize,
          }
        : {
            sx: { fontSize },
          })}
      className={classNames(frontColor, otherClass)}
    />
  );
}

export function WalletIcon({ type, fontSize = "small" }) {
  const props = {
    "extra-small": {
      text: "text-sm",
      dim: "h-4 w-4",
    },
    small: {
      text: "text-sm",
      dim: "h-5 w-5",
    },
    medium: {
      text: "text-lg",
      dim: "h-8 w-8",
    },
    large: {
      text: "text-xl",
      dim: "h-10 w-10",
    },
  };

  const cur = {
    naira: "ngn",
    cedis: "ghs",
    cefa: "xof",
  };

  return (
    <img
      src={require(`../assets/fiat/${cur?.[type]}.png`)}
      className={classNames("text-white", props[fontSize].dim)}
    />
  );
}
