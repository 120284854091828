import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, EffectCreative, Autoplay } from "swiper/modules";

import "swiper/css";
import { useSelector } from "react-redux";

const portalCardImages = [
  "https://firebasestorage.googleapis.com/v0/b/esetech-app.appspot.com/o/advertPopup%2Fadvert-popup-10?alt=media&token=bdbe0d67-21bd-486c-a9b4-a05292c549bc",
  "https://firebasestorage.googleapis.com/v0/b/esetech-app.appspot.com/o/advertPopup%2Fadvert-popup-17?alt=media&token=df462b8c-558f-4c6c-8391-081366984dbe",
  "https://firebasestorage.googleapis.com/v0/b/esetech-app.appspot.com/o/advertPopup%2Fadvert-popup-10?alt=media&token=bdbe0d67-21bd-486c-a9b4-a05292c549bc",
];

export default function PortalSection() {
  const { portalImages } = useSelector((state) => state.utils);

  return (
    <div className="col-span-1 aspect-square h-full max-h-[250px] w-full xl:max-h-[220px]">
      {portalImages && (
        <Swiper
          // effect={"cards"}
          // grabCursor={true}
          // modules={[EffectCards, Autoplay]}
          // cardsEffect={{
          //   perSlideOffset: 12,
          //   rotate: true,
          //   slideShadows: true,
          // }}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
          }}
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              origin: "left center",
              translate: ["-5%", 0, -200],
              rotate: [0, 100, 0],
            },
            next: {
              origin: "right center",
              translate: ["5%", 0, -200],
              rotate: [0, -100, 0],
            },
          }}
          modules={[EffectCreative, Autoplay]}
          className="px-8-0 h-full"
        >
          {portalImages.map((item, index) => (
            <SwiperSlide key={index}>
              <button
                className="flex h-full w-full items-center justify-center rounded-sm bg-white p-2 shadow-card-box outline-none duration-500 ease-in hover:-top-2 hover:shadow-lg"
                onClick={() => {
                  return window.open(
                    "https://dashboard.esetech.com.ng",
                    "_blank"
                  );
                }}
              >
                <img
                  src={item}
                  alt="portal links"
                  className="h-full w-full rounded-sm object-cover"
                />
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
