import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";
import {
  getGiftcardRate,
  getGiftcardRateFac,
  addGiftcardSale,
  clearGiftcard,
  addGiftcardSteps,
  getGiftcardCurrency,
  getGiftcardDeclineReasons,
} from "../../redux/reducers/giftcardReducers";

import { omit } from "lodash-es";
import { getDeviceType, notification } from "utils";
import { tradeGiftcardSchema, editGiftcardSchema } from "utils/formSchema";

import { uploadImage } from "../helpers";

const auth = i.getAuth();

export const useGiftcardRate = () => {
  const dispatch = useDispatch();
  const currentUser = auth?.currentUser;
  useEffect(() => {
    let unsub;
    if (currentUser) {
      try {
        const q = i.query(i.collection(i.db, "giftcardRate1"));

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          // const dd = t.map((item) => [item.name, item.id])?.sort();
          // console.log(dd, "asdkmasd");
          dispatch(
            getGiftcardRate({
              giftcard_rate: t,
            })
          );
        });
      } catch (error) {
        dispatch(
          getGiftcardRate({
            giftcard_rate: null,
          })
        );
      }
    } else {
      dispatch(
        clearGiftcard({
          payload: {},
        })
      );
    }
    return unsub;
  }, [dispatch, currentUser]);
};

const fiat = {
  naira: "NGN",
  cedis: "GHS",
  cefa: "XOF",
};
const fiatSym = {
  naira: "₦",
  cedis: "GH₵ ",
  cefa: "F.CFA ",
};

export const useGiftcardRateFac = () => {
  const dispatch = useDispatch();
  const currentUser = auth?.currentUser;
  const { user_details } = useStore()?.getState()?.profile;
  const { defaultCurrency = "naira" } = user_details || {};

  useEffect(() => {
    let unsub, unsubCur, unsubReasonDecline;
    if (currentUser) {
      try {
        const q = i.doc(i.db, "giftcardRateFactors", "factors");

        const qCurFlag = i.doc(i.db, "utils", "giftcard");
        const qReasonDecline = i.doc(i.db, "utils", "giftcardDeclineReasons");

        unsub = i.onSnapshot(q, (doc) => {
          const t = doc.data();
          dispatch(
            getGiftcardRateFac({
              giftcard_rate_fac: {
                ...t,
                exchangeFiatRate: t?.exchangeRate[fiat?.[defaultCurrency]],
                defaultFiat: fiat?.[defaultCurrency],
                defaultFiatSym: fiatSym?.[defaultCurrency],
                defaultCurrency,
              },
            })
          );
        });

        unsubCur = i.onSnapshot(qCurFlag, (doc) => {
          const t = doc?.data()?.currencyFlagList;
          dispatch(getGiftcardCurrency(t));
        });

        unsubReasonDecline = i.onSnapshot(qReasonDecline, (doc) => {
          const t = doc?.data()?.reasons;
          dispatch(getGiftcardDeclineReasons(t));
        });
      } catch (error) {
        dispatch(
          getGiftcardRateFac({
            giftcard_rate_fac: null,
          })
        );
        dispatch(getGiftcardCurrency(null));
      }
    } else {
      dispatch(
        getGiftcardRateFac({
          giftcard_rate_fac: null,
        })
      );
      dispatch(getGiftcardCurrency(null));
    }
    return () => {
      if (unsub) unsub();
      if (unsubCur) unsubCur();
      if (unsubReasonDecline) unsubReasonDecline();
    };
  }, [dispatch, currentUser, defaultCurrency]);
};

export const useAddToGiftcardCart = () => {
  const dispatch = useDispatch();
  const {
    product: { add_to_gf_sale },
  } = useStore().getState();

  const addToSale = (value, callback) => {
    if (value) {
      dispatch(
        addGiftcardSale({ add_to_gf_sale: { ...add_to_gf_sale, ...value } })
      );
      if (callback) callback();
    }
  };
  return [addToSale];
};

export const useRemoveFromGiftcardCart = () => {
  const dispatch = useDispatch();
  const {
    product: { add_to_gf_sale },
  } = useStore().getState();

  const removeFromSale = (keys) => {
    if (keys) {
      const gfSaleClone = omit(add_to_gf_sale, keys);
      dispatch(addGiftcardSale({ add_to_gf_sale: gfSaleClone }));
    }
  };
  return [removeFromSale];
};

export const useAddSteps = () => {
  const dispatch = useDispatch();

  const addSteps = (steps) => {
    if (steps) {
      dispatch(addGiftcardSteps(steps));
    }
  };
  return [addSteps];
};

export const useClearGiftcardCart = () => {
  const dispatch = useDispatch();

  const clearCart = () => {
    dispatch(addGiftcardSale({ add_to_gf_sale: null }));

    dispatch(addGiftcardSteps(0));
  };

  return [clearCart];
};

export const useSubmitGiftcardTrade = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const { user_details } = useStore()?.getState()?.profile;

  const collection = "pendingTransGiftcards";

  const submitTrade = async (values) => {
    const { disabled, isFlagged } = user_details;
    const {
      comment,
      total,
      quantity,
      type,
      currency,
      images,
      imageUID,
      defaultCurrency,
    } = values;

    const transaction = {
      card: values["card name"],
      country: currency,
      qty: quantity,
      total,
      tradeType: "sell",
      type,
    };

    const valuesFormat = {
      transaction,
      amountToCredit: total,
      amount: total,
      walletToCredit: `${defaultCurrency}Wallet`,
      amountToDebit: "none",
      walletToDebit: "none",
      comment,
      customerId: auth.currentUser.uid,
      agentId: null,
      product: "giftcard",
      productType: transaction.card,
      status: "pending",
      defaultCurrency,
      deviceType: getDeviceType(),
    };

    try {
      setUpdating(true);

      if (isFlagged || disabled) {
        throw "Unauthorized request";
      }

      await tradeGiftcardSchema.validate({
        ...valuesFormat,
        images,
      });

      await i.runTransaction(i.db, async (transaction) => {
        const documentRef = i.doc(i.collection(i.db, collection));

        const docExistRef = await i.getDocs(
          i.query(
            i.collection(i.db, collection),
            i.where("imageUID", "==", imageUID),
            i.limit(1)
          )
        );

        if (docExistRef.size) {
          throw "Document already exist!";
        }

        const transactionUpload = await uploadImage(
          images,
          `transGiftcards/${imageUID}`,
          null
        );

        return Promise.resolve(
          transaction.set(documentRef, {
            ...valuesFormat,
            transactionUpload,
            isDeferred: false,
            dateCreated: i.serverTimestamp(),
            imageUID,
          })
        );
      });

      setUpdating(false);
      setUpdated(true);
      notification("success", "Trade successfully submitted.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      notification(
        "danger",
        error === "Document already exist!"
          ? "Trade already been processed"
          : "An error occured. Try again later!",
        "error"
      );
    }
  };
  return [isUpdating, isUpdated, submitTrade];
};

export const useEditGiftcardTrade = () => {
  const [isUpdating, setUpdating] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const editTrade = async (values) => {
    const {
      id,
      productType,
      country,
      type,
      qty,
      total,
      comment,
      transactionUpload,
      images,
    } = values;

    const transaction = {
      card: productType,
      country,
      qty,
      total,
      tradeType: "sell",
      type,
    };

    const valuesFormat = {
      transaction,
      amountToCredit: total,
      // walletToCredit: 'nairaWallet',
      // amountToDebit: 'none',
      // walletToDebit: 'none',
      comment,
      // customerId: auth.currentUser.uid,
      // agentId: null,
      // product: 'giftcard',
      productType: transaction.card,
      // status: 'pending',
      // dateCreated: firestore.FieldValue.serverTimestamp(),
      // dateCreated: Date.now(),
      dateUploaded: Date.now(),
      // walletBalances: wallet,
    };

    try {
      setUpdating(true);
      let imageUID;
      const docExistRef = await i.getDoc(
        i.doc(i.db, "abbtestingtransactions", id)
      );

      if (docExistRef.exists()) {
        imageUID = docExistRef.data().imageUID;
        // console.log(imageUID);
      } else {
        throw "Document does not exist!";
      }

      await editGiftcardSchema.validate({
        ...valuesFormat,
        images,
      });
      const editTransactionUpload = await uploadImage(
        images,
        `abbtestingimage/${imageUID}`,
        null
      );

      await i.runTransaction(i.db, async (transaction) => {
        const documentRef = i.doc(i.db, "abbtestingtransactions", id);

        return Promise.resolve(
          transaction.set(
            documentRef,
            {
              ...valuesFormat,
              transactionUpload: [
                ...transactionUpload,
                ...editTransactionUpload,
              ],
            },
            { merge: true }
          )
        );
      });

      setUpdating(false);
      setUpdated(true);
      notification("success", "Trade successfully edited.");
    } catch (error) {
      // console.log(error);
      setUpdating(false);
      notification("danger", "An error occured. Try again later!", "error");
    }
  };
  return [isUpdating, isUpdated, editTrade];
};

// export const storeG = async () => {
//   try {
//     const querySnapshot = await i.getDocs(i.collection(i.db2, "giftcardRate"));
//     querySnapshot.forEach(async (doc) => {
//       const r = doc.data().rate;
//       const aa = Object.entries(r).reduce((acc1, item1) => {
//         const bb = item1[1].reduce((acc2, item2) => {
//           const cc = item2?.typeRate.reduce((acc3, item3) => {
//             acc3[item3?.qty] = item3?.rate;
//             return acc3;
//           }, {});

//           acc2[item2?.type] = cc;
//           return acc2;
//         }, {});
//         acc1[item1[0]] = bb;
//         return acc1;
//       }, {});
//       const docData = omit(doc.data(), "imageUrl");
//       const ee = { ...docData, rate: aa };
//       await i.setDoc(i.doc(i.db, "giftcardRate1", doc.id), ee, { merge: true });
//       console.log(ee);
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };
