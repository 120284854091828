import React from "react";
import { PageContainer } from "shared";

import { Fade } from "react-reveal";

import TransactionContent from "./TransactionContent";

export default function Index() {
  return (
    <PageContainer header="Transactions">
      <div className="grid grid-cols-1 gap-6 ">
        <div className="col-span-1">
          <Fade>
            <div className="">
              <div className="relative">
                <div>
                  <TransactionContent />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </PageContainer>
  );
}
