import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cryptoAuth: null,
  fiatAuth: null,
  utilityAuth: null,
  phoneList: null,
  advertPopup: null,
  portalImages: null,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    getDrawerStatus: (state, { payload }) => {
      state.drawerStatus = payload;
    },
    getCurrentWalletTab: (state, { payload }) => {
      state.drawerStatus = payload;
    },
    getCryptoAuth: (state, { payload }) => {
      state.cryptoAuth = payload;
    },
    getFiatAuth: (state, { payload }) => {
      state.fiatAuth = payload;
    },
    getUtilityAuth: (state, { payload }) => {
      state.utilityAuth = payload;
    },
    getPhoneList: (state, { payload }) => {
      state.phoneList = payload;
    },
    getAdvertPopup: (state, { payload }) => {
      state.advertPopup = payload;
    },
    getPortalImages: (state, { payload }) => {
      state.portalImages = payload;
    },
  },
});

export const {
  getDrawerStatus,
  getCurrentWalletTab,
  getCryptoAuth,
  getFiatAuth,
  getUtilityAuth,
  getPhoneList,
  getAdvertPopup,
  getPortalImages,
} = utilsSlice.actions;

export default utilsSlice.reducer;
