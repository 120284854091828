/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select, TextField, Button, Autocomplete, EnterPIN } from "components";
import { useVerifyUtility, usePurchaseUtiliity, useGetUtilityList } from "api";
import { Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { toLower } from "lodash";

export default function BuyCable({ nairaWallet }) {
  const navigate = useNavigate();

  // useState Hooks
  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [values, setValues] = useState(null);
  const [getCableList, setGetCableList] = useState(null);
  const [pricesPlan, setPricesPlan] = useState(null);
  const [packagePlan, setPackagePlan] = useState(null);

  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [renewSubscription, setRenewSubscription] = useState(false);

  const [isSubmitting, isSubmitted, purchaseUtility] = usePurchaseUtiliity();

  const [
    getVerifyDetails,
    setVerify,
    verifyCable,
    isLoading,
    isErrorVerify,
    setErrorVerify,
  ] = useVerifyUtility();

  const [utilityList, isLoadingList] = useGetUtilityList("cable-tv");

  useEffect(() => {
    const { serviceType } = values || {};

    if (serviceType) {
      const list =
        utilityList &&
        utilityList?.find((item) => item.service_type === serviceType);
      setGetCableList(list?.products);
    }
  }, [values?.serviceType, utilityList]);

  useEffect(() => {
    if (getVerifyDetails && renewSubscription) {
      const { currentBouquet, rawOutput, outstandingBalance } =
        getVerifyDetails?.user;

      setValues((prevS) => ({
        ...prevS,
        package: currentBouquet,
        packageId: 0,
        monthsPaidFor: rawOutput?.invoicePeriod,
      }));
      handleAmtChange(outstandingBalance);
    }
  }, [getVerifyDetails]);

  const handleBeneficiary = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");

    setValues((prevS) => ({
      ...prevS,
      beneficiary: val,
    }));
    // setErrorAmt(false);
    // setErrorVerify(null);
    setVerify(null);
  };

  const handleAmtChange = (e) => {
    const total = parseInt(e);
    const limit = parseInt(e) < 100 || parseInt(e) > 50000;
    const overlimit = total >= parseInt(nairaWallet?.amount || 0);
    const overcharges = parseInt(nairaWallet?.amount || 0) - total < 50;

    setValues((prevS) => ({ ...prevS, amount: e }));
    setTotalAmt(total);
    setErrorAmt(overlimit || overcharges || limit);
    setErrorText(
      overlimit
        ? "You have exceeded balance amount"
        : limit
        ? "Purchase must be within ₦100 and ₦50000"
        : overcharges
        ? "Maintain a minimum of ₦50 in wallet"
        : ""
    );
  };

  const handlePurchase = () => {
    const {
      amount,
      provider,
      providerId,
      beneficiary,
      package: packageName,
      packageId,
      monthsPaidFor,
    } = values;

    const valuesAlt = {
      amountToDebit: parseInt(amount),
      provider,
      providerName: providerId,
      providerId,
      beneficiary,
      productType: "cable-tv",
      productPlan: packageName,
      amount: parseInt(amount),
      transactionDetails: {
        productId: packageId,
        smartcardNumber: beneficiary,
        amount: parseInt(amount),
        monthsPaidFor,
      },
    };

    purchaseUtility(valuesAlt);
  };

  const disabledTrans = !(
    values &&
    parseInt(values?.amount) &&
    values?.beneficiary?.length >= 10 &&
    `${values?.packageId}` &&
    values?.monthsPaidFor &&
    !errorAmt
  );

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setValues(null);
      setValues(null);
      setGetCableList(null);
      setPackagePlan(null);
      setRenewSubscription(true);
      setVerify(null);
      setTotalAmt(0);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        navigate(-1);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  return (
    <div className="">
      <div className="rounded-sm border border-gray-200 bg-white p-4 shadow-card-box">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-2">
            <Select
              titleValue="name"
              dropDown={utilityList}
              inputLabel="Provider"
              disabled={isSubmitting}
              handleChange={(e) => {
                setValues((prevS) => ({
                  provider: e.name,
                  providerId: e.service_type,
                  serviceType: e.service_type,
                }));
                setErrorAmt(false);
                setVerify(null);
                setPackagePlan(null);
                setPricesPlan(null);
                setErrorVerify(false);
              }}
              valueAlt={values?.provider || ""}
              isLoading={isLoadingList}
            />
          </div>

          {!renewSubscription && (
            <>
              <div className="col-span-2">
                <Autocomplete
                  inputLabel="Cable Plan"
                  options={getCableList || []}
                  titleValue="name"
                  handleChange={(e) => {
                    setValues((prevS) => ({
                      ...prevS,
                      package: e?.name,
                      packageId: e?.code,
                    }));
                    setPricesPlan(null);
                    handleAmtChange(null);
                  }}
                  valueAlt={packagePlan || ""}
                  setValueAlt={(e) => setPackagePlan(e)}
                  disabled={isSubmitting || !getCableList}
                />
              </div>

              <div className="col-span-2">
                <Autocomplete
                  inputLabel="Avalaible Price Options"
                  options={
                    packagePlan?.availablePricingOptions?.map((i) => ({
                      ...i,
                      name: `${i?.monthsPaidFor} Month Paid for`,
                    })) || []
                  }
                  titleValue="name"
                  subTitleValue="price"
                  handleChange={(e) => {
                    setValues((prevS) => ({
                      ...prevS,
                      // invoicePeriod: e?.invoicePeriod,
                      monthsPaidFor: e?.invoicePeriod,
                      amount: `${e?.price}`,
                    }));
                    handleAmtChange(e?.price);
                  }}
                  valueAlt={pricesPlan || ""}
                  setValueAlt={(e) => setPricesPlan(e)}
                  disabled={isSubmitting || !getCableList}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="amount"
                  label="Amount"
                  value={values?.amount || ""}
                  startAdornment="₦"
                  formatInput
                  disableAutoComplete
                  placeholder="0.00"
                  readOnly
                  error={errorAmt === true}
                  showError={errorAmt === true}
                  handleChange={handleAmtChange}
                  // helperText={errorText}
                />
                {errorAmt && (
                  <Slide down duration={300}>
                    <div className="mt-2 text-xs font-normal text-red-500">
                      {errorText}
                    </div>
                  </Slide>
                )}
              </div>
            </>
          )}

          <div className="col-span-2">
            <TextField
              name="beneficiary-number"
              label="Smartcard Number"
              // type="number"
              placeholder="Input smartcard number"
              value={values?.beneficiary || ""}
              handleChange={(e) => {
                handleBeneficiary(e);
                // setErrorAmt(false);
                // setErrorText(null);
              }}
              disableAutoComplete
              endAdornment={
                <Button
                  label="Verify"
                  gradient
                  handleClick={() => {
                    verifyCable({
                      serviceType: values?.serviceType,
                      customerId: values?.beneficiary,
                    });
                  }}
                  isSubmitting={isLoading}
                  disabled={
                    !(values?.provider && values?.beneficiary?.length >= 10)
                  }
                />
              }
            />
            {(isErrorVerify || isLoading) && !getVerifyDetails && (
              <Slide down duration={300}>
                {isLoading ? (
                  <div className="mt-2 text-xs font-normal text-cyan-500">
                    Loading ...
                  </div>
                ) : (
                  <div className="mt-2 text-xs font-normal text-red-500">
                    {isErrorVerify}
                  </div>
                )}
              </Slide>
            )}
          </div>

          {getVerifyDetails && (
            <>
              <div className="col-span-2">
                <TextField
                  name="beneficiary-name"
                  label="Beneficiary Name"
                  value={getVerifyDetails?.user?.name || ""}
                  readOnly
                />
              </div>
              <div className="col-span-2">
                <TextField
                  name="current-bouquet"
                  label="Current Bouquet"
                  value={getVerifyDetails?.user?.currentBouquet || ""}
                  readOnly
                />
              </div>

              {renewSubscription && (
                <div className="col-span-2">
                  <TextField
                    name="amount"
                    label="Amount"
                    value={getVerifyDetails?.user?.outstandingBalance || ""}
                    startAdornment="₦"
                    formatInput
                    disableAutoComplete
                    placeholder="0.00"
                    readOnly
                    error={errorAmt === true}
                    showError={errorAmt === true}
                    handleChange={handleAmtChange}
                    // helperText={errorText}
                  />
                  {errorAmt && (
                    <Slide down duration={300}>
                      <div className="mt-2 text-xs font-normal text-red-500">
                        {errorText}
                      </div>
                    </Slide>
                  )}
                </div>
              )}
              <div className="col-span-2">
                <Button
                  label="Continue"
                  fullWidth
                  gradient
                  disabled={disabledTrans}
                  handleClick={() => setOpen(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <EnterPIN
        open={open}
        setOpen={setOpen}
        openIsSubmitted={openIsSubmitted}
        setIsSubmitted={setIsSubmitted}
        processSubmit={handlePurchase}
        btnText={"Subscribe TV Cable"}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}
