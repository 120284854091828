import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";

import * as i from "../lib";
import { axiosCall } from "../helpers";
import {
  getNairaWallet,
  getCedisWallet,
  getCefaWallet,
  getWithdrawalAccount,
  getUtilitiesBeneficiary,
  getDepositAccount,
  getWalletStatus,
} from "redux/reducers/walletReducers";
import { getDeviceType, notification } from "utils";
import { getTransactionStatus } from "redux/reducers/transactionReducers";

const auth = i.getAuth();

const url = (endpoint) => `${i.baseURL}/wallet/${endpoint}`;

export const useSnapShot = (actionDispatcher, payloadKey, collection) => {
  const dispatch = useDispatch();

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    if (payloadKey === "withdrawalAccount")
      dispatch(getTransactionStatus(true));
    try {
      unsub = i.onSnapshot(i.doc(i.db, collection, userUid), (doc) => {
        dispatch(
          actionDispatcher({
            [payloadKey]: doc.data(),
          })
        );
        if (payloadKey === "withdrawalAccount")
          dispatch(getTransactionStatus(false));
      });
    } catch (error) {
      dispatch(
        actionDispatcher({
          [payloadKey]: null,
        })
      );
      if (payloadKey === "withdrawalAccount")
        dispatch(getTransactionStatus(true));
    }
    return unsub;
  }, [dispatch, payloadKey, userUid]);
};

export const useNairaWallet = () => {
  return useSnapShot(getNairaWallet, "nairaWallet", "nairaWallet");
};

export const useCedisWallet = () => {
  return useSnapShot(getCedisWallet, "cedisWallet", "cedisWallet");
};

export const useCefaWallet = () => {
  return useSnapShot(getCefaWallet, "cefaWallet", "cefaWallet");
};

export const useWithdrawBankAccount = () => {
  return useSnapShot(
    getWithdrawalAccount,
    "withdrawalAccount",
    "userWithdrawalBankDetails"
  );
};

export const useUtilitiesDetails = () => {
  return useSnapShot(
    getUtilitiesBeneficiary,
    "utilityBeneficiary",
    "userUtilityDetails"
  );
};

// export const useDepositBankAccount = () => {
//   return useSnapShot(
//     getDepositAccount,
//     "userDepositAcct",
//     "userDepositBankDetails"
//   );
// };

export const useCreateDepositAcct = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [getUserAcct, setUserAcct] = useState(null);
  const { user_details } = useStore()?.getState()?.profile;
  const { displayName } = user_details || {};

  const createDepositAcc = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      customerEmail: auth.currentUser.email,
      deviceType: getDeviceType(),
    };

    try {
      if (
        !(
          displayName?.trim()?.split(" ").length >= 2 &&
          displayName
            ?.trim()
            ?.split(" ")
            .every((i) => i.length > 2)
        )
      ) {
        notification("warning", "Update account name to use full name.");
        return;
      }
      setSubmitting(true);
      const getAcct = await axiosCall(
        url("create_deposit_account"),
        valueFormat,
        true
      );

      setSubmitting(false);
      setSubmitted(true);
      setUserAcct(getAcct?.data?.data);
      notification("success", "Deposit Account successfully created.");
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [isSubmitting, isSubmitted, createDepositAcc, getUserAcct];
};

export const useGetDepositAcct = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getAcct = async () => {
      try {
        dispatch(getDepositAccount({ isLoaded: false }));

        const resp = await axiosCall(url("get_deposit_account"), {}, true);

        dispatch(
          getDepositAccount({ isLoaded: true, depositAcct: resp.data.data })
        );
      } catch (error) {
        // console.log(error);
        dispatch(getDepositAccount({ isLoaded: false, depositAcct: null }));
        notification("danger", "An error occured! Try again later.", "error");
      }
    };
    getAcct();
  }, []);
};
