import React, { useState, Fragment } from "react";
import { AddTask } from "@mui/icons-material";

import EditIcon from "@mui/icons-material/Edit";
import { Slide } from "react-reveal";
import { startCase } from "lodash";
import {
  BackPage,
  Container,
  Button,
  Dialog,
  EnterPIN,
  SimpleIcon,
  SimpleIconButton,
} from ".";
import { classNames, copyText } from "utils";

export function Summary({ tradeSummary, handlePage, processSubmit }) {
  const [open, setOpen] = useState(null);
  return (
    <div>
      <BackPage handleClick={handlePage} />
      <div className="overflow-hidden rounded-2xl bg-card p-2 text-text-color shadow-md">
        <div className="bg-gray-50 px-4 py-4 sm:flex sm:px-4">
          <div className="flow-root">
            <h5 className="">Trade Summary</h5>
          </div>
        </div>

        <div className="relative grid gap-6 bg-white px-4 py-4 sm:gap-8 sm:p-4">
          {Object.entries(tradeSummary).map((item) => {
            return (
              <Slide left key={`${item[0]}-${item[1]}`}>
                <div className="-m-3 flex items-start rounded border-b border-gray-300 p-3 hover:bg-gray-50">
                  <AddTask sx={{ fontSize: 20 }} className="text-color-main" />
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-900">
                      {startCase(item[0])}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">{item[1]}</p>
                  </div>
                </div>
              </Slide>
            );
          })}
        </div>
      </div>
      <div className="mt-4" />
      <Container>
        <Button
          label="Continue"
          fullWidth
          gradient
          handleClick={() => setOpen(true)}
        />
      </Container>
      <Dialog {...{ open, setOpen }} title="Enter 6-digit PIN ">
        <EnterPIN
          setOpen={setOpen}
          processSubmit={processSubmit}
          btnText="Withdraw Fund"
        />
      </Dialog>
    </div>
  );
}

const SummaryDropDown = ({ item }) => {
  const [openD, setOpenD] = useState(false);
  const val = item[1]?.value;
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <p className="text-sm font-normal text-gray-900">
          {startCase(item[0])}
        </p>
        <SimpleIconButton
          icon={openD ? "ExpandLess" : "ExpandMore"}
          onClick={() => setOpenD(!openD)}
        />
      </div>
      {openD && val}
    </div>
  );
};

export const TransactionSummary = ({ summary, useValue }) => {
  return (
    <>
      {Object.entries(summary).map((item) => {
        const disableField = item[1]?.disableDisplay;
        return (
          <Fragment key={item[0]}>
            {!disableField && item[1].value && (
              <div className="-m-3 flex items-start border-b border-slate-100 p-3 hover:bg-gray-50">
                {/* {!disableIcon && (
                  <CheckCircleOutlineIcon
                    sx={{ fontSize: 20 }}
                    className="text-color-main"
                  />
                )} */}

                <div className="flex w-full flex-row items-center justify-between">
                  {item[1]?.type === "dropdown" ? (
                    <SummaryDropDown item={item} />
                  ) : (
                    <>
                      <p className="mr-4 text-sm text-gray-900">
                        {startCase(item[0])}
                      </p>
                      <p className="break-all text-sm text-gray-500">
                        {/* {useValue ? item[1]?.value : item[1]} */}
                        {item[1]?.value}
                        {item[1]?.clickable && (
                          <span className="ml-2">
                            <SimpleIconButton
                              fontSize={18}
                              icon="ContentCopy"
                              onClick={() => {
                                copyText(
                                  item[1]?.value,
                                  "Copied successfully."
                                );
                              }}
                            />
                          </span>
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export const EditSummary = ({ children, title, value }) => {
  return (
    <div className="-m-3 flex items-start border-b border-gray-300 p-3 hover:bg-gray-50">
      <EditIcon sx={{ fontSize: 20 }} className="text-color-main" />
      <div className="ml-4 w-full">
        <p className="text-sm font-medium text-gray-600">{title}</p>
        {value?.type === "component" && (
          <div className="text-sm text-gray-500">{value?.value}</div>
        )}

        {value?.type === "text" && (
          <p className="mt-1 text-sm text-gray-500">{value?.value}</p>
        )}

        <div className="mt-4 text-sm text-gray-500">{children}</div>
      </div>
    </div>
  );
};
