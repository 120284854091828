import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";

import { Form } from "components";
import { AuthContainer } from "shared";
import { registerValidationSchema } from "utils/formSchema";
import { useCheckRegisterDetails } from "api";

const fieldList = [
  {
    name: "displayName",
    type: "text",
    label: "Full Name",
    inputType: "textfield",
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    inputType: "textfield",
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Phone Number",
    inputType: "phonefield",
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    inputType: "textfield",
  },
  {
    name: "referralCode",
    type: "text",
    label: "Referral Code",
    inputType: "referralfield",
    bgColor: "bg-background_main_alt",
  },
];

export default function Register() {
  const [showReferralInput, setShowReferralInput] = useState(false);

  const [isLoading, isLoaded, userDetails, phoneOTPRef, checkRegisterDetails] =
    useCheckRegisterDetails();

  const navigate = useNavigate();

  const { search } = useLocation();

  const email = new URLSearchParams(search).get("email");
  const referralCode = new URLSearchParams(search).get("referralCode");
  const marketingReferral = new URLSearchParams(search).get("marketing");
  const bitMediaRef = new URLSearchParams(search).get("utm_source");

  const handleSubmit = (values) => {
    checkRegisterDetails(values);
  };

  // console.log(isLoaded, userDetails, " registration ");
  useEffect(() => {
    if (isLoaded && userDetails) {
      navigate("/verify-email", {
        replace: true,
        state: {
          ...userDetails,
          phoneOTPRef,
          marketingReferral: marketingReferral,
          bitMediaReferral: bitMediaRef,
        },
      });
    }
  }, [
    isLoaded,
    marketingReferral,
    bitMediaRef,
    navigate,
    userDetails,
    phoneOTPRef,
  ]);

  const initialFieldValues = {
    displayName: "",
    email: email || "",
    phoneNumber: "",
    password: "",
    referralCode: referralCode || "",
  };

  return (
    <AuthContainer
      title="Create Account"
      subTitle="Let’s get you started! This will only take a few minutes."
      footer={{ link: "/login", text: "Already have an account? Login!" }}
    >
      <Form
        fieldList={fieldList}
        initialFieldValues={initialFieldValues}
        buttonText="Register"
        buttonLoading={isLoading}
        handleSubmit={handleSubmit}
        validationSchema={registerValidationSchema}
        displayReferralCode={showReferralInput}
        setShowReferralInput={setShowReferralInput}
        fullWidth
        extraInfo={
          <div className="text-sm">
            By clicking on the Register button, you agree to our{" "}
            <a href="/" className="text-color-main">
              Privacy Policy & Terms.
            </a>
          </div>
        }
      />
    </AuthContainer>
  );
}
