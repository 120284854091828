import React, { useState } from "react";
import { SimpleIcon } from "components";
import { useSelector } from "react-redux";
import Drawer from "./Drawer";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "utils";
import { useUserTransAnalysisCount } from "api";
import { sum } from "lodash";

export default function CheckTradeStatus() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { transCount } = useSelector((state) => state.transaction);
  const { pending, active, approved, declined } = transCount || {};
  const sumPending = sum([pending, active, approved, declined]);
  useUserTransAnalysisCount();

  return (
    <div className="">
      <button
        className="shadow-dialog-0 bg-gradient-to-bl-0 relative box-border flex items-center justify-center rounded border-2 border-white from-button_gradient_from to-bg_gradient_to py-[8px] px-[16px] text-sm font-semibold text-white duration-500 ease-in"
        onClick={() => setOpenDrawer(true)}
      >
        Check Trade Status
        {sumPending ? (
          <div className="absolute top-0 right-0 ml-1 flex h-5 w-5 animate-bounce items-center justify-center rounded-full bg-red-500 duration-500">
            <div className="text-xs font-semibold text-white">{sumPending}</div>
          </div>
        ) : null}
      </button>
      <Drawer
        anchor="right"
        state={openDrawer}
        setState={setOpenDrawer}
        title={"Giftcard Trade Status"}
        closeOnOverlayClick
      >
        <div className="drawer-content p-6">
          <AnalysisCard />
        </div>
      </Drawer>
    </div>
  );
}

const analysisList = [
  {
    name: "Pending Trades",
    bgColor: "bg-[#7f5621]",
    textColor: "text-[#7f5621]",
    num: 0,
    icon: "Pending",
    id: "pending",
  },
  {
    name: "Active Trades",
    bgColor: "bg-cyan-800",
    textColor: "text-cyan-800",
    num: 0,
    icon: "WatchLater",
    id: "active",
  },
  {
    name: "Approved Trades",
    bgColor: "bg-[#358041]",
    textColor: "text-[#358041]",
    num: 0,
    icon: "CheckCircle",
    id: "approved",
  },
  {
    name: "Declined Trades",
    bgColor: "bg-[#801c17]",
    textColor: "text-[#801c17]",
    num: 0,
    icon: "Cancel",
    id: "declined",
  },
];

export function AnalysisCard() {
  const navigate = useNavigate();
  const { transCount, isTransCountLoaded } = useSelector(
    (state) => state.transaction
  );
  const { pending, active, approved, declined } = transCount || {};
  const sumTrades = sum([pending, active, approved, declined]);

  return (
    <div className="shadow-md-0">
      <div className="mb-4 text-sm font-semibold">
        * Trades Analysis for up to 3 hours will be shown here.
      </div>
      {sumTrades ? (
        <div className="grid grid-cols-1 gap-2">
          {analysisList.map((item) => (
            <div className="col-span-1" key={item.name}>
              <div className="relative top-0 col-span-1 shadow-card-box duration-500 ease-in hover:shadow-lg">
                <div className="flex-1 rounded-sm border border-slate-100 bg-white p-4">
                  <button
                    to={`/transactions`}
                    className="flex h-full w-full"
                    onClick={() => {
                      navigate("/transactions", {
                        replace: true,
                        state: { page: 1 },
                      });
                    }}
                  >
                    <div className="flex items-start justify-between">
                      <div
                        className={classNames(
                          "flex h-8 w-8 items-center justify-center rounded",
                          item.bgColor
                        )}
                      >
                        <SimpleIcon
                          icon={item.icon}
                          frontColor="text-white"
                          fontSize={16}
                        />
                      </div>
                    </div>

                    <div className="ml-4 flex flex-col items-start text-sm">
                      <div className="flex flex-col">
                        <h6
                          className={`uppercase-0 font-bold tracking-wide ${item.textColor}`}
                        >
                          {item.name}
                        </h6>
                      </div>
                      <div className="mt-1 font-bold leading-none text-gray-500">
                        {isTransCountLoaded ? transCount?.[item.id] || 0 : 0}
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="relative shadow-card-box duration-500 ease-in hover:shadow-lg">
          <div className="flex-1 rounded-sm border border-slate-100 bg-white p-4">
            <div className="text-sm">
              <div className="flex flex-col">
                <h6 className={`font-bold tracking-wide`}>
                  No current active trade
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
