import React, { useState, useEffect } from "react";
import { Bounce } from "react-reveal";
import { omit } from "lodash";

import { Button, Drawer, Container, BackPage } from "components";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { formatMoney, symToCur } from "utils";
import {
  calculateQty,
  calcTot,
  giftcardPriceCalc,
  ValueButton,
  displayQtyContent,
} from "../helpers";

import Chips from "../components/Chips";

export default function SelectQuantity({
  handleStep,
  cardRates,
  getRateFac,
  card,
  cardType,
  addQty,
  currentQty,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [qty, setQty] = useState({});

  const { defaultFiat } = getRateFac;

  useEffect(() => {
    setQty(currentQty ? currentQty : {});
  }, []);

  const getWRate = (qty, rate) => giftcardPriceCalc(qty, rate, getRateFac);

  const calcQty = (qtyI, type) =>
    calculateQty({
      qty: qtyI,
      type,
      getRateFac,
      cardRates,
      state: qty,
      setState: setQty,
    });

  const getTot = calcTot(qty);

  const displayContent = displayQtyContent(qty, cardType, defaultFiat);

  const deleteContent = (key) => () => {
    setQty((prevS) => omit(prevS, key));
  };

  return (
    <div className="">
      <BackPage handleClick={handleStep} />

      <div>
        {Object.keys(cardRates).length ? (
          <div>
            <Container>
              <Chips {...{ displayContent, deleteContent, setOpenDrawer }} />
            </Container>

            {Object.keys(qty).length > 0 && (
              <>
                <div className="mt-4" />
                <Container>
                  <div className="bg-white p-3 text-center text-text-color">
                    <div>
                      <div className="text-sm">Total amount to sell</div>
                      <div className="text-lg font-bold text-color-main">
                        {formatMoney(getTot, defaultFiat)}
                      </div>
                    </div>
                  </div>
                </Container>
                <div className="mt-4" />
                <Container>
                  <Button
                    label="Continue"
                    fullWidth
                    gradient
                    handleClick={() => addQty({ quantity: qty, total: getTot })}
                  />
                </Container>
              </>
            )}

            <Drawer
              anchor="bottom"
              state={openDrawer}
              setState={setOpenDrawer}
              closeOnOverlayClick
            >
              <div className="bg-background_main">
                <div className="mb-14 grid grid-cols-2 gap-3 p-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:gap-3">
                  {Object.entries(cardRates)?.map((t) => {
                    const rate = getWRate(t[0], t[1]);
                    return (
                      <div className="col-span-1" key={t[0]}>
                        <div className="relative top-0 rounded-sm bg-card p-2 shadow-md">
                          <div className="rounded-sm bg-white p-2">
                            <div className="min-h-16 w-full">
                              <div className="flex flex-col items-center justify-between">
                                <div className="text-center">
                                  <div className="text-gray-500">
                                    <h4 className="text-sm">
                                      {symToCur(cardType)}
                                      {t[0]} {card}
                                    </h4>
                                  </div>
                                  <div className="text-center">
                                    <h3 className="num mt-2 text-sm font-bold text-gray-600">
                                      {formatMoney(rate?.price, defaultFiat)}
                                    </h3>
                                    <div className="-mt-0 text-xs text-gray-400">
                                      Rate: @{rate?.rate}/$
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="mt-5 flex items-center overflow-hidden">
                                    <ValueButton
                                      Icon={RemoveIcon}
                                      handleClick={() => {
                                        calcQty(t[0], "remove");
                                      }}
                                      disabled={
                                        qty?.[t[0]]?.count <= 0 ||
                                        !qty?.[t[0]]?.count
                                      }
                                    />
                                    <Bounce
                                      // bottom
                                      spy={qty?.[t[0]]?.count}
                                      // duration={800}
                                    >
                                      <div className="num mx-3 text-base font-bold text-gray-600">
                                        {qty?.[t[0]]?.count
                                          ? qty?.[t[0]]?.count
                                          : 0}
                                      </div>
                                    </Bounce>

                                    <ValueButton
                                      Icon={AddIcon}
                                      handleClick={() => {
                                        calcQty(t[0], "add");
                                      }}
                                      disabled={qty?.[t[0]]?.count >= 10}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="bg-background absolute bottom-0 right-0 h-14 w-full">
                  <div className="flex h-full items-center justify-center px-8 text-gray-600">
                    <div className="">
                      <Button
                        label="Continue"
                        gradient
                        handleClick={() => setOpenDrawer(false)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        ) : (
          <Container>
            <div className="bg-white p-4 text-text-color">
              Not avaliable for trading.
            </div>
          </Container>
        )}
      </div>
    </div>
  );
}
