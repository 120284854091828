/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import GridViewIcon from "@mui/icons-material/GridView";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AddchartIcon from "@mui/icons-material/Addchart";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { classNames } from "utils";
import Notification from "./Notification";
import Avatar from "./Avatar";

const navigation = [
  { name: "Dashboard", linkTo: "/dashboard", Icon: GridViewIcon },
  {
    name: "Trade Giftcard",
    linkTo: "/giftcard",
    Icon: CardGiftcardOutlinedIcon,
  },
  {
    name: "Wallets",
    linkTo: "/wallets",
    Icon: AccountBalanceWalletOutlinedIcon,
  },
  { name: "Utilities", linkTo: "/utilities", Icon: BubbleChartOutlinedIcon },
  { name: "Transactions", linkTo: "/transactions", Icon: AddchartIcon },
  { name: "Settings", linkTo: "/settings", Icon: SettingsOutlinedIcon },
];

export default function NavbarMobileDrawer({ open, setOpen, logout }) {
  const userNavigation = [
    { name: "Your Profile", handleClick: () => {} },
    { name: "Sign out", handleClick: () => logout() },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[200] overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-background_main_alt shadow-xl">
                  <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="mb-4 text-2xl font-bold text-gray-600">
                        {" "}
                        Kaapo
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="md:hidden">
                      <div className="space-y-1 divide-y divide-slate-200 px-2 py-3 sm:px-3">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.linkTo}
                            className={classNames(
                              item.current
                                ? "text-primary-600"
                                : "hover:text-primary-600 text-gray-700",
                              "flex items-center p-3 text-base font-normal"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.Icon fontSize="small" />
                            <span className="ml-3">{item.name}</span>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
