import React, { useState } from "react";
import { formatDate } from "utils";
import { TextField, TransactionSummary } from "components";
import TotalTrans from "pages/Wallets/sub-pages/WithdrawNaira/shared/TotalTrans";

export default function AccountDetails({ acct }) {
  const [amount, setAmount] = useState({ amount: "", totalAmt: 0 });

  const summary = {
    accountNumber: {
      value: acct?.accounts?.accountNumber,
      type: "text",
    },
    bankName: {
      value: acct?.accounts?.bankName,
      type: "text",
    },
    accountName: {
      value: acct?.accounts?.accountName,
      type: "text",
    },
    dateCreated: {
      value: formatDate(acct?.createdOn),
      type: "text",
    },
  };

  const charge = 1;

  const handleAmtChange = (e) => {
    const val = e === "" ? 0 : e;
    setAmount((prevS) => ({
      amount: e,
      totalAmt: parseInt(val) - parseInt(val) * (charge / 100),
    }));
  };

  return (
    <div>
      <h6 className="mb-2 text-sm text-gray-400">
        This account number is dedicated to you.{" "}
        {/* <a href="/" className="text-color-main">
          Learn more
        </a> */}
      </h6>
      <div className="rounded bg-slate-50 p-2">
        <div className="relative grid gap-6 bg-white px-4 py-4 sm:gap-8 sm:p-4">
          <TransactionSummary summary={summary} disableIcon />
        </div>
      </div>
      <div className="mt-6">
        <h6 className="mb-2 text-sm font-bold text-gray-600">
          Deposit Calculator
        </h6>
        <div className="rounded bg-slate-50 p-2">
          <div className="grid grid-cols-1 gap-6 bg-white p-4">
            <div className="col-span-2">
              <TextField
                name="depositAmount"
                type="text"
                label="Deposit Amount"
                value={amount?.amount}
                handleChange={handleAmtChange}
                startAdornment="₦"
                formatInput
                disableAutoComplete
                placeholder="0.00"
              />
            </div>
            <TotalTrans totalAmt={amount?.totalAmt} per={charge} />
          </div>
        </div>
      </div>
    </div>
  );
}
