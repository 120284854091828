import React, { useCallback, useState } from "react";
import { Fade } from "react-reveal";

import { classNames } from "utils";
import { Button, Drawer, IconBox, ToggleAmount } from "components";
import { useSelector } from "react-redux";
import { useFiatBonusWalletListProps } from "api";
import FundFiat from "pages/Wallets/sub-pages/FundFiat";
import { useNavigate } from "react-router-dom";
import BonusFiat from "pages/Wallets/sub-pages/BonusFiat";

export default function WalleSection({ account }) {
  const navigate = useNavigate();
  const { depositAccount } = account || {};
  const { depositAcct, isLoaded } = depositAccount || {};

  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);

  const { user_details } = useSelector((state) => state.profile || {});
  const { fiatAuth } = useSelector((state) => state.utils);

  const { defaultCurrency } = user_details || {};

  const { walletProps, isLoadedWalletProps } = useFiatBonusWalletListProps();

  const currentWallet = walletProps?.find(
    (item) => item.id === defaultCurrency
  );

  const propsFiat = useCallback(() => {
    return walletProps.find((item) => item.id === defaultCurrency);
  }, [defaultCurrency, walletProps])();

  const propsFiatBonus = useCallback(() => {
    return walletProps.find((item) => item.id === `${defaultCurrency}Bonus`);
  }, [defaultCurrency, walletProps])();

  return (
    <Fade>
      <div className="col-span-1 min-h-[220px]">
        <div className="card hero-pattern h-full rounded-sm border border-gray-200 bg-gray-50 bg-left  p-6 shadow-card-box">
          {/* space-y-0 */}
          <div className="flex h-full flex-col justify-between space-y-6">
            <div className="flex items-start justify-between">
              <h6 className="text-sm font-bold uppercase tracking-wide text-gray-500">
                {propsFiat?.name} Wallet
              </h6>

              <IconBox icon={"AccountBalanceWalletTwoTone"} gradient />
            </div>
            <div className="flex items-center justify-between gap-1 md:gap-1">
              {[
                {
                  name: "Available Fund",
                  wallet: propsFiat?.amount || 0,
                  value: "availableFund",
                  onClick: () =>
                    navigate("/wallets", {
                      state: { walletId: defaultCurrency },
                    }),
                },
                {
                  name: "Bonus Fund",
                  wallet: propsFiatBonus?.amount || 0,
                  value: "referralFund",
                  onClick: () => {
                    setOpenDrawer(true);
                    setDrawerContent("bonus");
                  },
                },
              ].map((item, index) => (
                <div
                  key={item.name}
                  className={classNames(
                    "flex cursor-pointer flex-col space-y-1",
                    index === 1 ? "items-end" : ""
                  )}
                  onClick={item?.onClick}
                >
                  <h4 className="text-xs font-bold leading-tight tracking-widest text-gray-700">
                    {item.name}
                  </h4>
                  <ToggleAmount walletAmt={item.wallet} sym={propsFiat?.sym} />
                </div>
              ))}
            </div>
            <div className="flex flex-row gap-2 md:gap-4">
              <Button
                label={`Withdraw ${defaultCurrency} `}
                gradient
                link="/withdraw"
                state={{
                  type: defaultCurrency,
                  amount: propsFiat?.amount,
                  sym: propsFiat?.sym,
                }}
              />
              {defaultCurrency === "naira" && (
                <Button
                  label={`Fund ${"Wallet"}`}
                  color="default"
                  handleClick={() => {
                    setOpenDrawer(true);
                    setDrawerContent("fiat");
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <Drawer
          anchor="right"
          state={openDrawer}
          setState={setOpenDrawer}
          title={
            drawerContent === "fiat"
              ? depositAcct
                ? "Deposit Account Details"
                : "Create Bank Account"
              : "Transfer Bonus"
          }
          {...(!depositAcct && { closeOnOverlayClick: true })}
        >
          <div className="drawer-content p-6">
            {drawerContent === "fiat" && (
              <FundFiat
                displayPage={(typeCurr) => fiatAuth?.["naira"]?.[typeCurr]}
              />
            )}

            {drawerContent === "bonus" && (
              <BonusFiat
                currentWalletName={currentWallet?.id}
                displayPage={(type) => fiatAuth?.[currentWallet?.id]?.[type]}
              />
            )}
          </div>
        </Drawer>
      </div>
    </Fade>
  );
}
