import React, { useState } from "react";
import { FullWidthTabs } from "components";

import WithdrawComp from "./WithdrawComp";

export default function WithdrawNaira(props) {
  const tabList = [
    {
      label: "Own account",
      Icon: "AccountBalance",
      account: "ownDetails",
      id: "own-account",
    },
    {
      label: "Beneficiary account",
      Icon: "AccountBalance",
      account: "beneficiaryDetails",
      id: "beneficiary-account",
    },
    {
      label: "Other account",
      Icon: "AccountBalance",
      account: null,
      id: "other-account",
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  const displayPage = props?.displayPage(currentTab?.id);

  return (
    <FullWidthTabs
      {...{ currentTab, setCurrentTab, tabList }}
      Comp={
        <WithdrawComp {...currentTab} {...props} displayPage={displayPage} />
      }
    />
  );
}
