import React, { useState } from "react";
import { useSelector } from "react-redux";
import bcrypt from "bcryptjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Dialog,
  SuccessTransaction,
  IconBox,
  PopupDrawer,
  SectionContent,
} from ".";
import { useBreakpoint } from "utils";
import OTPInput from "./OTPInput";
import CreatePin from "pages/Settings/drawer-content/CreatePin";

export default function EnterPIN({
  open,
  setOpen,
  openIsSubmitted,
  setIsSubmitted,
  processSubmit,
  btnText,
  isSubmitting,
}) {
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState();
  const [secure, setSecure] = useState(true);
  const [openSetPin, setOpenSetPin] = useState(false);
  const { user_details } = useSelector((state) => state.profile);

  const breakpoint = useBreakpoint();

  const handleComparePIN = (e) => {
    setOTP(e);
    if (e?.length === 6) {
      const pin = user_details?.transactionPIN;
      setOtpError(bcrypt.compareSync(e, pin));
    } else {
      setOtpError();
    }
  };

  return (
    <>
      {open && (
        <Dialog {...{ open, setOpen }} title="" maxWidth="xs">
          <div className="p-6">
            {user_details?.transactionPIN ? (
              <div className="text-center text-sm">
                <h5 className="mb-4 text-center text-base font-normal">
                  Enter your PIN to complete transaction
                </h5>

                <div className="flex flex-col items-center">
                  <button onClick={() => setSecure(!secure)}>
                    {!secure ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </button>
                  <div>
                    <OTPInput
                      value={OTP}
                      onChange={handleComparePIN}
                      OTPLength={6}
                      otpType={breakpoint.lg ? "text" : "number"}
                      disabled={false}
                      secure={secure}
                      inputClassName="border rounded-sm border-gray-500 text-gray-500 !m-1 focus:border-color-main outline-none focus:border-2 focus:rounded-sm text-[16px]"
                    />
                    {otpError !== undefined ? (
                      <>
                        {!otpError ? (
                          <span className="error-message text-xs text-red-500">
                            PIN does not match
                          </span>
                        ) : (
                          <div className="mt-2">
                            <Button
                              label={btnText}
                              gradient
                              handleClick={processSubmit}
                              isSubmitting={isSubmitting}
                            />
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="action mt-6 w-full">
                    <div className="action-buttons flex justify-between space-x-2">
                      <Button
                        label="Reset PIN"
                        link="/settings"
                        state={{ page: 3 }}
                        color="default"
                        disabled={isSubmitting}
                      />
                      <Button
                        label="Close"
                        handleClick={() => setOpen(false)}
                        variant="outlined"
                        color="neutral"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="rounded bg-white p-4">
                <div className="flex flex-col items-center justify-center">
                  <IconBox icon={"PriorityHigh"} gradient shadowAlt />
                  <p className="mt-4 text-sm">Create PIN to continue.</p>
                  <div className="action mt-2">
                    <div className="action-buttons flex flex-row gap-4">
                      <Button
                        label="Create PIN"
                        gradient
                        // link="/settings"
                        // state={{ page: 1 }}
                        handleClick={() => setOpenSetPin(true)}
                      />
                      <Button
                        label="Close"
                        handleClick={() => setOpen(false)}
                        variant="outlined"
                        color="neutral"
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Dialog>
      )}
      <PopupDrawer open={openSetPin} setOpenDrawer={setOpenSetPin}>
        <div className="bg-gray-50 p-4 sm:flex sm:px-4">
          <h5 className="font-bold">{"Create PIN"}</h5>
        </div>
        <div className="mt-0 bg-white p-2">
          <div className="drawer-content">
            {/* <SectionContent> */}
            <CreatePin setDrawer={setOpenSetPin} />
            {/* </SectionContent> */}
          </div>
        </div>
      </PopupDrawer>

      {openIsSubmitted && (
        <SuccessTransaction open={openIsSubmitted} setOpen={setIsSubmitted} />
      )}
    </>
  );
}
