import React, { useEffect, useState } from "react";
import { classNames } from "utils";

export default function OTPInput({
  inputClassName,
  otpType,
  onChange,
  OTPLength,
  secure,
}) {
  const [otp, setOtp] = useState(new Array(OTPLength).fill(""));

  const handleChange = (e, ind) => {
    const ele = e.target;
    if (isNaN(ele.value)) return false;

    const eleV = ind === OTPLength - 1 ? ele.value[0] : ele.value;
    setOtp([...otp.map((d, idx) => (idx === ind ? eleV : d))]);
  };

  const handleKeyup = (e) => {
    if ([8, 46].includes(e.keyCode)) {
      if (e.target.previousSibling) e.target.previousSibling.focus();
    } else if (e.target.nextSibling && Boolean(e.target.value)) {
      e.target.nextSibling.focus();
    }
  };

  useEffect(() => {
    onChange(otp.join(""));
  }, [otp]);

  return (
    <div className="my-1">
      <div className="flex items-center justify-center gap-2">
        {otp.map((item, index) => (
          <input
            // type={otpType}
            type={secure ? "password" : otpType}
            inputMode="numeric"
            key={index}
            value={item}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChange(e, index)}
            onKeyUp={handleKeyup}
            className={classNames(
              "h-8 w-8 text-center outline-none",
              "flex items-center justify-center",
              inputClassName
            )}
            maxLength="1"
autoComplete="off"
          />
        ))}
      </div>
    </div>
  );
}
