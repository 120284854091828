import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { SimpleIcon } from "components";
import { useNavigate } from "react-router-dom";
import { useSetNotification, useUnreadNotification } from "api";
import { useSelector } from "react-redux";
import { classNames } from "utils";

export default function Notification() {
  const { unread } = useSelector((state) => state.notification);

  const navigate = useNavigate();
  const [setNotify] = useSetNotification();

  useUnreadNotification();

  return (
    <div className="ml-2 flex flex-row md:ml-0">
      <>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Notifications">
            <button
              className="relative mr-0 flex h-7 w-7 items-center justify-center rounded-full bg-white p-1 text-gray-400 ring-gray-200 duration-100 ease-in hover:text-color-main hover:ring-4 focus:outline-none md:mr-3"
              onClick={() => {
                setNotify();
                navigate("/notifications");
              }}
            >
              <SimpleIcon icon="Notifications" fontSize={20} />

              <div
                className={classNames(
                  "absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full ",
                  unread
                    ? "animate-bounce bg-red-500 duration-500 "
                    : "bg-gray-400"
                )}
              >
                <div className="text-xs font-semibold text-white">{unread || 0}</div>
              </div>
            </button>
          </Tooltip>
        </Box>
      </>
    </div>
  );
}
