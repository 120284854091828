import React, { useState } from "react";
import { formatMoney } from "utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function ToggleAmount({ walletAmt, sym = "NGN" }) {
  const [hideAmount, setHideAmount] = useState(false);

  return (
    <div className="flex items-center text-color-main">
      <h2 className="mr-1 text-lg font-bold">
        {hideAmount ? "********" : <>{formatMoney(walletAmt, sym)}</>}
      </h2>
      <button onClick={() => setHideAmount(!hideAmount)}>
        {hideAmount ? (
          <Visibility sx={{ fontSize: 16 }} />
        ) : (
          <VisibilityOff sx={{ fontSize: 16 }} />
        )}
      </button>
    </div>
  );
}
