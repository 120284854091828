import React from "react";
import { Button } from "components";
import { Fade } from "react-reveal";
import { CheckTradeStatus } from "components";

export default function ProductCard() {
  return (
    <Fade>
      <div className="card col-span-1 flex min-h-[220px] flex-col justify-between rounded-sm bg-gradient-to-tr from-bg_gradient_from to-bg_gradient_to p-6">
        <div className="flex h-full flex-col justify-between">
          <div className="flex items-end justify-between gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold text-white">
                Sell your Giftcard at best rates
              </p>
              <p className="my-1 text-sm font-light text-gray-50">
                Effective and convinient means to exchange your giftcards at the
                best rates. Easily trade them with us for the best value we
                offer.
              </p>
            </div>
            <img
              src={require("../../assets/images/giftcard-product.png")}
              alt="gift-card"
              className="h-24 w-auto object-cover"
            />
          </div>
          <div className="mt-4 flex items-end justify-between">
            <div className="flex flex-row items-center gap-2 lg:gap-4">
              <Button label="Trade Now" color="default" link="/giftcard" />
              <CheckTradeStatus />
            </div>
            {/* <img
              src={require("../../assets/images/giftcard-product.png")}
              alt="gift-card"
              className="h-24 w-auto object-cover"
            /> */}
          </div>
        </div>
      </div>
    </Fade>
  );
}
