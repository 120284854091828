import React, { useEffect, useState } from "react";
import { Button } from "components";
import { useLocation, useNavigate } from "react-router";
import OTPInput from "otp-input-react";
import { useVerifyTwoFactor } from "api/actions/auth";
import { Lock } from "@mui/icons-material";
import { AuthContainer } from "shared";

export default function TwoFactorCheck() {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isLoading, isLoaded, verifyTwoFactor] = useVerifyTwoFactor();

  const { email, password, twoFacDetails, factorType, codeRef } = state || {};

  const { phoneNumber, email: email2FA, uid } = twoFacDetails || {};

  useEffect(() => {
    if (!(state && email && password && factorType)) {
      if (
        (factorType === "appAuthentication" && !uid) ||
        (factorType === "phoneAuthentication" && !phoneNumber) ||
        (factorType === "emailAuthentication" && !email) ||
        email2FA !== email
      ) {
        navigate("/login");
      }
    }
  }, [state]);

  const handleVerify2Fac = () => {
    verifyTwoFactor({
      email,
      password,
      code,
      uid,
      factorType,
      phoneNumber,
      codeRef,
    });
  };

  return (
    <AuthContainer
      title="Two Factor Authentication"
      subTitle="Keep your account secured. Provide the two-factor auth code to continue."
    >
      <div className="flex flex-col items-center justify-center text-center">
        <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
          <Lock sx={{ fontSize: 36 }} className="iconShadow text-base" />
        </div>

        <div className="my-4 flex flex-col items-center justify-center">
          {factorType === "phoneAuthentication" && (
            <p className="my-4 text-sm">Input the OTP sent to +{phoneNumber}</p>
          )}
          {factorType === "emailAuthentication" && (
            <p className="my-4 text-sm">Input the OTP sent to {email}</p>
          )}

          {factorType === "appAuthentication" && (
            <>
              <p className="my-4 text-sm">
                Scan the QR Code in the Authenticator app. Then enter the code
                that you see in the app in the input below and click Verify.
              </p>
              <img src={twoFacDetails.qrCode} className="mb-4 h-40 w-40" />
            </>
          )}

          <OTPInput
            value={code}
            onChange={(e) => setCode(e)}
            OTPLength={6}
            otpType="number"
            disabled={false}
            // secure={secure}
            inputClassName="border rounded border-gray-500 text-gray-500 !m-1 focus:border-primary outline-none focus:border-1 focus:rounded-lg"
          />
        </div>

        <div className="w-1/2">
          <Button
            label="Verify"
            fullWidth
            gradient
            handleClick={handleVerify2Fac}
            isSubmitting={isLoading}
            disabled={!(Boolean(code) && code.length === 6)}
          />
        </div>
      </div>
    </AuthContainer>
  );
}
