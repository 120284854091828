import React, { useState, useEffect, Fragment } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { Fade, Slide } from "react-reveal";
import { SimpleIcon } from "components";
import { useDropDownVisible } from "./CustomInput";
import { classNames, formatDate, formatDateToValue } from "utils";
import { CircularProgress } from "@mui/material";
import { useBreakpoint } from "utils";

export default function CalendarPicker({
  queryDates,
  setQueryDates,
  isTransactionListLoading,
}) {
  const [values, setValues] = useState(null);
  const [valuesD, setValuesD] = useState({ startDate: null, endDate: null });

  const { startDate, endDate } = valuesD;

  const breakpoint = useBreakpoint();

  const { ref, isDropdownVisible, setIsDropdownVisible } = useDropDownVisible(
    false,
    "calendar"
  );

  const startD = new DateObject(values?.[0]).format();
  const endD = new DateObject(values?.[1]).format();

  useEffect(() => {
    if (startD !== endD) {
      setValuesD({ startDate: startD, endDate: endD });
    }
  }, [values]);

  useEffect(() => {
    setValues(null);
  }, [isDropdownVisible]);

  const handleClick = () => {
    if (!queryDates.endDate) {
      if (startDate !== endDate) {
        setQueryDates({
          startDate: formatDateToValue(startDate),
          endDate: formatDateToValue(endDate),
        });
      }
    } else {
      setQueryDates({ startDate: null, endDate: null });
      setValuesD({ startDate: null, endDate: null });
    }
  };

  return (
    <div className="relative z-10 mr-0 mt-0 h-full">
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="flex w-full items-center justify-between">
          <button
            className={classNames(
              isTransactionListLoading ? "pointer-events-none" : "",
              "dropdown-button-calendar mr-2 flex-1 rounded-lg"
            )}
          >
            <div className="pointer-events-none font-semibold  text-primary">
              <div className="flex items-center">
                {/* <div className="mr-1 flex h-6 w-6 items-center justify-center rounded border border-gray-200">
                  <SimpleIcon icon="SelectAll" fontSize={18} otherClass="" />
                </div> */}

                {startDate && endDate ? (
                  [startDate, endDate].map((item, index) => (
                    <div className="flex items-center" key={index}>
                      {index === 1 && <span className="mx-1"> - </span>}
                      <div className="text-sm">{item}</div>
                    </div>
                  ))
                ) : (
                  <div className="text-sm">Select date</div>
                )}
              </div>
            </div>
          </button>
          <div className="flex gap-2">
            {[
              {
                icon: "Clear",
                bgColor: "bg-red-500",
                display: queryDates.endDate && !isTransactionListLoading,
                onClick: handleClick,
              },
              {
                icon: "SearchOutlined",
                bgColor: "bg-primary",
                display: true,
                onClick: handleClick,
              },
            ].map((item, index) => (
              <Fragment key={index}>
                {item.display ? (
                  <button className="" onClick={item.onClick}>
                    <div
                      className={classNames(
                        item.bgColor,
                        "flex h-6 w-6 items-center justify-center rounded-full shadow-md"
                      )}
                    >
                      {isTransactionListLoading ? (
                        <CircularProgress color="neutral" size={16} />
                      ) : (
                        <SimpleIcon
                          icon={item.icon}
                          frontColor="text-white"
                          fontSize={16}
                        />
                      )}
                    </div>
                  </button>
                ) : null}
              </Fragment>
            ))}
          </div>
        </div>
        {isDropdownVisible && (
          <div
            ref={ref}
            // absolute
            className={`dropdown-calendar absolute right-0 top-14 z-[10] w-max`}
          >
            <div className="h-full w-full">
              <Fade duration={300}>
                <Calendar
                  value={values}
                  onChange={setValues}
                  range
                  numberOfMonths={breakpoint.md ? 2 : 1}
                  minDate={"2021/05/12"}
                  maxDate={new Date()}
                />
              </Fade>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
