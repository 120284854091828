import React from "react";
// import { useNavigate } from "react-router-dom";
import { PageContainer } from "shared";
import { Button } from "components";
import NavbarTop from "shared/NavbarTop";

export default function Error() {
  // const navigate = useNavigate();

  return (
    <div className="page-container relative">
      <div className="navbar relative z-50 mt-20">
        <div className="relative m-auto flex h-full w-full flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-6 text-center shadow-card-box md:w-2/3 lg:w-1/3">
          <div className="text-9xl">
            <span className="font-bold text-primary">4</span>
            <span className="font-bold text-slate-200">0</span>
            <span className="font-bold text-primary">4</span>
          </div>
          <div className="my-8">
            <h4 className="text-lg font-bold">Oops! Page cannot be found</h4>
          </div>

          <div className="m-auto mt-2 text-center">
            <Button
              label="Go back"
              fullWidth
              gradient
              // handleClick={() => {
              //   navigate(-1);
              // }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
