import React, { useState } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import DeleteIcon from "@mui/icons-material/Delete";

import { Dialog, SimpleIcon } from ".";
import { classNames } from "utils";
// import ReactImageUploading from 'react-images-uploading';
import ImageUploading from "react-images-uploading";

export function ImageGallery({ images, type, handleChange, className }) {
  const [imageList, setImageList] = useState(images);
  const [currentImage, setImage] = useState(null);
  const [openDialog, setDialog] = useState(false);

  const Icon = ({ type, ...otherProps }) =>
    type === "view" ? (
      <GridViewIcon {...otherProps} />
    ) : (
      <DeleteIcon {...otherProps} />
    );

  const handleClick = (image, type) => {
    if (type === "view") {
      setImage(image);
      setDialog(true);
    } else if (type === "remove") {
      const filterImg = imageList.filter((i) => i !== image);
      setImageList(filterImg);
      handleChange(filterImg);
    }
  };

  return (
    <div className={classNames(className ? className : "")}>
      {imageList.map((image, index) => (
        <div key={index} className="relative h-20 w-20 rounded">
          <img
            src={image}
            alt={image}
            className="h-full w-full rounded-lg object-cover"
          />
          <div className="absolute -top-2 -right-2">
            {type.map((t, ind) => (
              <button
                key={ind}
                className={classNames(
                  t === "view" ? "bg-blue-400" : "bg-red-400",
                  "flex h-6 w-6 items-center justify-center rounded-full text-white shadow"
                )}
                onClick={() => handleClick(image, t)}
              >
                <Icon type={t} sx={{ fontSize: 16 }} />
              </button>
            ))}
          </div>
        </div>
      ))}

      <Dialog open={openDialog} setOpen={setDialog} closeBtn>
        <div className="relative">
          <img
            src={currentImage}
            alt="giftcard-popup"
            className="h-full w-full object-cover"
          />
        </div>
      </Dialog>
    </div>
  );
}

export function ImageUpload({ images, handleChange, className }) {
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={handleChange}
      maxNumber={10}
      dataURLKey="data_url"
      acceptType={["jpg", "jpeg", "png"]}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className={classNames(className ? className : "")}>
          {images.map((image, index) => (
            <div key={index} className="relative m-2 h-20 w-20 rounded">
              <img
                src={image["data_url"]}
                alt={image["file"]["name"]}
                className="h-full w-full rounded-lg object-cover"
              />
              <div className="absolute -top-2 -right-2">
                <button
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-white shadow-lg"
                  onClick={() => onImageRemove(index)}
                >
                  <SimpleIcon
                    icon="Close"
                    frontColor="text-white"
                    fontSize={16}
                  />
                </button>
              </div>
            </div>
          ))}
          <button
            className={classNames(
              isDragging ? "border-red-200" : "",
              "border-primary-200 m-2 h-20 w-20 rounded-lg border border-dashed p-3 text-center text-text-color"
            )}
            onClick={onImageUpload}
            {...dragProps}
          >
            <div className="flex flex-col items-center">
              <SimpleIcon icon="CloudUpload" frontColor="#333" />
            </div>
          </button>
        </div>
      )}
    </ImageUploading>
  );
}
