import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducers from "./reducers/authReducers";
import profileReducers from "./reducers/profileReducers";
import walletReducers from "./reducers/walletReducers";
import productReducers from "./reducers/giftcardReducers";
import transactionReducers from "./reducers/transactionReducers";
import utilReducers from "./reducers/utilReducers";
import notificationReducers from "./reducers/notificationReducers";

const store = configureStore({
  reducer: {
    auth: authReducers,
    profile: profileReducers,
    account: walletReducers,
    product: productReducers,
    transaction: transactionReducers,
    utils: utilReducers,
    notification: notificationReducers,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
