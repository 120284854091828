import { Route } from "react-router-dom";
import {
  Login,
  Register,
  RegisterOTP,
  TwoFactorCheck,
  ForgetPass,
  ResetPass,
} from "../pages";
import { UserIsNotAuthenticated } from "./AuthProvider";

const PublicRoutes = (
  <Route>
    {[
      { path: "/login", element: Login },
      {
        path: "/register",
        element: Register,
      },
      {
        path: "/verify-email",
        element: RegisterOTP,
      },
      {
        path: "/two-factor-check",
        element: TwoFactorCheck,
      },
      {
        path: "/forget-password",
        element: ForgetPass,
      },
      {
        path: "/reset-password",
        element: ResetPass,
      },
    ].map((r, ind) => (
      <Route
        key={ind}
        exact
        path={r.path}
        element={
          <UserIsNotAuthenticated>
            <r.element />
          </UserIsNotAuthenticated>
        }
      />
    ))}
  </Route>
);

export default PublicRoutes;
