import React, { useState, useEffect } from "react";
import { uid } from "uid";
import { VerifyBank, Button, Dialog, Drawer } from "components";
import { useUpdateWithdrawalAcc } from "api";

export function AddAccount({ openDrawer, setOpenDrawer }) {
  const [bankDetails, setBankDetails] = useState(null);
  const { bankName, bankAccountNo, accountName, bankCode } = bankDetails || {};

  const [isUpdating, isUpdated, updateAcct] = useUpdateWithdrawalAcc();

  const addAccount = () => {
    const values = {
      accounts: { bankName, bankAccountNo, accountName, bankCode, id: uid(16) },
      type: "add",
      accountsName: "ownDetails",
    };
    updateAcct(values);
  };

  useEffect(() => {
    if (isUpdated) {
      setOpenDrawer(false);
      setBankDetails(null);
    }
  }, [isUpdated]);

  return (
    <Drawer
      anchor="right"
      state={openDrawer}
      setState={setOpenDrawer}
      title="Add Bank Account"
    >
      <div className="drawer-content">
        <div className="mt-6 p-4">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <VerifyBank
              {...{
                bankDetails,
                setBankDetails,
                accountType: "other-account",
                label: "Other account",
                accounts: null,
              }}
            />
            <div className="col-span-2">
              <Button
                fullWidth
                label="Add Account"
                gradient
                handleClick={addAccount}
                disabled={
                  !(bankName && bankAccountNo && accountName) || isUpdating
                }
                isSubmitting={isUpdating}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export function RemoveAccount({
  openPopup,
  setOpenPopup,
  accountDelete,
  accountsName,
}) {
  const [isUpdating, isUpdated, updateAcct] = useUpdateWithdrawalAcc();

  const deleteAccount = () => {
    const values = {
      accounts: accountDelete,
      type: "remove",
      accountsName,
    };

    updateAcct(values);
  };

  useEffect(() => {
    if (isUpdated) {
      setOpenPopup(false);
    }
  }, [isUpdated]);

  return (
    <Dialog
      open={openPopup}
      setOpen={setOpenPopup}
      classes={{
        paper: "!max-w-[300px]",
      }}
    >
      <div className="py-3 text-center">
        <p className="mt-4 text-base text-gray-400">
          Are you sure you want to delete this account?
        </p>
        <div className="action-buttons flex justify-center space-x-2 p-4">
          <Button
            label="Delete"
            color="error"
            handleClick={deleteAccount}
            isSubmitting={isUpdating}
          />
          <Button
            label="Cancel"
            handleClick={() => setOpenPopup(false)}
            variant="outlined"
            color="neutral"
          />
        </div>
      </div>
    </Dialog>
  );
}
