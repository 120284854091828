import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useNavigate } from "react-router";
import { SimpleIcon } from "components";

export default function AvatarMenu({ avatarUrl, logout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            classes={{
              root: "!rounded !p-[4px]",
            }}
          >
            <div className="relative mr-0 flex h-7 w-7 items-center justify-center rounded-sm bg-white p-1 text-gray-400 ring-gray-200 duration-100 ease-in hover:text-color-main hover:ring-4 focus:outline-none">
              <Avatar
                src={avatarUrl}
                className="iconShadow-alt !h-5 !w-5"
                alt="profile-image"
                variant="rounded"
              />
            </div>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          className:
            "!bg-white border border-slate-200 !shadow-lg !rounded w-40",

          elevation: 0,

          sx: {
            overflow: "visible",
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {[
          {
            Icon: AccountBalanceWalletOutlinedIcon,
            name: "Wallet",
            onClick: () => navigate("/wallets"),
          },
          {
            Icon: Settings,
            name: "Settings",
            onClick: () => navigate("/settings"),
          },
          { Icon: Logout, name: "Logout", onClick: () => logout() },
        ].map((item, index) => (
          <MenuItem
            key={index}
            classes={{
              root: "!text-sm !text-gray-500 !p-0 flex flex-col",
            }}
            onClick={item.onClick}
          >
            <div className="w-full py-2 px-4">
              <item.Icon fontSize="small" />{" "}
              <span className="ml-3">{item.name}</span>
            </div>
            {index === 1 && <Divider className="w-full" />}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
