import React from "react";
import { useLocation } from "react-router-dom";
import { capitalize } from "lodash";
import { Fade } from "react-reveal";
import { useSelector } from "react-redux";

import { PageContainer } from "shared";
import { SectionContent, WalletBalance } from "components";
import WithdrawNaira from "./sub-pages/WithdrawNaira";
import WithdrawFiat from "./sub-pages/WithdrawFiat";

export default function Withdraw() {
  const { fiatAuth } = useSelector((state) => state.utils);

  const { state } = useLocation();
  const { type, amount, sym } = state || {};

  const page =
    type === "cedis" ? "withdrawCedis" : type === "cefa" ? "withdrawCefa" : "";

  return (
    <PageContainer header={`Withdraw ${capitalize(type)}`}>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-4 xl:gap-6">
        <Fade>
          <div className="col-span-1 md:col-span-3 lg:col-span-2">
            <SectionContent>
              <div className="p-2">
                <div className="mb-4">
                  <WalletBalance amount={`${amount}`} sym={sym} />
                </div>

                <div className="">
                  {type === "naira" ? (
                    <WithdrawNaira
                      displayPage={(typeCurr) => fiatAuth?.[type]?.[typeCurr]}
                      navigateBack
                    />
                  ) : (
                    <WithdrawFiat
                      displayPage={(typeCurr) => fiatAuth?.[type]?.[typeCurr]}
                      currentWalletName={type}
                      navigateBack
                    />
                  )}
                </div>
              </div>
            </SectionContent>
          </div>
        </Fade>
      </div>
    </PageContainer>
  );
}
