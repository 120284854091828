import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { ScrollTop } from '../shared';
import { Error } from '../pages';

const AppRoutes = () => (
  <BrowserRouter>
    <ScrollTop>
      <>
        <Routes>
          {PrivateRoutes}
          {PublicRoutes}
          <Route path="*" element={<Error />} />
        </Routes>
      </>
    </ScrollTop>
  </BrowserRouter>
);

export default AppRoutes;
