import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { pick } from "lodash";
import { uid } from "uid";

import { PageContainer } from "shared";
import SelectCurrency from "./SelectCurrency";
import {
  useAddToGiftcardCart,
  useAddSteps,
  useRemoveFromGiftcardCart,
  useClearGiftcardCart,
  useSubmitGiftcardTrade,
} from "api";
import SelectType from "./SelectType";
import SelectQuantity from "./SelectQuantity";
import UploadImage from "./UploadImage";
import Summary from "./Summary";
import { giftcardPriceCalc } from "../helpers";
import { SuccessTransaction } from "components";

export default function Index() {
  // useState Hooks
  const [openIsSubmitted, setIsSubmitted] = useState(false);
  const [showFullSummary, setFullSummary] = useState(false);

  const [addToSale] = useAddToGiftcardCart();
  const [addSteps] = useAddSteps();
  const [removeFromSale] = useRemoveFromGiftcardCart();
  const [clearCart] = useClearGiftcardCart();

  const [isUpdating, isUpdated, submitTrade] = useSubmitGiftcardTrade();

  const navigate = useNavigate();

  const {
    add_to_gf_sale: cart,
    giftcard_rate,
    giftcard_rate_fac,
    giftcard_currency,
    steps,
  } = useSelector((state) => state.product);

  const card = giftcard_rate?.find((g) => g.id === cart?.id);
  const cardCurrency = Object.keys(card?.rate || {});
  const cardType = Object.keys(card?.rate?.[cart?.currency] || {});
  const cardRates = card?.rate?.[cart?.currency]?.[cart?.type] || {};

  useEffect(() => {
    if (
      !card ||
      (!cart?.id && !cart?.card) ||
      cart?.defaultCurrency !== giftcard_rate_fac?.defaultCurrency
    ) {
      navigate(-1);
    }
  }, [giftcard_rate_fac?.defaultCurrency, card]);

  useEffect(() => {
    const scroll = window.scrollTo(0, 0);
    return scroll;
  }, [steps]);

  useEffect(() => {
    let timerId = () => {};
    if (isUpdated) {
      setIsSubmitted(true);

      timerId = setTimeout(() => {
        setIsSubmitted(false);
        navigate("/giftcard");
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isUpdated]);

  function pushToAnalytics() {
    const transaction = cart.total;
    window.dataLayer.push({ transaction: null });
    window.dataLayer.push({
      event: "proccessed-trade",
      transaction,
      eventCallback: function () {
        return;
      },
    });
  }

  const tradeSummary = pick(cart, [
    "card name",
    "currency",
    "type",
    "quantity",
    "total",
  ]);

  const getWRate = (qty, rate) =>
    giftcardPriceCalc(qty, rate, giftcard_rate_fac);

  const addCurrency = (currency) => {
    const value = { currency };
    addToSale(value);
    addSteps(2);
  };

  const addType = (type) => {
    const value = { type };
    addToSale(value);
    addSteps(3);
  };

  const addQty = (qtyT) => {
    addToSale(qtyT);
    addSteps(4);
  };

  const addImage = (image) => {
    addToSale(image);
    addSteps(5);
    setFullSummary(true);
  };

  const imageUID = uid(32);

  const processTrade = () => {
    submitTrade({
      ...cart,
      imageUID,
    });
  };

  const headerText = {
    1: "Select Giftcard Currency",
    2: "Select Giftcard Type",
    3: "Select Giftcard Quantity",
    4: "Upload Image files",
    5: "Process Trade",
  };

  return (
    <PageContainer header={`${headerText[steps]} (${steps} / 5)`}>
      <div className="relative">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-4 xl:gap-6">
          {!showFullSummary && (
            <div className="col-span-1 md:col-span-3">
              {steps === 1 && (
                <SelectCurrency
                  cardCurrency={cardCurrency}
                  addCurrency={addCurrency}
                  currentCurrency={cart?.currency}
                  currencyList={giftcard_currency}
                />
              )}

              {steps === 2 && (
                <SelectType
                  cardType={cardType}
                  handleStep={() => {
                    addSteps(1);
                    removeFromSale(["type"]);
                  }}
                  addType={addType}
                  currentType={cart?.type}
                />
              )}
              {steps === 3 && (
                <SelectQuantity
                  cardRates={cardRates}
                  getRateFac={giftcard_rate_fac}
                  card={card?.name}
                  cardType={cart?.currency}
                  addQty={addQty}
                  handleStep={() => {
                    addSteps(2);
                    removeFromSale(["quantity", "total"]);
                  }}
                  currentQty={cart?.quantity}
                />
              )}
              {steps === 4 && (
                <UploadImage
                  addImage={addImage}
                  currentImageArr={cart?.images}
                  currentComment={cart?.comment}
                  handleStep={() => {
                    removeFromSale(["images", "comment"]);
                    addSteps(3);
                  }}
                />
              )}
            </div>
          )}

          <>
            <Summary
              tradeSummary={tradeSummary}
              pushToAnalytics={pushToAnalytics}
              cardType={cart?.currency}
              total={cart?.total}
              showFullSummary={showFullSummary}
              defaultFiat={giftcard_rate_fac?.defaultFiat}
              processTrade={processTrade}
              isProcessing={isUpdating}
              handleStep={() => {
                // removeFromSale(['images', 'comment']);
                addSteps(4);
                setFullSummary(false);
              }}
            />
          </>
        </div>
      </div>
      <SuccessTransaction open={openIsSubmitted} setOpen={setIsSubmitted} />
    </PageContainer>
  );
}
