import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';

import { SimpleTable } from 'components';
import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { useUpdateNotificationStatus } from 'api';

export default function Notifications() {
  const {
    user_details: { notificationStatus },
  } = useSelector((state) => state.profile);

  const [isUpdating, isUpdated, updateStatus] = useUpdateNotificationStatus();

  const handleChange = (values) => {
    updateStatus(values);
  };

  const stat = {
    fundDeposit: false,
    giftcardSell: false,
    walletCredit: false,
  };

  const notificationStat = notificationStatus || {
    emailNotification: stat,
    pushNotification: stat,
  };

  return (
    <div>
      <SimpleTable
        caption="You will receive notification for the below selected items."
        columns={[
          { id: 'type', label: 'Type', minWidth: 250, align: 'left' },
          ...['emailNotification', 'pushNotification']?.map((item) => ({
            id: item,
            label: startCase(item),
            align: 'center',
          })),
        ]}
        rows={['fundDeposit', 'giftcardSell', 'walletCredit'].map((item) => ({
          type: startCase(item),
          ...['emailNotification', 'pushNotification']?.reduce(
            (acc, itemObj) => {
              acc[itemObj] = (
                <Checkbox
                  checked={notificationStat?.[itemObj]?.[item] || false}
                  size="small"
                  classes={{ root: '!p-0' }}
                  onChange={(e) => {
                    handleChange({
                      key: [`${itemObj}.${item}`],
                      value: !notificationStat?.[itemObj]?.[item],
                    });
                  }}
                />
              );
              return acc;
            },
            {}
          ),
        }))}
      />
    </div>
  );
}
