import React from "react";
import { useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import Fade from "react-reveal/Fade";
import { Container, BackPage, CurrentData } from "components";

// const flagCode = {
//   USD: { code: "us", id: 1 },
//   EUR: { code: "eu", id: 2 },
//   GBP: { code: "gb", id: 3 },
//   CAD: { code: "ca", id: 4 },
//   AUD: { code: "au", id: 5 },
//   NZD: { code: "nz", id: 6 },
//   SGP: { code: "sg", id: 7 },
//   CHE: { code: "ch", id: 8 },
// };

export default function SelectCurrency({
  cardCurrency,
  addCurrency,
  currentCurrency,
  currencyList,
}) {
  const navigate = useNavigate();
  const flagCode = currencyList;

  return (
    <div className="">
      <BackPage handleClick={() => navigate(-1)} />

      <Container>
        {cardCurrency.length ? (
          <div className="flex flex-wrap gap-3 rounded-sm bg-white p-3">
            {sortBy(cardCurrency, [(o) => flagCode[o]?.id])?.map((cur) => (
              <Fade key={cur}>
                <button
                  className="relative w-20 scale-100 rounded-sm bg-card p-1 text-center duration-300 ease-in hover:scale-105 hover:shadow-2xl"
                  onClick={() => addCurrency(cur)}
                >
                  <i
                    className={`flag-icon flag-icon-${flagCode?.[cur]?.code} w-full rounded-lg`}
                  />
                  <div className="mt-2 text-center text-sm font-normal text-text-color">
                    {cur}
                  </div>

                  <CurrentData currData={currentCurrency} listData={cur} />
                </button>
              </Fade>
            ))}
          </div>
        ) : (
          <div className="bg-white p-3">
            <div className="font-semibold">Not avaliable for trading</div>
          </div>
        )}
      </Container>
    </div>
  );
}
