import React, { Fragment, useEffect, useState } from "react";
import { startCase } from "lodash";
import {
  Select,
  TextField,
  Button,
  Accordion,
  Backdrop,
  FullWidthTabs,
  EnterPIN,
  NoDisplay,
} from "components";
import { useVerifyUtility, usePurchaseUtiliity, useGetUtilityList } from "api";
import { formatDate, formatInputNum, formatMoneyNoDec } from "utils";
import { Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";

const utilityList = [
  {
    id: 1,
    amount: 4000,
    product_code: "DE",
  },
  {
    id: 2,
    amount: 4000,
    product_code: "UTME",
  },
];

const tabList = [
  {
    label: "WAEC",
    Icon: "School",
    id: "waec",
  },
  {
    label: "JAMB",
    Icon: "School",
    id: "jamb",
  },
];

export default function BuyPin({ nairaWallet, userEmail, displayPage }) {
  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [currentTab, setCurrentTab] = useState(tabList[0]);

  const [values, setValues] = useState(null);
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const navigate = useNavigate();

  const [isSubmitting, isSubmitted, purchaseUtility] = usePurchaseUtiliity();

  const [
    getVerifyDetails,
    setVerify,
    verifyEpin,
    isLoadingVerify,
    isErrorVerify,
    setErrorVerify,
  ] = useVerifyUtility();

  const [getUtilityList, setUtilityList, epinList, isLoadingList] =
    useGetUtilityList();

  const handleBeneficiary = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");

    setValues((prevS) => ({
      ...prevS,
      beneficiary: val,
    }));
  };

  const handleAmtChange = (e, quantity = 1) => {
    const total = parseInt(e) * parseInt(quantity);

    const limit = parseInt(e) < 100 || parseInt(e) > 200000;
    const overlimit = total >= parseInt(nairaWallet?.amount || 0);
    const overcharges = parseInt(nairaWallet?.amount || 0) - total < 50;

    setValues((prevS) => ({ ...prevS, amount: e }));
    setTotalAmt(total);
    setErrorAmt(overlimit || overcharges || limit);
    setErrorText(
      overlimit
        ? "You have exceeded balance amount"
        : limit
        ? "Purchase must be within ₦100 and ₦200000"
        : overcharges
        ? "Maintain a minimum of ₦50 in wallet"
        : ""
    );
  };

  const handlePurchase = () => {
    const {
      amount,
      provider,
      providerName,
      beneficiary,
      package: plan,
      quantity,
      totalAmt,
    } = values;

    const valuesAlt = {
      amountToDebit: parseInt(totalAmt),
      provider,
      providerName,
      beneficiary: beneficiary || userEmail,
      productType: currentTab.id === "waec" ? "epin" : "jamb",
      productPlan: plan,
      amount: parseInt(totalAmt),
      transactionDetails: {
        ...(currentTab.id === "waec"
          ? {
              service_type: "waec",
              numberOfPins: quantity,
              pinValue: parseInt(amount),
              amount: parseInt(totalAmt),
            }
          : {
              service_type: "jamb",
              account_number: beneficiary,
              product_code: provider,
              amount: parseInt(amount),
            }),
      },
    };

    purchaseUtility(valuesAlt);
  };

  useEffect(() => {
    // if (currentTab.id === "waec") {
    //   epinList({ service_type: "waec", category_type: "epin" });
    // }
    // else if (currentTab.id === "jamb") {
    //   epinList({ service_type: "jamb", category_type: "jamb" });
    // }
  }, []);

  useEffect(() => {
    setVerify(null);
    setValues(null);
    setErrorVerify(false);
  }, [currentTab?.label]);

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setValues(null);
      setUtilityList(null);
      setVerify(null);
      setTotalAmt(0);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        navigate(-1);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  const WaecSub = () => (
    <>
      {displayPage?.["waec"] ? (
        <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-2">
            <Select
              titleValue="description"
              subTitleValue="price"
              dropDown={[]}
              // dropDown={
              //   isLoadingList
              //     ? []
              //     : getUtilityList?.map((item) => ({
              //         ...item,
              //         price: `₦${formatInputNum(item.amount)}`,
              //       }))
              // }
              inputLabel="Checker Type"
              disabled={isSubmitting}
              // handleChange={(e) => {
              //   setValues((prevS) => ({
              //     provider: "epin",
              //     providerName: "waec",
              //     package: e.description,
              //     amount: e.amount,
              //     totalAmt: e.amount * 1,
              //     quantity: 1,
              //   }));
              //   handleAmtChange(e.amount);
              //   setErrorAmt(false);
              //   setVerify(null);
              // }}
              valueAlt={values?.package || ""}
            />
          </div>

          {getUtilityList && values?.providerName && (
            <>
              <div className="col-span-2">
                <TextField
                  name="amount"
                  label="Amount"
                  value={values?.amount || ""}
                  startAdornment="₦"
                  formatInput
                  disableAutoComplete
                  placeholder="0.00"
                  readOnly
                />
                {/* {errorAmt && (
              <Slide down duration={300}>
                <div className="mt-2 text-xs font-normal text-red-500">
                  {errorText}
                </div>
              </Slide>
            )} */}
              </div>

              <div className="col-span-2">
                <TextField
                  name="quantity"
                  type="number"
                  label="Quantity"
                  value={values?.quantity}
                  // handleChange={(e) => {
                  //   const val = e.target.value;

                  //   setValues((prevS) => ({
                  //     ...prevS,
                  //     quantity: parseInt(val),
                  //     totalAmt: val ? values?.amount * parseInt(val) : "",
                  //   }));
                  //   handleAmtChange(values?.amount, val);
                  // }}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="total-amount"
                  type="text"
                  label="Total Amount"
                  value={values?.totalAmt}
                  startAdornment="₦"
                  formatInput
                  readOnly
                />
                {errorAmt && (
                  <Slide down duration={300}>
                    <div className="mt-2 text-xs font-normal text-red-500">
                      {errorText}
                    </div>
                  </Slide>
                )}
              </div>

              <div className="col-span-2">
                <Button
                  label="Continue"
                  fullWidth
                  gradient
                  disabled={
                    !(
                      values &&
                      Object.values(values).every((item) => item) &&
                      parseInt(values?.totalAmt) &&
                      values?.quantity &&
                      !errorAmt
                    )
                  }
                  handleClick={() => setOpen(true)}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <NoDisplay type="purchase" />
      )}
    </>
  );

  const JambSub = () => (
    <>
      {displayPage?.["jamb"] ? (
        <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-2">
            <Select
              titleValue="product_code"
              subTitleValue="price"
              dropDown={[]}
              // dropDown={utilityList?.map((item) => ({
              //   ...item,
              //   price: `₦${formatInputNum(item.amount)}`,
              // }))}
              // dropDown={
              //   isLoadingList
              //     ? []
              //     : getUtilityList.map((item) => ({
              //         ...item,
              //         price: `₦${formatInputNum(item.amount)}`,
              //       }))
              // }
              inputLabel="Checker Type"
              disabled={isSubmitting}
              // handleChange={(e) => {
              //   setValues((prevS) => ({
              //     provider: "epin",
              //     providerName: "jamb",
              //     package: e.product_code,
              //     totalAmt: e.amount,
              //   }));
              //   setErrorAmt(false);
              //   handleAmtChange(e.amount);
              //   setVerify(null);
              // }}
              valueAlt={values?.package || ""}
            />
          </div>

          <div className="col-span-2">
            <TextField
              name="beneficiary-number"
              label="JAMB Confirmation Code"
              // type="number"
              placeholder="Input JAMB Confirmation Code"
              value={values?.beneficiary || ""}
              handleChange={handleBeneficiary}
              disableAutoComplete
              endAdornment={
                <Button
                  label="Verify"
                  gradient
                  // handleClick={() => {
                  //   const service_type = values?.provider;
                  //   const account_number = values?.beneficiary;

                  //   verifyEpin({
                  //     service_type: "jamb",
                  //     account_number,
                  //     product_code: service_type,
                  //   });
                  // }}
                  isSubmitting={isLoadingVerify}
                  disabled={
                    !(values?.provider && values?.beneficiary?.length >= 10)
                  }
                />
              }
            />
            {isErrorVerify && !getVerifyDetails && (
              <Slide down duration={300}>
                <div className="mt-2 text-xs font-normal text-red-500">
                  {isErrorVerify}
                </div>
              </Slide>
            )}
          </div>

          {values?.amount && (
            <div className="col-span-2">
              <TextField
                name="amount"
                label="Amount"
                value={values?.amount || ""}
                startAdornment="₦"
                formatInput
                disableAutoComplete
                placeholder="0.00"
                readOnly
              />
              {errorAmt && (
                <Slide down duration={300}>
                  <div className="mt-2 text-xs font-normal text-red-500">
                    {errorText}
                  </div>
                </Slide>
              )}
            </div>
          )}

          {/* {getVerifyDetails && (
            <>
              <div className="col-span-2">
                <TextField
                  name="beneficiary-name"
                  label="Beneficiary Name"
                  value={getVerifyDetails?.name || ""}
                  readOnly
                />
              </div>

              <div className="col-span-2">
                <Accordion
                  title="Beneficiary Details"
                  content={
                    <ul>
                      {getVerifyDetails?.outstandingBalance && (
                        <li className="flex items-center justify-between py-2 text-xs text-gray-400">
                          <div>{startCase("outstandingBalance")}</div>
                          <div>
                            {formatMoneyNoDec(
                              getVerifyDetails?.outstandingBalance
                            )}
                          </div>
                        </li>
                      )}
                      {getVerifyDetails?.minimumAmount && (
                        <li className="flex items-center justify-between py-2 text-xs text-gray-400">
                          <div>{startCase("minimumAmount")}</div>
                          <div>
                            {formatMoneyNoDec(getVerifyDetails?.minimumAmount)}
                          </div>
                        </li>
                      )}
                      {getVerifyDetails?.accountNumber && (
                        <li className="flex items-center justify-between py-2 text-xs text-gray-400">
                          <div>{startCase("JAMB Confirmation Code")}</div>
                          <div>{getVerifyDetails?.accountNumber}</div>
                        </li>
                      )}
                      {getVerifyDetails?.address && (
                        <li className="flex items-center justify-between py-2 text-xs text-gray-400">
                          <div>{startCase("address")}</div>
                          <div>{getVerifyDetails?.address}</div>
                        </li>
                      )}

                      {Object.entries(getVerifyDetails?.rawOutput)?.map(
                        (item) => (
                          <Fragment key={item[0]}>
                            {item[1] && (
                              <li className="flex items-center justify-between py-2 text-xs text-gray-400">
                                <div>{startCase(item[0])}</div>
                                <div>
                                  {item[0] === "dueDate"
                                    ? formatDate(item[1])
                                    : item[1]}
                                </div>
                              </li>
                            )}
                          </Fragment>
                        )
                      )}
                    </ul>
                  }
                />
              </div>

              <div className="col-span-2">
                <Button
                  label="Continue"
                  fullWidth
                  gradient
                  disabled={
                    !(
                      values &&
                      Object.values(values).every((item) => item) &&
                      parseInt(values?.amount) &&
                      values?.beneficiary?.length >= 10 &&
                      !errorAmt
                    )
                  }
                  handleClick={() => setOpen(true)}
                />
              </div>
            </>
          )} */}
        </div>
      ) : (
        <NoDisplay type="purchase" />
      )}
    </>
  );

  return (
    <>
      <div className="">
        <div className="rounded-sm border border-gray-200 bg-white p-4 shadow-card-box">
          {/* <FullWidthTabs
            {...{ currentTab, setCurrentTab, tabList }}
            Comp={currentTab.id === "waec" ? WaecSub() : JambSub()}
          /> */}
          {WaecSub()}
        </div>
        <EnterPIN
          open={open}
          setOpen={setOpen}
          openIsSubmitted={openIsSubmitted}
          setIsSubmitted={setIsSubmitted}
          processSubmit={handlePurchase}
          btnText={"Purchase Scratch Pin"}
          isSubmitting={isSubmitting}
        />
      </div>

      <Backdrop open={isLoadingList} />
    </>
  );
}
