import * as React from "react";
import {
  Dialog as MuiDialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogTitle,
  Paper,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Backdrop as MuiBackdrop,
  CircularProgress,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import Slide from "@mui/material/Slide";
import { LineWave } from "react-loader-spinner";
import { classNames, useBreakpoint } from "utils";
import { useSelector } from "react-redux";
import { SimpleIconButton } from "./Icon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function Dialog(props) {
  const {
    open,
    setOpen,
    title,
    children,
    fullScreen,
    closeBtn,
    showHeader,
    maxWidth = "sm",
    ...otherProps
  } = props;

  const { transactionStatus } = useSelector((state) => state.transaction || {});

  const handleClose = () => {
    if (!transactionStatus) {
      setOpen(false);
    }
  };

  return (
    <div className="relative">
      <MuiDialog
        open={open}
        onClose={handleClose}
        fullWidth
        TransitionComponent={Transition}
        {...(!fullScreen
          ? {
              scroll: "body",
              "aria-labelledby": "scroll-dialog-title",
              "aria-describedby": "scroll-dialog-description",
              maxWidth,
              PaperComponent: PaperComponent,
              "aria-labelledby": "draggable-dialog-title",
            }
          : { fullScreen })}
        {...otherProps}
      >
        {fullScreen || showHeader ? (
          <AppBar
            sx={{ position: "fixed" }}
            classes={{
              root: "h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow !sticky",
            }}
          >
            <Toolbar
              classes={{
                root: "justify-between items-center h-full",
              }}
            >
              <DialogTitle
                classes={{
                  root: "!font-normal text-center !text-base",
                }}
              >
                {title}
              </DialogTitle>

              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        ) : (
          <>
            {title && (
              <DialogTitle
                classes={{
                  root: "!font-normal text-center !text-base cursor-move",
                }}
                id="draggable-dialog-title"
              >
                {title}
              </DialogTitle>
            )}
            {closeBtn && (
              <button
                className="absolute top-0 right-0 z-[200] flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white"
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </button>
            )}
          </>
        )}
        {children}
      </MuiDialog>
    </div>
  );
}

export function Accordion({ title, content }) {
  return (
    <MuiAccordion
      elevation={0}
      square
      classes={{
        root: "border border-gray-200 rounded",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        // id="panel1a-header"
      >
        <div className="text-sm text-gray-500">{title}</div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{content}</Typography>
      </AccordionDetails>
    </MuiAccordion>
  );
}

export function Backdrop({ open, setOpen }) {
  return (
    <div>
      <MuiBackdrop
        sx={{ color: "#fff" }}
        classes={{ root: "z-[100]" }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="primary" />
      </MuiBackdrop>
    </div>
  );
}

export function BackdropSection({ open }) {
  return (
    <>
      {open && (
        <div className="absolute top-0 left-0 z-[40] h-full w-full rounded-lg bg-white">
          <div className="flex h-full w-full items-center justify-center">
            <LineWave
              heigth="100"
              width="100"
              color="#3d81b9"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}
    </>
  );
}

export function ResponsiveDialog({
  open,
  setOpen,
  title,
  children,
  showCloseButton = true,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiDialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      // scroll="body"
      classes={{
        paper: "!overflow-visible flex justify-center items-center !h-auto",
        scrollBody: "!overflow-visible",
        root: "!p-4",
      }}
    >
      {showCloseButton && (
        <>
          <div
            className={classNames(
              "absolute -right-2 -top-2 z-[502] flex h-10 w-10 items-center justify-center rounded-full bg-primary shadow-dialog"
            )}
          >
            <SimpleIconButton
              icon="Close"
              frontColor="text-white"
              onClick={handleClose}
              fontSize={22}
              className="!p-1"
            />
          </div>
        </>
      )}
      {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
      <DialogContent
        classes={{
          root: "!p-4",
        }}
      >
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
    </MuiDialog>
  );
}

export function PopupDrawer({
  setOpenDrawer,
  open,
  children,
  showCloseButton = true,
}) {
  const breakpoint = useBreakpoint();
  const { transactionStatus } = useSelector((state) => state.transaction || {});

  const handleClose = () => {
    if (showCloseButton) {
      if (!transactionStatus) {
        setOpenDrawer(false);
      }
    } else {
      return null;
    }
    // setOpenDrawer(false);
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      scroll="body"
      {...(breakpoint.md
        ? {
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            classes: {
              paper: "!w-[400px] !rounded !overflow-visible",
            },
          }
        : {
            fullScreen: true,
          })}
    >
      {showCloseButton && (
        <>
          <div
            className={classNames(
              "absolute z-[502] flex h-8 w-8 items-center justify-center rounded-full shadow-dialog",
              breakpoint.md
                ? "-right-2 -top-2 bg-white"
                : "right-3 top-3 bg-primary"
            )}
          >
            <SimpleIconButton
              icon="Close"
              frontColor={classNames(
                breakpoint.md ? "text-primary" : "text-white"
              )}
              onClick={handleClose}
              fontSize="small"
              className="!p-1"
            />
          </div>
        </>
      )}

      <div
        className={classNames(
          "overflow-hidden rounded text-text-color shadow-md",
          breakpoint.md ? "bg-white  p-2" : ""
        )}
      >
        {children}
      </div>
    </MuiDialog>
  );
}
