import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { capitalize, startCase } from "lodash";
import { classNames, formatDate, useBreakpoint } from "utils";
import { PageContainer } from "shared";
import {
  BackdropSection,
  BackPage,
  Drawer,
  IconBox,
  SectionContent,
  SimpleIcon,
  ToggleAmount,
  WalletIcon,
} from "components";
import { walletFiatProps } from "utils/constants";
import { useFiatBonusWalletListProps } from "api";
import ConvertFiat from "./sub-pages/ConvertFiat";
import BonusFiat from "./sub-pages/BonusFiat";
import TransferFiat from "./sub-pages/TransferFiat";
import FundFiat from "./sub-pages/FundFiat";
import WithdrawFiat from "./sub-pages/WithdrawFiat";
import WithdrawNaira from "./sub-pages/WithdrawNaira";

import { AppBar } from "@mui/material";
import { useLocation } from "react-router-dom";

const otherActionList = [
  {
    id: "transfer",
    name: "Transfer",
    icon: "ArrowCircleUpOutlined",
    bg: "bg-cyan-500",
    bgGradient: "from-cyan-500 to-cyan-800",
    text: "text-cyan-500",
    desc: "Transfer fiat to other Kaapo users",
  },
  {
    id: "bonus",
    name: "Bonus",
    icon: "ArrowCircleDownOutlined",
    bg: "bg-purple-500",
    bgGradient: "from-purple-500 to-purple-800",
    text: "text-purple-500",
    desc: "Transfer bonus to main account",
  },
  {
    id: "convert",
    name: "Convert",
    icon: "SwapHorizontalCircleOutlined",
    bg: "bg-yellow-500",
    bgGradient: "from-yellow-500 to-yellow-800",
    text: "text-yellow-500",
    desc: "Convert fiat to local currency",
  },
];

const actionList = {
  naira: [
    {
      id: "withdrawNaira",
      name: "Withdraw",
      icon: "ArrowCircleLeftOutlined",
      bg: "bg-red-500",
      bgGradient: "from-red-500 to-amber-800",
      text: "text-red-500",
      desc: "Withdraw fiat to local currency",
      subLink: [
        { name: "Own Account", id: "own-account" },
        { name: "Beneficiary Account", id: "beneficiary-account" },
        { name: "Other Account", id: "other-account" },
      ],
    },
    {
      id: "fundNaira",
      name: "Fund",
      icon: "ArrowCircleRightOutlined",
      bg: "bg-emerald-500",
      bgGradient: "from-emerald-500 to-emerald-600",
      text: "text-emerald-500",
      desc: "Fund fiat with local currency",
      // text: "text-white",
    },
    ...otherActionList,
  ],
  cedis: [
    {
      id: "withdrawCedis",
      name: "Withdraw",
      icon: "ArrowCircleLeftOutlined",
      bg: "bg-red-50",
      bgGradient: "from-red-500 to-amber-800",
      text: "text-red-500",
      desc: "Withdraw fiat to local currency",
    },
    // {
    //   id: "fundCedis",
    //   name: "Fund",
    //   icon: "ArrowCircleRightOutlined",
    //   bg: "bg-emerald-50",
    //   text: "text-emerald-500",
    // },
    ...otherActionList,
  ],
  cefa: [
    {
      id: "withdrawCefa",
      name: "Withdraw",
      icon: "ArrowCircleLeftOutlined",
      bg: "bg-red-50",
      bgGradient: "from-red-500 to-amber-800",
      text: "text-red-500",
      desc: "Withdraw fiat to local currency",
    },
    // {
    //   id: "fundCefa",
    //   name: "Fund",
    //   icon: "ArrowCircleRightOutlined",
    //   bg: "bg-emerald-50",
    //   text: "text-emerald-500",
    // },
    ...otherActionList,
  ],
};

const pages = {
  withdrawNaira: WithdrawNaira,
  withdrawCedis: WithdrawFiat,
  withdrawCefa: WithdrawFiat,
  fundNaira: FundFiat,
  transfer: TransferFiat,
  bonus: BonusFiat,
  convert: ConvertFiat,
};

export default function Index() {
  const location = useLocation();
  const { state } = location || {};

  const [pageDelay, setPageDelay] = useState(false);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentTab, setCurrentTab] = useState(state?.walletId || "naira");
  const [openDrawer, setOpenDrawer] = useState(false);

  const {
    profile: { user_details },
  } = useSelector((state) => state);
  const { fiatAuth } = useSelector((state) => state.utils);

  const breakpoint = useBreakpoint();
  const { walletProps } = useFiatBonusWalletListProps();

  const currentWallet = walletProps?.find((item) => item.id === currentTab);
  const currentBonusWallet = walletProps?.find(
    (item) => item.id === `${currentTab}Bonus`
  );

  const Page = currentPage ? pages?.[currentPage?.id] : () => null;

  useEffect(() => {
    setTimeout(() => {
      setPageDelay(true);
    }, 500);
    setPageDelay(false);
  }, [currentTab]);

  const title =
    currentPage?.name === "Withdraw"
      ? startCase(currentPage?.id)
      : currentPage?.id === "bonus"
      ? `Transfer Bonus - ${capitalize(currentWallet?.name)}`
      : `${
          ["transfer", "convert"].includes(currentPage?.id)
            ? `${currentPage?.name} Fiat - ${currentWallet?.name}`
            : startCase(currentPage?.id)
        }`;

  return (
    <PageContainer header="Wallets">
      <div className="">
        <Fade>
          <SectionContent otherclass="mb-4">
            <AppBar
              position="static"
              classes={{
                root: classNames("text-white"),
              }}
            >
              <div className={classNames("flex flex-row items-center")}>
                {walletFiatProps.map((item) => (
                  <button
                    className={classNames(
                      "flex flex-row items-center p-4 pr-4 text-white",

                      currentTab === item.id ? "bg-current-nav" : ""
                    )}
                    onClick={() => {
                      setCurrentTab(item.id);
                      setPage(0);
                    }}
                    key={item.id}
                  >
                    <div className="flex flex-row items-center justify-center">
                      <span>
                        <WalletIcon type={item.id} fontSize="small" />
                      </span>
                      <span
                        className={classNames(
                          "ml-2 text-sm font-bold",
                          currentTab === item.id ? "font-bold" : ""
                        )}
                      >
                        {item.name} {breakpoint.md ? "Wallet" : ""}
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            </AppBar>
          </SectionContent>

          <div className="grid grid-cols-1 gap-6">
            <div className="col-span-1">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-8 md:gap-4">
                <div className="col-span-1 md:col-span-3">
                  {pageDelay ? (
                    <div className="relative overflow-hidden rounded-sm border-0 border-gray-200 bg-gray-50 p-4 shadow-card-box">
                      <>
                        <div className="hero-pattern pointer-events-none absolute top-0 left-0 z-10 h-full w-full rounded-sm border bg-bottom" />
                        <div className="relative z-20 flex h-full flex-col justify-between">
                          {!breakpoint.md && (
                            <div className="mb-3">
                              <IconBox
                                icon={"AccountBalanceWalletOutlined"}
                                gradient
                              />
                            </div>
                          )}
                          <div className="flex items-start justify-between">
                            <div className="flex flex-1 items-start space-y-0">
                              {breakpoint.md && (
                                <div className="mr-3 ">
                                  <IconBox
                                    icon={"AccountBalanceWalletOutlined"}
                                    gradient
                                  />
                                </div>
                              )}
                              <div className="flex flex-col">
                                <div className="text-xs font-bold uppercase tracking-wide text-gray-500">
                                  Total Balance
                                  {/* {currentWallet.name} Wallet */}
                                </div>
                                <ToggleAmount
                                  walletAmt={currentWallet?.amount}
                                  sym={currentWallet?.sym}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="text-right">
                                <h4 className="mb-1 text-xs font-bold leading-tight tracking-widest text-gray-700">
                                  Last Activity
                                </h4>

                                <div className="flex items-center">
                                  <h2 className="text-xs font-bold tracking-widest text-gray-500 ">
                                    {formatDate(
                                      currentWallet?.updatedAt ||
                                        user_details.dateCreated
                                    )}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="my-4 h-[1px] w-full bg-card" />
                          <div className="flex flex-col">
                            <div className="flex items-center justify-between gap-1 md:gap-1">
                              {[
                                {
                                  name: `${currentWallet.name} Wallet`,
                                  wallet: currentWallet?.amount,
                                },
                                {
                                  name: "Bonus Wallet",
                                  wallet: currentBonusWallet?.amount,
                                },
                              ].map((item, index) => (
                                <div
                                  className={classNames(
                                    index === 1 ? "items-end" : "",
                                    "flex flex-col space-y-[2px]"
                                  )}
                                >
                                  <h4 className="text-xs font-bold leading-tight tracking-widest text-gray-700">
                                    {item.name}
                                  </h4>
                                  <ToggleAmount
                                    walletAmt={item.wallet}
                                    sym={currentWallet?.sym}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="z-1 pointer-events-none absolute -top-[90%] -right-0 h-[400px] w-[400px] rounded-full bg-slate-100/90" />
                      </>
                    </div>
                  ) : (
                    <div className="relative flex h-40 items-center justify-center rounded-sm border-0 border-gray-200 bg-gray-50 p-4 shadow-card-box">
                      <BackdropSection open />
                    </div>
                  )}
                </div>
                <div className="col-span-1 md:col-span-4">
                  {pageDelay ? (
                    <>
                      {page === 0 && (
                        <SectionContent>
                          <Fade>
                            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-2 xl:p-0">
                              {actionList?.[currentWallet?.id].map((item) => (
                                <div className="shadow-card-boxs relative top-0 col-span-1 duration-500 ease-in hover:shadow-lg md:col-span-1 lg:col-span-1">
                                  <button
                                    className="card flex h-full w-full flex-1 rounded-sm border border-slate-200 bg-slate-50 p-4"
                                    onClick={() => {
                                      if (breakpoint.md) {
                                        setPage(1);
                                      } else {
                                        setOpenDrawer(true);
                                      }
                                      setCurrentPage(item);
                                    }}
                                  >
                                    <div className="flex items-start justify-between">
                                      <SimpleIcon
                                        icon={item.icon}
                                        frontColor={item.text}
                                        // otherClass=""
                                      />
                                    </div>
                                    <div className="ml-2 text-sm">
                                      <div className="flex flex-row items-center">
                                        <h6 className="text-md font-bold tracking-wide">
                                          {item.name}
                                        </h6>
                                      </div>
                                      <div className="mt-2 text-left text-gray-500">
                                        {item.desc}
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              ))}
                            </div>
                          </Fade>
                        </SectionContent>
                      )}

                      {breakpoint.md && page === 1 && (
                        <div>
                          <BackPage handleClick={() => setPage(0)} />
                          <Fade>
                            <div className="rounded border border-gray-200 bg-white p-4 shadow-md">
                              <div className="-mx-4 -mt-4 flex h-12 items-center border-b border-slate-200 px-4">
                                <div className="font-semibold text-gray-600">
                                  {title}
                                </div>
                              </div>

                              <div
                                className={classNames(
                                  currentPage?.id === "withdrawNaira"
                                    ? "mt-2"
                                    : "mt-6"
                                )}
                              >
                                <Page
                                  currentWalletName={currentWallet?.id}
                                  displayPage={(type) =>
                                    fiatAuth?.[currentWallet?.id]?.[type]
                                  }
                                  setPage={setPage}
                                  setOpenMobileDrawer={setOpenDrawer}
                                />
                              </div>
                            </div>
                          </Fade>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="relative flex h-40 w-full items-center justify-center rounded-sm border-0 border-gray-200 bg-gray-50 p-4 shadow-card-box">
                      <BackdropSection open />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      {!breakpoint.md && (
        <Drawer
          anchor={currentPage?.id === "withdrawNaira" ? "bottom" : "right"}
          state={openDrawer}
          setState={setOpenDrawer}
          title={title}
        >
          <div
            className={classNames(
              "drawer-content",
              currentPage?.id === "withdrawNaira" ? "pb-2" : "p-6"
            )}
          >
            <Page
              currentWalletName={currentWallet?.id}
              displayPage={(type) => fiatAuth?.[currentWallet?.id]?.[type]}
              setPage={setPage}
              setOpenMobileDrawer={setOpenDrawer}
            />
          </div>
        </Drawer>
      )}
    </PageContainer>
  );
}
