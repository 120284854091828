import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "shared";
import { useSelector } from "react-redux";
import Fade from "react-reveal/Fade";

import { useAddToGiftcardCart, useAddSteps, useClearGiftcardCart } from "api";
import { BackdropSection, Button, GiftCard } from "components";
// import { storeG } from "api/actions/giftcard";

export default function Index() {
  const navigate = useNavigate();
  const { giftcard_rate, giftcard_rate_fac, giftcard_currency } = useSelector(
    (state) => state?.product
  );
  const { user_details } = useSelector((state) => state.profile || {});
  const { defaultCurrency } = user_details || {};

  const [addToSale] = useAddToGiftcardCart();
  const [addSteps] = useAddSteps();
  const [clearCart] = useClearGiftcardCart();

  const giftcardNames = giftcard_rate
    ?.map((g) => ({
      title: g.title,
      name: g.name,
      id: g.id,
      imageUrl: g.imageUrl,
    }))
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const addGiftcardName = (card) => {
    const callback = () => {
      navigate("/giftcard/sell");
      addSteps(1);
    };
    const currentCard = {
      card: card.name,
      "card name": card.title,
      id: card.id,
    };
    addToSale({ ...currentCard, defaultCurrency }, callback);
  };

  useEffect(() => {
    clearCart();
  }, []);

  return (
    <PageContainer header="Sell Giftcard">
      {/* <Button label="add" handleClick={() => storeG()} /> */}
      <div className="overflow-hidden rounded-sm border border-slate-200 bg-white p-4 shadow-card-box">
        {giftcard_rate_fac && giftcard_rate && giftcard_currency ? (
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 xl:gap-6 xl:p-0">
            {giftcard_rate.length ? (
              giftcardNames.map((card) => (
                <div className="md:col-span-1 xl:col-span-1" key={card.id}>
                  <Fade>
                    <GiftCard
                      card={card}
                      handleClick={() => addGiftcardName(card)}
                    />
                  </Fade>
                </div>
              ))
            ) : (
              <div>Not available for trading </div>
            )}
          </div>
        ) : (
          <div className="relative col-span-6 flex h-40 w-full items-center justify-center rounded-sm border-0 border-gray-200 bg-gray-50 p-4">
            <BackdropSection open />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
