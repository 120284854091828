import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Navigation from "./navigation";
import { muiTheme } from "./config/themeConfig";
import { ReactNotifications } from "react-notifications-component";

import "./index.css";
import "react-notifications-component/dist/theme.css";
import {
  useAuthStateChanged,
  useUserProfile,
  useNairaWallet,
  useCedisWallet,
  useCefaWallet,
  useWithdrawBankAccount,
  useGiftcardRate,
  useGiftcardRateFac,
  useGetFiatTransAuth,
  useGetUtilityTransAuth,
  useGetNotification,
  useGetAdvertPopup,
  useGetPortalImage,
} from "api";
import ErrorBoundary from "shared/ErrorHandle";
import { Footer } from "shared";
import Messanger from "components/Messanger";
import AdvertPopup from "components/AdvertPopup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const state = useSelector((state) => state);
  // console.log(state);
  const { advertPopup } = useSelector((state) => state.utils);

  useAuthStateChanged();
  useUserProfile();
  useNairaWallet();
  useCedisWallet();
  useCefaWallet();
  useWithdrawBankAccount();
  useGiftcardRate();
  useGiftcardRateFac();
  useGetFiatTransAuth();
  useGetUtilityTransAuth();
  useGetNotification();
  useGetAdvertPopup();
  useGetPortalImage();

  return (
    <div className="app">
      <div className="min-h-screen">
        <ThemeProvider theme={muiTheme}>
          <Messanger />
          {/* <ReactNotifications /> */}
          <ToastContainer />

          <ErrorBoundary>
            <Navigation />
            {state?.auth?.currentUser?.current_user && (
              <AdvertPopup advertPopup={advertPopup} />
            )}
          </ErrorBoundary>
        </ThemeProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
