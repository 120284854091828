import { Route } from "react-router-dom";
import {
  Home,
  Dashboard,
  Wallets,
  Giftcard,
  Utilities,
  Transactions,
  Settings,
  SellGiftcard,
  PurchaseUtitlity,
  SuccessTransaction,
  DownloadApp,
  Notifications,
  Withdraw,
} from "../pages";
import { UserIsAuthenticated } from "./AuthProvider";

const PrivateRoutes = (
  <Route>
    {[
      { path: "/", element: Home },
      { path: "/dashboard", element: Dashboard },
      { path: "/wallets", element: Wallets },
      { path: "/giftcard", element: Giftcard },
      { path: "/utilities", element: Utilities },
      { path: "/transactions", element: Transactions },
      { path: "/settings", element: Settings },
      { path: "/success-trade", element: SuccessTransaction },
      { path: "/download-app", element: DownloadApp },
      { path: "/notifications", element: Notifications },
      { path: "/withdraw", element: Withdraw },

      { path: "/utilities/:id", element: PurchaseUtitlity },
      { path: "/giftcard/sell", element: SellGiftcard },
    ].map((r, ind) => (
      <Route
        key={ind}
        exact
        path={r.path}
        element={
          <UserIsAuthenticated>
            <r.element />
          </UserIsAuthenticated>
        }
      />
    ))}
  </Route>
);

export default PrivateRoutes;
