import imageCompression from "browser-image-compression";
import * as i from "./lib";
import axios from "axios";

import UAParser from "ua-parser-js";
import DeviceDetector from "device-detector-js";
import { appCheck } from "./actions/utils";
import { publicIpv4 } from "public-ip";

const deviceDetector = new DeviceDetector();
const parser = new UAParser();

const auth = i.getAuth();
const url = (endpoint) => `${i.baseURL}/${endpoint}`;

export const uploadImage = async (images, storagePath, fileName) => {
  const uploadedImgProm = await images.map(async (image) => {
    const compressImgOption = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    const file = await imageCompression(image.file, compressImgOption);
    const storageRef = i.ref(
      i.getStorage(),
      `${storagePath}/${fileName || file.name}`
    );
    const snapshot = await i.uploadBytes(storageRef, file);
    const downloadURL = await i.getDownloadURL(snapshot.ref);
    // console.log(downloadURL);
    return downloadURL;
  });

  return await Promise.all(uploadedImgProm);
};

export const options = (transData, url, appCheckToken, idempotentKey) => {
  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: auth?.currentUser?.accessToken,
      "X-Firebase-AppCheck": appCheckToken,
      "X-Idempotency-Key": idempotentKey,
    },
    data: {
      uid: auth?.currentUser?.uid,
      transData: { ...transData, customerId: auth?.currentUser?.uid },
    },
    url,
  };
};

const optionsAlt = (transData, url, appCheckToken) => ({
  method: "POST",
  url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Firebase-AppCheck": appCheckToken,
  },
  data: {
    transData,
  },
});

export const axiosCall = (url, transData, useAuth, idempotentKey) => {
  return new Promise(async (resolve, reject) => {
    try {
      const appCheckTokenResponse = await appCheck();

      const option = useAuth
        ? options(transData, url, appCheckTokenResponse, idempotentKey)
        : optionsAlt(transData, url, appCheckTokenResponse);

      const res = await axios(option);

      return resolve(res);
    } catch (error) {
      let err = error;
      if (error?.response) {
        err = error?.response?.data?.error;
      }
      return reject(err);
    }
  });
};

export const axiosCall2 = async (requests, useAuth) => {
  const makeRequest = async ({ url, transData, idempotentKey }) => {
    try {
      const appCheckToken = await appCheck();

      const option = useAuth
        ? options(transData, url, appCheckToken, idempotentKey)
        : optionsAlt(transData, url, appCheckToken);

      const res = await axios(option);
      return res;
    } catch (error) {
      let err = error;
      if (error?.response) {
        err = error?.response?.data?.error;
      }
      throw err;
    }
  };

  if (Array.isArray(requests)) {
    try {
      const responses = await Promise.all(requests.map(makeRequest));
      return responses;
    } catch (error) {
      // Handle error for batch requests
      console.error("Error in batch requests:", error);
      throw error;
    }
  } else {
    // Single request case
    try {
      const response = await makeRequest(requests);
      return response;
    } catch (error) {
      // Handle error for single request
      console.error("Error in single request:", error);
      throw error;
    }
  }
};

export const verifyOTP = async (
  values,
  urlLink = "verify_email_otp",
  useAuth = true
) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    email: values?.newEmail || values?.email || auth?.currentUser?.email,
    username: values?.displayName,
    phoneNumber: values?.phoneNumber,
    isVerify: values?.isVerify ?? true,
    brandName: values?.brandName,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/${urlLink}`),
        { values: valueFormat },
        useAuth
      );
      return resolve(res);
    } catch (error) {
      // console.log(error);
      return reject(error);

      // return reject(
      //   error.response && error.response?.data?.error
      //     ? error.response?.data?.error
      //     : error
      // );
    }
  });
};

export const checkOTP = async (values, urlLink = "check_email_otp") => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    email: values?.newEmail || auth?.currentUser?.email,
    code: values?.code,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/${urlLink}`),
        { values: valueFormat },
        true
      );
      return resolve(res);
    } catch (error) {
      return reject(error);

      // return reject(
      //   error.response && error.response?.data?.error
      //     ? error.response?.data?.error
      //     : error
      // );
    }
  });
};

export const updateEmail = async (values) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    email: values?.newEmail,
    code: values?.code,
  };

  const opt = options(valueFormat, url("auth/update_email"));

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(
        error.response && error.response?.data?.error
          ? error.response?.data?.error
          : error
      );
    }
  });
};

export const updatePhone = async (values) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    phoneNumber: values?.phone,
    code: values?.code,
    email: values?.email,
    requestId: values?.phoneCodeRef,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosCall(
        url(`auth/update_phone`),
        { values: valueFormat },
        true
      );

      return resolve(res);
    } catch (error) {
      return reject(
        error.response && error.response?.data?.error
          ? error.response?.data?.error
          : error
      );
    }
  });
};

export const updateAcct = async (values) => {
  const valueFormat = {
    customerId: auth?.currentUser?.uid,
    ...values,
  };

  const opt = options(valueFormat, url("profile/update_withdrawal_account"));

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDevice = async () => {
  const userAgent = parser.getResult().ua;
  // const res = await axios.get("https://geolocation-db.com/json/");
  const IPv4 = await publicIpv4();

  const devD = deviceDetector.parse(userAgent);
  return { ...devD, ipAddress: IPv4 };
};
