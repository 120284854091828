import React, { useState } from "react";
import { Button, Select, TextField } from "components";
import { useSelector } from "react-redux";

export default function CreateAcctForm({ isSubmitting, createDepositAcc }) {
  const {
    user_details: { displayName },
  } = useSelector((state) => state.profile || {});

  const [input, setInput] = useState({
    bvn: "",
    accountName: "",
  });

  const avaliableBanks = [
    { bankName: "Wema Bank", id: "035" },
    { bankName: "Sterling Bank", id: "232" },
    // { bankName: "Moniepoint", id: "50515" },
  ];

  const handleInput = async (e) => {
    let val = e.target.value;
    const name = e.target.name;

    setInput((prevS) => {
      let v = val;
      if (name === "bvn") {
        val = val.replace(/[^0-9]/g, "");
        if (val.length >= 11) {
          v = val.slice(0, 11);
        }
      }
      return { ...prevS, [name]: v };
    });
  };

  return (
    <>
      <div className="mb-6">
        <h6 className="text-base text-gray-500">
          You need to create a bank account to fund your wallet.
        </h6>
      </div>
      <div className="grid grid-cols-1 gap-6">
        <div className="col-span-1">
          <TextField
            name="bvn"
            type="text"
            label="BVN"
            value={input?.bvn}
            handleChange={handleInput}
            placeholder="Input BVN"
            disableAutoComplete
          />
        </div>

        <div className="col-span-1">
          <Select
            handleChange={(e) => {
              setInput((prevS) => ({
                ...prevS,
                bankName: e.bankName,
                code: e.id,
              }));
            }}
            titleValue="bankName"
            dropDown={avaliableBanks ?? []}
            inputLabel="Bank"
          />
        </div>

        <div className="col-span-1">
          <Button
            label="Create Account"
            gradient
            fullWidth
            handleClick={() => {
              createDepositAcc({ ...input, accountName: displayName });
            }}
            disabled={
              !(input?.bvn?.length === 11 && input?.code && input?.bankName)
            }
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </>
  );
}
