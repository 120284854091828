import React from "react";
import { ChevronRight } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useLogout } from "api";
import NavbarTop from "./NavbarTop";
import NavbarBottom from "./NavbarBottom";
import { capitalize } from "lodash";
import { Box } from "@mui/material";
import SelectFiatCurrency from "components/SelectCurrency";

export default function PageContainer({ children, header, footerPos }) {
  const [, , , logout] = useLogout();
  const { user_details } = useSelector((state) => state.profile);

  const { defaultCurrency } = user_details || {};

  const location = useLocation();
  const path = location.pathname.split("/").slice(1);

  return (
    <div className="page-container relative">
      <div className="navbar relative z-50">
        <NavbarTop logout={logout} avatarUrl={user_details.avatarUrl} />
        <NavbarBottom logout={logout} avatarUrl={user_details.avatarUrl} />
      </div>

      <header className="header h-48 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to">
        <div className="mx-auto flex justify-between py-8 px-4 md:px-10">
          <div className="flex items-center">
            {header && (
              <div>
                <h1 className="text-xl font-bold text-white">{header}</h1>
              </div>
            )}
          </div>
          <ul className="hidden items-center justify-center text-sm text-white md:flex">
            <li className="flex items-center">
              <Link to="/" className="underline underline-offset-2">
                App
              </Link>
              <ChevronRight strokeWidth="2px" size={16} />
            </li>
            {path.map((i, index) => {
              const d = i !== path[path.length - 1];
              const Block = d ? Link : Box;
              return (
                <li className="flex items-center" key={index}>
                  <Block
                    to={`/${i}`}
                    className={d ? "underline underline-offset-2" : ""}
                  >
                    {capitalize(i)}
                  </Block>
                  {i !== path[path.length - 1] && (
                    <ChevronRight strokeWidth="2px" size={16} />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </header>

      <main className="main-content">
        <div className="-mt-32 px-0 md:-mt-32 md:px-4">
          <div className="mx-auto max-w-screen-2xl p-3 sm:p-4 lg:p-6">
            {children}
          </div>
        </div>
      </main>

      <SelectFiatCurrency
        open={user_details && !defaultCurrency}
        defaultCurrency={defaultCurrency}
      />
    </div>
  );
}
