import React from "react";

import { Form, SimpleIcon } from "components";
import { AuthContainer } from "shared";
import { loginValidationSchema } from "utils/formSchema";
import { useLogin } from "api";
import { useBreakpoint } from "utils";

export default function Login() {
  const [isLoading, isLoaded, error, login] = useLogin();

  const fieldList = [
    {
      name: "email",
      type: "email",
      label: "Email",
      inputType: "textfield",
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      inputType: "textfield",
    },
  ];
  const initialFieldValues = { email: "", password: "" };

  const handleSubmit = (values) => {
    const { email, password } = values;
    login(email, password);
  };

  return (
    <AuthContainer
      title="The adventure starts here 🚀"
      subTitle="Sign in and experience a faster, easier and more reliable way to buy
      and sell your Giftcards with Kaapo."
      footer={{ link: "/register", text: "Don't have an account? Register!" }}
      showForgetPass
      showDownloadAppButton
    >
      {/* <div className="mb-6 flex flex-row items-center rounded border-0 border-white bg-primary/10 p-4 font-semibold shadow">
        <SimpleIcon
          icon="Campaign"
          fontSize={36}
          frontColor="text-amber-500"
          otherClass=""
        />
        <p className="ml-4 text-primary">
          If you have an Esetech account on or before the 30th of November 2022,
          kindly log in with your details.
        </p>
      </div> */}
      <Form
        fieldList={fieldList}
        initialFieldValues={initialFieldValues}
        buttonText="Login"
        buttonLoading={isLoading}
        handleSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
        fullWidth
      />
    </AuthContainer>
  );
}
