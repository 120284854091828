/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-reveal";
import { toLower } from "lodash";
import {
  Select,
  TextField,
  Button,
  Autocomplete,
  EnterPIN,
  CheckboxInputs,
} from "components";
import { useVerifyUtility, usePurchaseUtiliity, useGetUtilityList } from "api";

const serviceList = [
  { name: "prepaid", id: "prepaid" },
  { name: "postpaid", id: "postpaid" },
];
export default function BuyPower({ nairaWallet }) {
  const navigate = useNavigate();

  // useState Hooks
  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [phoneN, setPhoneN] = useState("");

  const [values, setValues] = useState(null);
  const [getPowerList, setGetPowerList] = useState(null);
  const [packagePlan, setPackagePlan] = useState(null);
  const [sendToken, setSendToken] = useState({
    email: true,
    sms: false,
  });

  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [isSubmitting, isSubmitted, purchaseUtility] = usePurchaseUtiliity();

  const [
    getVerifyDetails,
    setVerify,
    verifyPower,
    isLoading,
    isErrorVerify,
    setErrorVerify,
  ] = useVerifyUtility();

  const [utilityList, isLoadingList] = useGetUtilityList("electricity");

  useEffect(() => {
    const { productPlan } = values || {};

    if (productPlan) {
      const list =
        utilityList &&
        utilityList
          ?.filter((item) => toLower(item.name)?.includes(toLower(productPlan)))
          .map((item) => ({ ...item, id: item.shortname }));

      setGetPowerList(list);
    }
  }, [values?.productPlan, utilityList]);

  const handlePhoneInput = (val) => {
    let e = val.target.value.replace(/[^0-9]/g, "");
    if (e.length > 10) {
      setPhoneN(e.slice(0, 10));
    } else {
      setPhoneN(e);
    }
  };

  const handleBeneficiary = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");

    setValues((prevS) => ({
      ...prevS,
      beneficiary: val,
    }));
    // setErrorAmt(false);
    // setSaveBene(false);
  };

  const handleAmtChange = (e) => {
    const total = parseInt(e);
    const limit = parseInt(e) < 100 || parseInt(e) > 50000;
    const overlimit = total >= parseInt(nairaWallet?.amount || 0);
    const overcharges = parseInt(nairaWallet?.amount || 0) - total < 50;

    setValues((prevS) => ({ ...prevS, amount: e }));
    setTotalAmt(total);
    setErrorAmt(overlimit || overcharges || limit);
    setErrorText(
      overlimit
        ? "You have exceeded balance amount"
        : limit
        ? "Purchase must be within ₦100 and ₦50000"
        : overcharges
        ? "Maintain a minimum of ₦50 in wallet"
        : ""
    );
  };

  const handlePurchase = () => {
    const {
      amount,
      provider,
      providerId,
      beneficiary,
      productPlan,
      serviceType,
    } = values;

    const valuesAlt = {
      amountToDebit: parseInt(amount),
      provider,
      providerName: serviceType,
      providerId: `${providerId}`,
      beneficiary,
      productType: "electricity",
      productPlan,
      amount: parseInt(amount),
      transactionDetails: {
        productId: serviceType,
        phone: `0${phoneN}`,
        meterNumber: beneficiary,
        amount: parseInt(amount),
        sendToken,
        serviceType,
      },
    };

    purchaseUtility(valuesAlt);
  };

  const disabledTrans = !(
    values &&
    // Object.values(values).every((item) => item) &&
    parseInt(values?.amount) &&
    values?.beneficiary?.length >= 10 &&
    phoneN &&
    phoneN.length === 10 &&
    !errorAmt
  );

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setValues(null);
      setPhoneN("");
      setGetPowerList(null);
      setPackagePlan(null);
      setVerify(null);
      setTotalAmt(0);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        setPhoneN("");
        navigate(-1);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  return (
    <div className="">
      <div className="rounded-sm border border-gray-200 bg-white p-4 shadow-card-box">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-2">
            <Select
              titleValue="name"
              dropDown={serviceList}
              inputLabel="Service Type"
              disabled={isSubmitting}
              handleChange={(e) => {
                setValues((prevS) => ({
                  productPlan: e.name,
                }));
                setErrorAmt(false);
                setVerify(null);
                setPackagePlan(null);
                setPhoneN(null);
                setErrorVerify(false);
              }}
              valueAlt={values?.productPlan || ""}
              isLoading={isLoadingList}
            />
          </div>

          <div className="col-span-2">
            <Select
              titleValue="name"
              dropDown={getPowerList || []}
              inputLabel="Provider"
              disabled={isSubmitting || !values?.productPlan || !getPowerList}
              handleChange={(e) => {
                setValues((prevS) => ({
                  productPlan: values?.productPlan,
                  provider: e.name,
                  providerId: e.product_id,
                  serviceType: e.service_type,
                }));
                setErrorAmt(false);
                setVerify(null);
                setPackagePlan(null);
                setErrorVerify(false);
                setPhoneN(null);
              }}
              valueAlt={values?.provider || ""}
              isLoading={isLoadingList || !values?.productPlan || !getPowerList}
              placeholder="Select Provider"
            />
          </div>

          <div className="col-span-2">
            <TextField
              name="amount"
              label="Amount"
              value={values?.amount || ""}
              startAdornment="₦"
              handleChange={handleAmtChange}
              formatInput
              disableAutoComplete
              placeholder="0.00"
              // readOnly
              error={errorAmt === true}
              showError={errorAmt === true}
              // helperText={errorText}
            />
            {errorAmt && (
              <Slide down duration={300}>
                <div className="mt-2 text-xs font-normal text-red-500">
                  {errorText}
                </div>
              </Slide>
            )}
          </div>

          <div className="col-span-2">
            <TextField
              name="phoneNumber"
              type="text"
              label="Recipient Phone Number"
              value={phoneN || ""}
              handleChange={handlePhoneInput}
              disableAutoComplete
              placeholder="8100200300"
              startAdornment={<div className="">+234</div>}
              disabled={isSubmitting}
            />
          </div>

          <div className="col-span-2">
            <TextField
              name="beneficiary-number"
              label="Meter Number"
              // type="number"
              placeholder="Input meter number"
              value={values?.beneficiary || ""}
              handleChange={(e) => {
                handleBeneficiary(e);
                // setErrorAmt(false);
                // setErrorText(null);
              }}
              disableAutoComplete
              endAdornment={
                <Button
                  label="Verify"
                  gradient
                  handleClick={() => {
                    verifyPower({
                      serviceType: values?.serviceType,
                      customerId: values?.beneficiary,
                    });
                  }}
                  isSubmitting={isLoading}
                  disabled={
                    !(values?.provider && values?.beneficiary?.length >= 10)
                  }
                />
              }
            />
            {(isErrorVerify || isLoading) && !getVerifyDetails && (
              <Slide down duration={300}>
                {isLoading ? (
                  <div className="mt-2 text-xs font-normal text-cyan-500">
                    Loading ...
                  </div>
                ) : (
                  <div className="mt-2 text-xs font-normal text-red-500">
                    {isErrorVerify}
                  </div>
                )}
              </Slide>
            )}
          </div>

          {getVerifyDetails && (
            <>
              <div className="col-span-2">
                <TextField
                  name="beneficiary-name"
                  label="Beneficiary Name"
                  value={getVerifyDetails?.user?.name || ""}
                  readOnly
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="beneficiary-address"
                  label="Beneficiary Address"
                  value={getVerifyDetails?.user?.address || ""}
                  readOnly
                />
              </div>

              <div className="col-span-2">
                <CheckboxInputs
                  checkList={[
                    {
                      value: "email",
                      checked: sendToken?.email,
                      label: "Receive token through email",
                    },
                    {
                      value: "sms",
                      checked: sendToken?.sms,
                      label: "Receive token through SMS",
                      disabled: true,
                    },
                  ]}
                  handleChange={(e) =>
                    setSendToken((prevS) => ({
                      ...prevS,
                      [e.target.value]: !prevS?.[e.target.value],
                    }))
                  }
                  rowAligned
                />
              </div>

              <div className="col-span-2">
                <Button
                  label="Continue"
                  fullWidth
                  gradient
                  disabled={disabledTrans}
                  handleClick={() => setOpen(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <EnterPIN
        open={open}
        setOpen={setOpen}
        openIsSubmitted={openIsSubmitted}
        setIsSubmitted={setIsSubmitted}
        processSubmit={handlePurchase}
        btnText={"Purchase Electricity"}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}
