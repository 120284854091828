import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Form } from "components";
import { verifyPhoneSchema, changePhoneSchema } from "utils/formSchema";
import { useVerifyPhone, useChangePhone } from "api";
import { changePhoneFormList, verifyPhoneFormList } from "utils/constants";

export default function ChangePhone({ setDrawer }) {
  const {
    user_details: { displayName, email },
  } = useSelector((state) => state.profile);

  const [phone, setPhone] = useState(null);

  const [isUpdating, isUpdated, verifyPhone, phoneCodeRef] = useVerifyPhone();
  const [isUpdatingPhone, isUpdatedPhone, changePhone] = useChangePhone();

  const verifyPhonefield = verifyPhoneFormList;
  const changePhonefield = changePhoneFormList(phone);

  const verifiyPhoneInitialVal = {
    phone: "",
    currentPass: "",
  };

  const changePhoneInitialVal = {
    code: "",
  };

  useEffect(() => {
    if (isUpdatedPhone) setDrawer(false);
  }, [isUpdatedPhone]);

  const handleVerify = (values) => {
    verifyPhone({ ...values, displayName, brandName: "Kaapo" });
    setPhone(`${values?.phone}`);
  };

  const handleSubmit = (values) => {
    changePhone({ ...values, phone, email, sentEmail: true, phoneCodeRef });
  };

  return (
    <div className="p-4">
      <Form
        fieldList={verifyPhonefield}
        initialFieldValues={verifiyPhoneInitialVal}
        buttonText="Verify Phone"
        buttonLoading={isUpdating}
        handleSubmit={handleVerify}
        validationSchema={verifyPhoneSchema}
        disableVisibilty={isUpdated}
        disableAutoComplete
        verifyType="phoneVerify"
        isDisabled={isUpdating}
        isDisabledFormFirstForm={isUpdated}
      />
      {isUpdated && (
        <>
          <div className="mt-6" />
          <Form
            fieldList={changePhonefield}
            initialFieldValues={changePhoneInitialVal}
            buttonText="Change Phone"
            buttonLoading={isUpdatingPhone}
            handleSubmit={handleSubmit}
            validationSchema={changePhoneSchema}
            isDisabled={isUpdatingPhone}
            disableAutoComplete
          />
        </>
      )}
    </div>
  );
}
