import React from "react";
import { Bars } from "react-loader-spinner";
import { Backdrop as MuiBackdrop, CircularProgress } from "@mui/material";
import { classNames } from "utils";

export default function Loader({ fullPage }) {
  return (
    <div
      className={classNames(
        fullPage ? "h-screen" : "",
        "flex items-center justify-center"
      )}
    >
      <Bars
        heigth="100"
        width="100"
        color="#4174b2"
        ariaLabel="loading-indicator"
      />{" "}
    </div>
  );
}
