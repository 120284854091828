import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

import { FormControlLabel, Checkbox } from "@mui/material";

import {
  Select,
  TextField,
  SelectChip,
  EnterPIN,
  Button,
  Backdrop,
} from "components";
import { useGetPhoneList, useGetUtilityCommission, useSubmitSale } from "api";
import { FormAccordion, FormInstruction } from "../shared/FormComponents";

const dropDown = (providerNo) => [
  { name: "MTN", id: "mtn", disabled: !providerNo["mtn"] },
  { name: "Airtel", id: "airtel", disabled: !providerNo["airtel"] },
  { name: "GLO", id: "glo", disabled: !providerNo["glo"] },
  {
    name: "9Mobile",
    id: "9mobile",
    disabled: !providerNo["9mobile"],
  },
];

export default function ConvertAirtime() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [network, setNetwork] = useState(null);
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(null);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [terms, setTerms] = useState(false);

  useGetPhoneList();
  const { phoneList } = useSelector((state) => state.utils);
  const [isSubmitting, isSubmitted, submitSale] = useSubmitSale();

  useEffect(() => {
    const scroll = window.scrollTo(0, 0);
    return scroll;
  }, [page]);

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setValues(null);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        navigate(-1);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  const commission = useGetUtilityCommission();

  const handleAmtChange = (e) => {
    const total = parseInt(e);
    const limit = total < 500 || total > 200000;

    setValues((prevS) => ({
      ...prevS,
      amount: e,
      weBuy: parseInt(e) - commission * parseInt(e),
    }));
    setErrorAmt(limit);
    setErrorText("Airtime sale must be within ₦500 and ₦200000");
  };

  const handlePhone = (val) => {
    let e = val.target.value.replace(/[^0-9]/g, "");
    if (e.length > 10) {
      e = e.slice(0, 10);
    }
    setValues((prevS) => ({ ...prevS, beneficiary: e }));
  };

  const handleSale = () => {
    const { amount, providerName, provider, weBuy, beneficiary } = values;

    const valuesAlt = {
      amountToCredit: parseInt(weBuy),
      provider,
      providerName,
      beneficiary: `+234${beneficiary}`,
      phoneWithAirtime: `+234${beneficiary}`,
      productType: "convert-airtime",
      productPlan: null,
      amount: parseInt(weBuy),
      commission,
      amountBought: parseInt(weBuy),
      amountSold: parseInt(amount),
    };

    submitSale(valuesAlt);
  };

  const show =
    values?.beneficiary?.length === 10 &&
    values?.provider &&
    values?.amount &&
    !errorAmt;

  if (!phoneList) {
    return <Backdrop />;
  }

  return (
    <div className="rounded-sm border border-gray-200 bg-white p-4 shadow-card-box">
      <div className="flex items-center justify-between">
        {page !== 0 && (
          <button
            className=""
            onClick={() => {
              setPage(0);
              setValues(null);
              setErrorAmt(false);
              setErrorText(null);
              setTerms(false);
            }}
          >
            <ArrowCircleLeftOutlinedIcon className="iconShadow-alt !text-3xl text-color-main" />
          </button>
        )}
      </div>

      {page === 0 && <FormInstruction setPage={setPage} />}

      {page === 1 && (
        <div>
          <div className="mt-4">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
              <div className="col-span-2">
                <Select
                  handleChange={(e) => {
                    setValues((prevS) => ({
                      ...prevS,
                      provider: e.name,
                      providerName: e.id,
                    }));
                    setNetwork(e);
                  }}
                  titleValue="name"
                  dropDown={dropDown(phoneList)}
                  inputLabel="Provider"
                  disabled={isSubmitting}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="beneficiary-number"
                  // type="number"
                  label="Phone Number With Airtime"
                  value={values?.beneficiary}
                  handleChange={handlePhone}
                  placeholder="8100200300"
                  disableAutoComplete
                  startAdornment={<div className="">+234</div>}
                  disabled={isSubmitting}
                />
              </div>

              <div className="col-span-2">
                <SelectChip
                  title="Select Airtime Amount"
                  list={["500", "1000", "2000", "5000", "10000", "20000"]}
                  handleClick={handleAmtChange}
                  currData={values?.amount || ""}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="amount"
                  type="text"
                  label="Amount"
                  value={values?.amount || ""}
                  handleChange={handleAmtChange}
                  startAdornment="₦"
                  formatInput
                  disableAutoComplete
                  error={errorAmt === true}
                  showError={errorAmt === true}
                  helperText={errorText}
                  placeholder="Input amount"
                  disabled={isSubmitting}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="we-buy"
                  type="text"
                  label="We Buy At"
                  value={values?.weBuy || ""}
                  startAdornment="₦"
                  formatInput
                  readOnly
                />
              </div>

              {show && (
                <>
                  <div className="col-span-2">
                    <FormAccordion
                      provider={values?.providerName}
                      amount={values?.amount}
                      phoneList={phoneList}
                    />
                  </div>
                  <div className="col-span-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={terms}
                          size="small"
                          onChange={() => setTerms(!terms)}
                        />
                      }
                      label="I agree to sending the airtime before submitting this form."
                      classes={{ label: "!text-sm ml-2" }}
                    />
                  </div>
                </>
              )}

              <div className="col-span-2">
                <Button
                  label="Continue"
                  fullWidth
                  gradient
                  disabled={!(show && terms)}
                  handleClick={() => setOpen(true)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <EnterPIN
        open={open}
        openIsSubmitted={openIsSubmitted}
        setIsSubmitted={setIsSubmitted}
        setOpen={setOpen}
        processSubmit={handleSale}
        btnText={"Process Sale"}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}
