import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";

import { IconBox } from "./Icon";

const CustomPagination = ({ setPage, count, page }) => {
  const handleChangePage = (event, newPage) => {
    setPage(parseInt(newPage) - 1);
  };

  return (
    <div className="flex items-center justify-center">
      <Pagination
        variant="outlined"
        shape="rounded"
        color="primary"
        count={count}
        page={page + 1}
        onChange={handleChangePage}
      />
    </div>
  );
};

export function TableData({ columns, rows, hideFooter, onRowClick }) {
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = React.useState(0);

  const pageCount = Math.ceil(rows?.length / pageSize);

  return (
    <div style={{ display: "", height: "100%", width: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          classes={{
            root: "!text-gray-500",
            cell: "!outline-none",
            row: "cursor-pointer",
            columnHeader: "!outline-none !text-center !capitalize",
            columnHeaders: "bg-white border-none text-gray-900 !bg-white",
            footerContainer: "!text-gray-500",
          }}
          columns={columns}
          rows={rows}
          pageSize={pageSize}
          page={page}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          hideFooterSelectedRowCount
          hideFooter={pageCount > 1 ? hideFooter : true}
          autoHeight
          getRowHeight={() => 56}
          initialState={{ pinnedColumns: { right: ["action"] } }}
          onRowClick={(params) => {
            onRowClick(params.id);
          }}
          components={{
            ...(pageCount > 1
              ? {
                  Pagination: () => (
                    <CustomPagination
                      {...{ setPage, page }}
                      count={pageCount}
                    />
                  ),
                }
              : {
                  NoRowsOverlay: () => (
                    <div className="mt-0 flex h-full flex-col items-center justify-center text-sm text-gray-400">
                      <IconBox gradient icon="DeveloperBoardOff" />
                      <div className="mt-4 font-bold">No data to display</div>
                    </div>
                  ),
                }),
          }}
        />
      </div>
    </div>
  );
}

export function SimpleTable({ columns, rows, caption }) {
  return (
    <div className="w-full overflow-auto rounded">
      <TableContainer sx={{ maxHeight: 440, minWidth: 650 }}>
        {caption && (
          <div className="caption">
            <div className="p-5 text-sm text-gray-400">{caption}</div>
            <Divider />
          </div>
        )}
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  classes={{ root: "!text-gray-500 !bg-gray-50" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={index}
                        align={column.align}
                        classes={{ root: "!text-gray-400" }}
                      >
                        {value}
                        {/* {column.component ? value : value} */}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
