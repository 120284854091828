import * as React from "react";
import { Button as MuiButton } from "@mui/material";
import { classNames } from "utils";
import { Link } from "react-router-dom";

export default function Button({
  label,
  type,
  handleClick,
  disabled,
  otherClass,
  gradient,
  fullWidth,
  link,
  color = "primary",
  variant = "contained",
  size,
  Icon,
  state,
  isSubmitting,
}) {
  return (
    <MuiButton
      color={color}
      variant={variant}
      {...(link && { component: Link, to: link, state })}
      {...(handleClick && { onClick: handleClick })}
      // {...(disabled && { disabled })}
      {...(type && { type })}
      {...(size && { size })}
      disabled={disabled || isSubmitting}
      classes={{
        disabled: gradient ? "!text-white/50" : "",
      }}
      className={classNames(
        gradient
          ? "!rounded-sm bg-gradient-to-r from-button_gradient_from to-button_gradient_to !shadow-lg"
          : variant === "contained"
          ? "!shadow"
          : "",
        fullWidth ? "w-full" : "",
        "h-[36px] !text-sm !font-bold !capitalize",
        otherClass
      )}
    >
      {!isSubmitting ? (
        <>{Icon ? <Icon className="iconShadow" /> : label}</>
      ) : (
        <div>Loading..</div>
      )}
    </MuiButton>
  );
}
