import { useEffect, useState } from "react";
import { axiosCall } from "../helpers";
import {
  notification,
  createRefID,
  formatInputNum,
  getDeviceType,
} from "utils";
import { useNavigate } from "react-router";
import { isEqual, omit } from "lodash";

import { useDispatch } from "react-redux";

import * as i from "../lib";

import { getTransactionStatus } from "redux/reducers/transactionReducers";

const auth = i.getAuth();
const url = (endpoint) => `${i.baseURL}/utility/${endpoint}`;

export const useGetUtilityList = (type) => {
  const [utilityList, setGetUtilityList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setGetUtilityList(null);
    setLoading(true);
    const getUtilityList = async () => {
      try {
        if (type) {
          setLoading(true);
          const dataList = await axiosCall(url("get_utility_list"), { type });
          const list = dataList?.data?.data;
          setGetUtilityList(list);
          setLoading(false);
        }
      } catch (error) {
        notification("danger", "An error occured fetching data!", "error");
        setLoading(false);
      }
    };

    getUtilityList();
  }, [type]);
  return [utilityList, isLoading];
};

export const useVerifyUtility = () => {
  const [getVerifyDetails, setVerify] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const verifyUtility = async (value) => {
    setLoading(true);
    setError(false);
    try {
      const verify = await axiosCall(url("verify_utility"), value);

      setLoading(false);
      setVerify(verify?.data?.data);
    } catch (error) {
      setError(error);
      setLoading(false);
      notification(
        "danger",
        error?.messages?.includes("entered number does not exist")
          ? "Cannot fetch data! Check details."
          : "An error occured! Try again later.",
        "error"
      );
    }
  };
  return [
    getVerifyDetails,
    setVerify,
    verifyUtility,
    isLoading,
    isError,
    setError,
  ];
};

// 8212390917;
// 45701490778;
// 1059250698;
export const usePurchaseUtiliity = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const purchaseUtility = async (valuesObj) => {
    try {
      setSubmitting(true);
      dispatch(getTransactionStatus(true));

      const values = {
        ...valuesObj,
        product: "utility",
        amountToCredit: "none",
        walletToCredit: "none",
        walletToDebit: "nairaWallet",
        customerId: auth.currentUser.uid,
        // transRef: idempotentKey,
        deviceType: getDeviceType(),
      };

      const purchaseTrans = await axiosCall(
        url("purchase_utility"),
        values,
        true
      );

      // const purchaseTrans = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(values);
      //   }, 3000);
      // });

      // console.log(purchaseTrans);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      notification("danger", "An error occured! Try again later.", "error");
    }
  };
  return [isSubmitting, isSubmitted, purchaseUtility];
};

export const useSubmitSale = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const transId = createRefID(auth.currentUser.uid, "UTIL");

  const submitSale = async (values) => {
    setSubmitting(true);
    try {
      const valueFormat = {
        ...values,
        product: "utility",
        walletToCredit: "nairaWallet",
        walletToDebit: "none",
        amountToDebit: "none",
        transId,
        customerId: auth.currentUser.uid,
        deviceType: getDeviceType(),
      };

      const saleTrans = await axiosCall(
        url("send_convert_airtime"),
        valueFormat,
        true
      );

      // const saleTrans = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(valueFormat);
      //   }, 3000);
      // });

      // console.log(saleTrans);
      setSubmitting(false);
      setSubmitted(true);
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      notification("danger", "An error occured! Try again later.", "error");
    }
  };
  return [isSubmitting, isSubmitted, submitSale];
};
