import * as yup from "yup";
import mapValues from "lodash/mapValues";

const field = {
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required."),
  // .min(8, 'Password is too short - should be 8 chars minimum.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
};

export const loginValidationSchema = yup.object({
  email: field.email,
  password: field.password,
});

export const resetPassValidationSchema = yup.object({
  email: field.email,
});

export const registerValidationSchema = yup.object({
  displayName: yup
    .string("Enter your full name")
    .min(3, "Full Name should be of minimum 3 characters length")
    .required("Full Name is required"),
  phoneNumber: yup
    .string("Enter your phone number")
    .min(9, "Phone Number should be of minimum 3 characters length")
    .required("phone Number is required"),
  email: field.email,
  password: field.password,
});

export const displayNameSchema = yup.object({
  displayName: yup
    .string("Enter your full name")
    .min(3, "Name should be of minimum 3 characters length")
    .max(50, "Name should be of maximum 50 characters length")
    .required("Full Name is required"),
});

export const pinResetSchema = yup.object({
  password: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  pin: yup
    .string()
    .length(6, "Pin must 6 characters")
    .matches(/^[0-9]+$/, "Pin must contain only numeric")
    .required("Pin is required"),
  confirmPin: yup
    .string()
    .oneOf([yup.ref("pin"), null], "Pins must match")
    .required("Confirm Pin is required"),
});

export const changePhoneSchema = yup.object({
  code: yup.string().required("OTP is required"),
});

export const verifyPasswordSchema = yup.object({
  currentPass: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Current Password is required"),
});

export const changePasswordSchema = yup.object({
  newPass: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("New Password is required"),
  confirmNewPass: yup
    .string()
    .oneOf([yup.ref("newPass"), null], "Passwords must match")
    .required("Confirm Password is required"),
  code: yup.string().required("OTP is required"),
});

export const verifyEmailSchema = yup.object({
  newEmail: yup
    .string()
    .email("New Email must be a valid email")
    .required("New Email is required"),
  currentPass: yup
    .string()
    // .min(6, 'Password should be of minimum 6 characters length')
    .required("Password is required"),
});

export const changeEmailSchema = yup.object({
  code: yup.string().required("OTP is required"),
});

export const verifyPhoneSchema = yup.object({
  phone: yup
    .number("Invalid phone number")
    .required("Phone number is required"),
  currentPass: yup.string().required("Password is required"),
});

const qty = yup.lazy((obj) =>
  yup.object(
    mapValues(obj, (value, key) => {
      if (key === "count") {
        return yup.number().required();
      }
      if (key === "price") {
        return yup.number().required();
      }
      if (key === "rate") {
        return yup.number().required();
      }
    })
  )
);

export const tradeGiftcardSchema = yup.object({
  amountToCredit: yup.number().required(),
  amount: yup.number().required(),
  walletToCredit: yup
    .string()
    .oneOf(["nairaWallet", "cedisWallet", "cefaWallet"])
    .required(),
  amountToDebit: yup.string().oneOf(["none"]).required(),
  walletToDebit: yup.string().oneOf(["none"]).required(),
  comment: yup.string(),
  customerId: yup.string().min(10).max(128).required(),
  agentId: yup.number().nullable(),
  product: yup.string().oneOf(["giftcard"]).required(),
  productType: yup.string().min(3).required(),
  status: yup.string().oneOf(["pending"]).required(),
  // dateCreated: yup.number().required(),
  transaction: yup.object({
    card: yup.string().min(3).required(),
    country: yup.string().length(3).required(),
    qty: qty,
    total: yup.number().required(),
    tradeType: yup.string().oneOf(["sell"]).required(),
    type: yup.string().min(3).required(),
  }),
  images: yup.array().of(
    yup.object().shape({
      data_url: yup.string().required(),
      file: yup.mixed().required(),
    })
  ),
});

export const editGiftcardSchema = yup.object({
  amountToCredit: yup.number().required(),
  comment: yup.string(),
  productType: yup.string().min(3).required(),
  dateUploaded: yup.number().required(),
  transaction: yup.object({
    card: yup.string().min(3).required(),
    country: yup
      .string()
      .oneOf(["USD", "EUR", "GBP", "CAD", "AUD", "NZD"])
      .required(),
    qty: qty,
    total: yup.number().required(),
    tradeType: yup.string().oneOf(["sell"]).required(),
    type: yup.string().min(3).required(),
  }),
  images: yup.array().of(
    yup.object().shape({
      data_url: yup.string().required(),
      file: yup.mixed().required(),
    })
  ),
});
