import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_details: null,
  isLoading: true,
  isLoaded: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    getUserProfile: (state, { payload }) => {
      return payload;
    },
  },
});

export const { getUserProfile } = profileSlice.actions;

export default profileSlice.reducer;
