import React from "react";
import { Dialog, SimpleIcon } from ".";
import { useBreakpoint } from "utils";

export default function SuccessTransaction({ open, setOpen }) {
  const breakpoint = useBreakpoint();

  return (
    <Dialog
      {...{ open, setOpen }}
      classes={{
        paper: "!max-w-[300px]",
      }}
    >
      <div className="flex flex-col items-center justify-center rounded-sm border border-gray-200 bg-white p-4 text-center shadow-card-box">
        <div className="flex h-8 w-8 items-center justify-center rounded-sm bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
          <SimpleIcon
            icon="CheckTwoTone"
            frontColor="text-white"
            fontSize={24}
            otherClass=""
          />
        </div>
        <div className="mt-4">
          <h4 className="mb-1 text-lg font-semibold text-primary">Success</h4>
          <div className="text-sm">Transaction was successfully processed!</div>
        </div>
      </div>
    </Dialog>
  );
}
