import React, { useEffect } from "react";
import { Fade } from "react-reveal";
import Chip from "@mui/material/Chip";
import SelectAllIcon from "@mui/icons-material/SelectAll";

export default function Chips({
  displayContent,
  deleteContent,
  setOpenDrawer,
}) {
  const [chipData, setChipData] = React.useState(displayContent);

  useEffect(() => {
    setChipData(displayContent);
  }, [displayContent]);

  return (
    <Fade>
      <div className="rounded-sm bg-white p-2">
        {/* {chipData.length > 0 && ( */}
        <ul className="flex flex-wrap text-text-color">
          {chipData.map((data) => (
            <li key={data.id} className="m-1">
              <Chip
                label={data.label}
                onDelete={deleteContent(data.id)}
                classes={{
                  root: "num !text-text-color !bg-card !text-sm !rounded-sm !h-12",
                }}
              />
            </li>
          ))}
          <li className="m-1">
            <Chip
              label={
                <div className="flex items-center justify-between">
                  <span>
                    {!chipData.length
                      ? "Click to select card value"
                      : "Click to edit card value"}
                  </span>
                  <span>
                    <SelectAllIcon sx={{ fontSize: 20, marginLeft: 1 }} />
                  </span>
                </div>
              }
              classes={{
                root: "!text-text-color !bg-primary/20 !shadow-md !text-base !rounded-sm !h-12",
              }}
              onClick={() => setOpenDrawer(true)}
            />
          </li>
        </ul>
      </div>
    </Fade>
  );
}
