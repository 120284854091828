import React from "react";
import { classNames } from "utils";

export default function Container({ children, otherclass, disableFullHeight }) {
  return (
    <div
      className={classNames(
        disableFullHeight ? "" : "h-full",
        // "rounded-sm bg-card p-2 shadow-md",
        "rounded-sm border border-slate-200"
      )}
    >
      {/* <div
        className={classNames(
          otherclass || "",
          disableFullHeight ? "" : "h-full",
          "rounded-sm bg-white text-text-color"
        )}
      > */}
      {children}
      {/* </div> */}
    </div>
  );
}

export function SectionContent({ children, otherclass }) {
  return (
    <div
      className={classNames(
        "rounded-sm border border-[rgba(122,123,151,.3)] bg-white p-2 shadow-md",
        otherclass
      )}
    >
      {children}
    </div>
  );
}
