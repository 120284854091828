import React from 'react';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import { Button, Container } from 'components';
import { useNavigate } from 'react-router';
import { PageContainer } from 'shared';

export default function SuccessTransaction() {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <div className="m-auto w-full md:w-2/3 lg:w-1/2">
        {/* <Container> */}
        <div className="flex flex-col items-center justify-center rounded-sm border border-gray-200 bg-white p-6 text-center shadow-card-box">
          <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
            <CheckTwoToneIcon sx={{ fontSize: 36 }} className="iconShadow" />
          </div>
          <div className="my-4">
            <h4 className="text-base">
              Transaction was successfully processed!
            </h4>
          </div>
          <div className="w-full">
            <Button
              label="Back To Dashboard"
              fullWidth
              gradient
              handleClick={() => {
                navigate('/', { replace: true });
              }}
            />
          </div>
        </div>
        {/* </Container> */}
      </div>
    </PageContainer>
  );
}
