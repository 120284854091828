import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const Messanger = () => {
  const {
    auth,
    profile: { user_details },
  } = useSelector((state) => state);
  return (
    <>
      <Helmet>
        <script type="text/javascript" id="zsiqchat">
          {`var $zoho=$zoho || { };$zoho.salesiq = $zoho.salesiq || 
            {widgetcode: "cfd2e49c03c5aefe4a7a76b5b3f5122f4c372a4a5c96b8cd9fcb5dfd5876d938", 
            values:{ },
            ready:function(){ 
               
            }};
            var d=document;s=d.createElement("script");
            s.type="text/javascript";s.id="zsiqscript";
            s.defer=true;s.src="https://salesiq.zoho.com/widget";
            t=d.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(s,t);
          `}
        </script>
        <script>
          {` 
            $zoho.salesiq.ready=function(){
              $zoho.salesiq.visitor.name(${
                user_details ? JSON.stringify(user_details.displayName) : null
              }); 
                $zoho.salesiq.visitor.email(${
                  user_details ? JSON.stringify(user_details.email) : null
                });
                $zoho.salesiq.visitor.uniqueuserid(${
                  user_details ? JSON.stringify(user_details.email) : null
                });
               $zoho.salesiq.visitor.id(${
                 auth ? JSON.stringify(auth.currentUser.current_user) : null
               });
                $zoho.salesiq.visitor.contactnumber(${
                  user_details ? JSON.stringify(user_details.phoneNumber) : null
                });
            }
          `}
        </script>
      </Helmet>
    </>
  );
};

export default Messanger;
