import React from "react";
import { useSelector } from "react-redux";
import AccountDetails from "pages/Dashboard/sub-pages/AccountDetails";
import CreateAcctForm from "pages/Dashboard/sub-pages/CreateAcctForm";
import { useCreateDepositAcct, useGetDepositAcct } from "api";
import { BackdropSection, NoDisplay } from "components";

export default function FundFiat({ displayPage }) {
  const { depositAccount } = useSelector((state) => state?.account || {});

  const { depositAcct, isLoaded } = depositAccount || {};

  const [isSubmitting, isSubmitted, createDepositAcc, getUserAcct] =
    useCreateDepositAcct();

  useGetDepositAcct();

  if (!displayPage(`fundNaira`)) {
    return <NoDisplay type="funding" />;
  }

  return (
    <div className="drawer-content ">
      {isLoaded ? (
        <>
          {getUserAcct || depositAcct ? (
            <AccountDetails acct={getUserAcct || depositAcct} />
          ) : (
            <CreateAcctForm {...{ isSubmitting, createDepositAcc }} />
          )}
        </>
      ) : (
        <div className="relative h-20 bg-white">
          <BackdropSection open={true} />
        </div>
      )}
    </div>
  );
}
