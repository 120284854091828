import React from "react";
import { Link } from "react-router-dom";
import { ProductCard } from "components";
import { products } from "shared/constants";
import PortalSection from "./PortalSection";

export default function ProductUtility() {
  return (
    <div className="mt-9">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-6">
        <div className="col-span-6">
          <div className="mb-4 flex justify-between">
            <h2 className="text-sm font-bold tracking-wide text-gray-700 md:text-sm md:tracking-wider">
              Do more with Kaapo
            </h2>
            <Link
              to="/utilities"
              className="text-sm font-semibold uppercase text-color-main"
            >
              More
            </Link>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 xl:p-0">
            {products.map((product) => (
              <ProductCard product={product} key={product.title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
