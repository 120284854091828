import React, { useState, useEffect } from "react";

import { TextField } from "@mui/material";
import { Container, Button, BackPage, ImageUpload } from "components";

export default function UploadImage({
  handleStep,
  addImage,
  currentImageArr,
  currentComment,
}) {
  const [images, setImages] = useState([]);
  const [comment, setComment] = useState("");

  useEffect(() => {
    currentComment && setComment(currentComment);
    currentImageArr && setImages(currentImageArr);
  }, [currentComment, currentImageArr]);

  const handleImageChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  return (
    <div>
      <BackPage handleClick={handleStep} />

      <Container>
        <ImageUpload
          images={images}
          handleChange={handleImageChange}
          className="flex flex-wrap rounded bg-white p-2"
        />
      </Container>

      <div className="mt-4" />
      <Container>
        <div className="bg-white p-4">
          <div className="text-text-color">
            <p>Additional Comment.</p>
            <p className="text-sm text-slate-400">
              Enter notes E.G Enter code(s) here if you are trading ecode or if
              your card is not clear.
            </p>
            <div className="mt-4">
              <TextField
                fullWidth
                id="comment"
                label="Comment"
                multiline
                rows={2}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
      </Container>

      {images.length > 0 && (
        <>
          <div className="mt-4" />
          <Container>
            <Button
              label="Continue"
              fullWidth
              gradient
              handleClick={() => addImage({ images, comment })}
            />
          </Container>
        </>
      )}
    </div>
  );
}
