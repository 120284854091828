import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddCircleOutline, PriorityHigh } from "@mui/icons-material";

import { Button, IconBox, SimpleIcon } from "components";
import { AddAccount, RemoveAccount } from "../drawer-content/UpdateAccount";

export default function BankAccounts() {
  const { withdrawalAccount: bank_Account } = useSelector(
    (state) => state.account || {}
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [acctDelete, setAcctDelete] = useState(null);
  const [accountName, setAccountName] = useState(null);

  return (
    <div className="p-2 md:mt-2">
      <div className="relative">
        <div className="p-2">
          {bank_Account?.ownDetails?.length ? (
            <h5 className="mb-6">Own Accounts</h5>
          ) : null}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {bank_Account?.ownDetails?.length ? (
              bank_Account?.ownDetails?.map((item) => (
                <div className="col-span-1" key={item.id}>
                  <div className="relative top-0 h-full bg-transparent duration-300 ease-in hover:shadow-lg">
                    <div className="h-full rounded bg-primary/5">
                      <div className="flex flex-row items-start p-4">
                        <div className="mr-4 flex items-start">
                          <IconBox icon={"AccountBalanceOutlined"} gradient />
                        </div>

                        <div className="flex-1 text-sm">
                          <div className="flex w-full flex-row items-center justify-between">
                            <div className="text-sm font-bold tracking-wide">
                              {item.bankName}
                            </div>
                            <button
                              className="ml-2"
                              onClick={() => {
                                setAcctDelete(item);
                                setOpenPopup(true);
                                setAccountName("ownDetails");
                              }}
                            >
                              <SimpleIcon
                                icon="HighlightOff"
                                frontColor="text-red-500"
                              />
                            </button>
                          </div>
                          <div className="mt-1 text-sm text-gray-400">
                            <div>
                              {item.bankAccountNo} | {item.accountName}
                            </div>
                            {/* <div>{item.bankAccountNo}</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-2 flex flex-col items-center justify-center">
                <IconBox icon={"PriorityHigh"} gradient />
                <p className="mt-3 text-sm">No account details saved.</p>
              </div>
            )}
          </div>
          <div className="mt-6">
            <button
              className="relative h-full w-full rounded-lg border-2 border-dashed border-slate-200 py-4"
              onClick={() => setOpenDrawer(true)}
            >
              <div className="flex items-center justify-center">
                <SimpleIcon
                  icon="AddCircleOutline"
                  frontColor="text-slate-300"
                  fontSize={50}
                />
              </div>
            </button>
          </div>
        </div>
        {bank_Account?.beneficiaryDetails?.length ? (
          <>
            <hr className="my-8 h-[1px] w-full bg-slate-50/50" />
            <div className="p-2">
              <h5 className="mb-6">Beneficiary Accounts</h5>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {bank_Account?.beneficiaryDetails?.length &&
                  bank_Account?.beneficiaryDetails?.map((item) => (
                    <div className="col-span-1" key={item.id}>
                      <div className="relative top-0 h-full bg-transparent duration-300 ease-in hover:shadow-lg">
                        <div className="h-full rounded bg-primary/5">
                          <div className="flex flex-row items-start p-4">
                            <div className="mr-4 flex items-start">
                              <IconBox
                                icon={"AccountBalanceOutlined"}
                                gradient
                              />
                            </div>

                            <div className="flex-1 text-sm">
                              <div className="flex w-full flex-row items-center justify-between">
                                <div className="text-sm font-bold tracking-wide">
                                  {item.bankName}
                                </div>
                                <button
                                  className="ml-2"
                                  onClick={() => {
                                    setAcctDelete(item);
                                    setOpenPopup(true);
                                    setAccountName("beneficiaryDetails");
                                  }}
                                >
                                  <SimpleIcon
                                    icon="HighlightOff"
                                    frontColor="text-red-500"
                                  />
                                </button>
                              </div>
                              <div className="mt-1 text-sm text-gray-400">
                                <div>
                                  {item.bankAccountNo} | {item.accountName}
                                </div>
                                {/* <div>{item.bankAccountNo}</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        ) : null}
      </div>

      <AddAccount openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

      <RemoveAccount
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        accountDelete={acctDelete}
        accountsName={accountName}
      />
    </div>
  );
}
