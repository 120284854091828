import React, { useState, Fragment } from "react";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import "react-phone-input-2/lib/material.css";

import { Button, TextField } from "./";
import { classNames } from "utils";
import { replace, toUpper } from "lodash";

export default function Form({
  fieldList,
  initialFieldValues,
  buttonText,
  buttonLoading,
  handleSubmit,
  validationSchema,
  disableVisibilty,
  fullWidth,
  extraInfo,
  isDisabled,
  isDisabledFormFirstForm,
  disableAutoComplete,
  verifyType,
  displayReferralCode,
  setShowReferralInput,
}) {
  const [phone, setPhone] = useState("");
  const [phoneErrMsg, setPhoneErrMsg] = useState(true);

  const formik = useFormik({
    enableReintialize: true,
    initialValues: initialFieldValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (verifyType === "phoneVerify") {
        if (phoneErrMsg && Boolean(phone)) {
          handleSubmit(values, resetForm);
        }
      } else {
        handleSubmit(values, resetForm);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 gap-6">
        {fieldList.map((f) => (
          <Fragment key={f.name}>
            {f.inputType === "textfield" && (
              <div className="col-span-1" key={f.name}>
                <>
                  {f.helperText_alt && (
                    <div className="mb-3 text-xs text-gray-400">
                      {f.helperText_alt}
                    </div>
                  )}
                  <TextField
                    name={f.name}
                    type={f.type}
                    label={f.label}
                    value={formik.values?.[f.name]}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    touched={formik.touched?.[f.name]}
                    error={formik.errors?.[f.name]}
                    handleBlur={formik.handleBlur}
                    helperText={f.helperText}
                    {...f.otherProps}
                    placeholder={f.label}
                    disabled={isDisabled || isDisabledFormFirstForm}
                    disableAutoComplete={disableAutoComplete}
                  />
                </>
              </div>
            )}

            {f.inputType === "phonefield" && (
              <div className="col-span-1" key={f.name}>
                <>
                  <PhoneInput
                    country={"ng"}
                    value={phone}
                    onChange={(value, country, e, formattedValue) => {
                      const valueFormat = replace(
                        formattedValue,
                        /(^\+234\s+)(0*)/gi,
                        "+234 "
                      );

                      const valueFormat2 = replace(
                        value,
                        /(^234\s+)(0*)/gi,
                        "234"
                      );

                      setPhone(valueFormat);

                      formik.handleChange({
                        target: { value: valueFormat2, name: f.name },
                      });
                    }}
                    disabled={isDisabled || isDisabledFormFirstForm}
                    inputStyle={{
                      width: "100%",
                      borderRadius: 4,
                      padding: "16px 12px 16px 58px",
                    }}
                    inputClass={classNames(
                      (formik.error?.[f.name] && formik.touched?.[f.name]) ||
                        (!phoneErrMsg && Boolean(phone))
                        ? "!border-red-500 focus:!border-red-500"
                        : "!border-slate-200",
                      "border focus:!shadow-none !text-sm !bg-transparent"
                    )}
                    containerClass={classNames(
                      formik.error?.[f.name] || (!phoneErrMsg && Boolean(phone))
                        ? "!text-red-500"
                        : "",
                      "bg-transparent"
                    )}
                    isValid={(value, country) => {
                      const countryCode = toUpper(country.iso2);
                      const valid =
                        isValidPhoneNumber(value, countryCode) &&
                        isPossiblePhoneNumber(value, countryCode);

                      setPhoneErrMsg(valid);
                      return valid;
                    }}
                  />
                  {((!phoneErrMsg && Boolean(phone)) ||
                    formik.error?.[f.name]) && (
                    <div className="mt-3 text-xs text-red-600">
                      {formik.error?.[f.name] ? (
                        <>Phone number is required</>
                      ) : (
                        <>Invalid phone number</>
                      )}
                    </div>
                  )}
                </>
              </div>
            )}

            {f.inputType === "referralfield" && (
              <>
                {!displayReferralCode ? (
                  <button onClick={() => setShowReferralInput(true)}>
                    <div className="text-left text-sm text-primary underline underline-offset-1">
                      I have a referral code
                    </div>
                  </button>
                ) : (
                  <TextField
                    name={f.name}
                    type={f.type}
                    label={f.label}
                    value={formik.values?.[f.name]}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    touched={formik.touched?.[f.name]}
                    error={formik.errors?.[f.name]}
                    handleBlur={formik.handleBlur}
                    helperText={f.helperText}
                    {...f.otherProps}
                    placeholder={f.label}
                    disabled={isDisabled || isDisabledFormFirstForm}
                    disableAutoComplete={disableAutoComplete}
                  />
                )}
              </>
            )}
          </Fragment>
        ))}

        {extraInfo && extraInfo}
        {!disableVisibilty && (
          <div className="col-span-1">
            <Button
              type="submit"
              label={buttonText}
              disabled={buttonLoading}
              isSubmitting={buttonLoading}
              fullWidth={fullWidth}
              gradient
            />
          </div>
        )}
      </div>
    </form>
  );
}
