import { useEffect, useState } from "react";
import { axiosCall } from "../helpers";
import { notification, createRefID, getDeviceType } from "utils";
// import { useNavigate } from 'react-router';

import * as i from "../lib";
import { getTransactionStatus } from "redux/reducers/transactionReducers";
import { useNavigate } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}/fiat/${endpoint}`;
const transRef = (uid, type) => createRefID(uid, type);

export const useBankList = (label) => {
  const [getBankList, setBankList] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getBankList = async () => {
      if (label === "Other account") {
        setLoading(true);
        try {
          const resolved = await axiosCall(url("get_banks"), {});

          const resolvedMapped = resolved?.data?.data?.map((item) => ({
            ...item,
            bankName: item.name,
            bankCode: item.code,
          }));

          setBankList(resolvedMapped);
          setLoading(false);
        } catch (error) {
          setError(true);
          setLoading(false);
        }
      }
    };
    getBankList();
    return () => {};
  }, [label]);
  return [getBankList, isLoading, error];
};

export const useVerifyAccountNum = (params) => {
  const [verifyAcctName, setVerifyAcctName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorVerify, setErrorVerify] = useState(null);

  const { selectedBank, accountNum } = params;

  const verifyAccountNum = async () => {
    setVerifyAcctName(null);
    try {
      if (accountNum.length >= 10 && selectedBank) {
        setIsLoading(true);

        const val = {
          accountNumber: accountNum,
          bankCode:
            selectedBank?.[0]?.code ||
            selectedBank?.[0]?.bankCode ||
            selectedBank?.code ||
            selectedBank?.bankCode,
        };

        const resp = await axiosCall(url("validate_bank"), val);
        const resolvedData = resp?.data;

        const {
          accountNumber: bankAccountNo,
          accountName,
          bank,
          bankCode: code,
        } = resolvedData?.data;

        const bankName =
          bank?.name || selectedBank?.bankName || selectedBank?.name;
        const bankCode = bank?.code || code;

        setVerifyAcctName({ bankAccountNo, accountName, bankName, bankCode });
        setIsLoading(false);
        setErrorVerify(false);
      } else {
        setVerifyAcctName(null);
        setIsLoading(false);
        setErrorVerify(null);
      }
    } catch (error) {
      // console.log(error);

      setIsLoading(false);
      setErrorVerify(true);
    }
  };

  return [
    verifyAcctName,
    setVerifyAcctName,
    isLoading,
    errorVerify,
    setErrorVerify,
    verifyAccountNum,
  ];
};

export const useWithdrawFundNaira = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const {
    account: { withdrawalAccount },
  } = useStore().getState();

  const allDetails = Object.values(withdrawalAccount || {}).reduce(
    (acc, item) => {
      acc.push(...item);
      return acc;
    },
    []
  );

  const idempotentKey = transRef(auth.currentUser?.uid, "FIAT_WITHD");

  const submitWithdraw = async (args) => {
    const { values, code, saveBene } = args;

    const acc = values.withdrawDetails.accountDetails;

    const saveBeneExist = allDetails.find(
      (item) =>
        item.accountName === acc.accountName &&
        item.bankAccountNo === acc.bankAccountNo &&
        item.bankName === acc.bankName
    );

    if (saveBeneExist && saveBene) {
      i.notification("warning", "Beneficiary already saved.");
      return;
    }

    try {
      dispatch(getTransactionStatus(true));
      setSubmitting(true);

      const valueFormat = {
        ...values,
        customerId: auth.currentUser.uid,
        amountToCredit: "none",
        walletToCredit: "none",
        walletToDebit: "nairaWallet",
        product: "payout",
        deviceType: getDeviceType(),
        transRef: idempotentKey,
      };

      const getIdempotentKey = await i.getDoc(
        i.doc(i.db, "withdrawalIdempotencyKeys", auth?.currentUser?.uid)
      );

      if (getIdempotentKey.exists()) {
        // eslint-disable-next-line no-throw-literal
        throw "Transaction ID already exist";
      }

      await i.setDoc(
        i.doc(i.db, "withdrawalIdempotencyKeys", auth?.currentUser?.uid),
        {
          idempotentKey: idempotentKey,
        }
      );

      const withdrawTrans = await axiosCall(
        url("withdraw_naira"),
        {
          transData: valueFormat,
          code,
          saveBene,
        },
        true,
        idempotentKey
      );

      // const withdrawTrans = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve({ transData: valueFormat, code, saveBene });
      //   }, 5000);
      // });

      await i.deleteDoc(
        i.doc(i.db, "withdrawalIdempotencyKeys", auth?.currentUser?.uid)
      );

      // console.log(withdrawTrans);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      await i.deleteDoc(
        i.doc(i.db, "withdrawalIdempotencyKeys", auth?.currentUser?.uid)
      );
      // console.log(error, "eroororoor");
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      let message;
      switch (error) {
        case "Transaction ID already exist":
          message = error;
          break;
        case "Already have an active transaction":
          message = error;
          break;
        case "Transaction ID does not exist":
          message = error;
          break;

        default:
          message = "An error occured! Try again later.";
          break;
      }
      i.notification("danger", message, "error");
    }
  };

  return [isSubmitting, isSubmitted, submitWithdraw];
};

export const useWithdrawFundOther = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const submitWithdraw = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      amountToCredit: "none",
      walletToCredit: "none",
      product: "payout",
      deviceType: getDeviceType(),
      transRef: transRef(auth.currentUser?.uid, "FIAT_WITHD"),
    };

    try {
      dispatch(getTransactionStatus(true));
      setSubmitting(true);

      const withdrawTrans = await axiosCall(
        url("withdraw_fiat"),
        valueFormat,
        true
      );

      // const withdrawTrans = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(valueFormat);
      //   }, 3000);
      // });

      // console.log(withdrawTrans);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      i.notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [isSubmitting, isSubmitted, submitWithdraw];
};

export const useTransferFund = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const submitTransfer = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      amountToCredit: "none",
      walletToCredit: "none",
      product: "transfer-fiat",
      transRef: transRef(auth.currentUser?.uid, "ESET_TRNSTOUSER"),
      deviceType: getDeviceType(),
    };

    try {
      dispatch(getTransactionStatus(true));
      setSubmitting(true);

      const trans = await axiosCall(url("transfer_fiat"), valueFormat, true);

      // const trans = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(valueFormat);
      //   }, 2000);
      // });

      // console.log(trans);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      i.notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [isSubmitting, isSubmitted, submitTransfer];
};

export const useBonusTransfer = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const bonusTransfer = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      product: "transfer-bonus",
      transRef: transRef(auth.currentUser?.uid, "TRNST_BONUS"),
      deviceType: getDeviceType(),
    };

    try {
      dispatch(getTransactionStatus(true));
      setSubmitting(true);

      const trans = await axiosCall(
        url("transfer_bonus_fiat"),
        valueFormat,
        true
      );

      // const trans = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(valueFormat);
      //   }, 20000);
      // });

      // console.log(trans);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      i.notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [isSubmitting, isSubmitted, bonusTransfer];
};

export const useConvertFund = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const convertFund = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      product: "convert-fiat",
      transRef: transRef(auth.currentUser?.uid, "CONVT_FIAT"),
      deviceType: getDeviceType(),
    };

    try {
      dispatch(getTransactionStatus(true));
      setSubmitting(true);

      const trans = await axiosCall(url("convert_fiat"), valueFormat, true);

      // const trans = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(valueFormat);
      //   }, 5000);
      // });

      // console.log(trans);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      i.notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [isSubmitting, isSubmitted, convertFund];
};
