import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Divider } from "@mui/material";
import { Button, IconBox, Drawer, SimpleTable, SimpleIcon } from "components";
import { securitActions } from "shared/constants";
import CreatePin from "../drawer-content/CreatePin";
import ChangePass from "../drawer-content/ChangePass";
import ChangePhone from "../drawer-content/ChangePhone";
import TwoFactor from "../drawer-content/TwoFactor";
import { classNames, formatDate } from "utils";
import { capitalize } from "lodash";

const twofactorList = [
  {
    name: (
      <>
        SMS
        <br />
        Authenticator
      </>
    ),
    id: "phoneAuthentication",
    iconName: "Textsms",
    title: "Text Message 2FA",
  },
  {
    name: (
      <>
        Email
        <br />
        Authenticator
      </>
    ),
    id: "emailAuthentication",
    iconName: "Email",
    title: "Email Authenticator 2FA",
  },
  // {
  //   name: (
  //     <>
  //       Google
  //       <br />
  //       Authenticator
  //     </>
  //   ),
  //   id: "appAuthentication",
  //   iconName: "Google",
  //   title: "Google Authenticator 2FA",
  // },
];

export default function Security() {
  const [openDrawer, setDrawer] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);

  const {
    user_details: { recentDevices, twoFactorAuth },
  } = useSelector((state) => state?.profile);

  const { enable2factorAuth, factorType } = twoFactorAuth || {};

  const contents = {
    changePin: CreatePin,
    changePassword: ChangePass,
    changePhoneNumber: ChangePhone,
    phoneAuthentication: TwoFactor,
    emailAuthentication: TwoFactor,
    appAuthentication: TwoFactor,
  };

  const title =
    [...securitActions, ...twofactorList]?.find((i) => i.id === currentContent)
      ?.title || null;
  const pageId =
    [...securitActions, ...twofactorList]?.find((i) => i.id === currentContent)
      ?.id || null;
  const Content = contents[currentContent] || null;

  return (
    <div className="p-2 md:mt-2 md:p-2">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {securitActions.map((item, index) => (
          <div className="col-span-1" key={item.title}>
            <button
              onClick={() => {
                setDrawer(true);
                setCurrentContent(item.id);
              }}
              className="h-full w-full text-left"
            >
              <div className="relative top-0 flex h-full flex-col items-start justify-between rounded bg-primary/5 p-4 duration-500 ease-in hover:shadow-lg">
                <div className="flex items-start justify-start">
                  <div className="mr-4 flex items-start justify-between">
                    <IconBox icon={item.Icon} gradient />
                  </div>
                  {/* font-bold  */}
                  <div className="text-sm">
                    <div className="flex flex-col">
                      <div className={`font-bold text-color-main`}>
                        {item.title}
                      </div>
                    </div>
                    <div className="mt-1 text-gray-500 md:block">
                      {item.subtitle_alt}
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>

      <Divider classes={{ root: "!my-8 !-mx-4" }} />

      <div>
        <h5 className="mb-6">Two-factor Authentication</h5>
        <div className="text-center">
          <div className="mb-4 font-bold">
            Keep your account secure with authentication step.
          </div>

          <div className="m-auto w-full text-sm text-gray-400 md:w-2/3">
            Two-factor authentication adds an additional layer of security to
            your account by requiring more than just a password to log in.
          </div>
          <div className="action mt-4 flex flex-row flex-wrap items-center justify-center gap-4">
            {twofactorList.map((item) => {
              const is2faEnabled = enable2factorAuth && factorType === item.id;

              return (
                <div className="flex flex-col items-center justify-center">
                  {is2faEnabled && (
                    <div className="mb-2 flex h-4 w-4 items-center justify-center rounded-full bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
                      <SimpleIcon
                        icon="Check"
                        fontSize={12}
                        frontColor="text-white"
                      />
                    </div>
                  )}

                  <button
                    className=""
                    onClick={() => {
                      setDrawer(true);
                      setCurrentContent(item.id);
                    }}
                  >
                    <div
                      className={classNames(
                        "flex h-full w-28 flex-col items-center justify-center rounded border border-b border-slate-200 p-2",
                        is2faEnabled
                          ? "bg-gradient-to-br from-button_gradient_from to-button_gradient_to"
                          : "bg-dark/5"
                      )}
                    >
                      <div
                        className={classNames(
                          "flex h-7 w-7 items-center justify-center rounded-full shadow",
                          is2faEnabled ? "bg-white" : "bg-primary"
                        )}
                      >
                        <SimpleIcon
                          icon={item.iconName}
                          fontSize={16}
                          frontColor={
                            is2faEnabled ? "text-primary" : "text-white"
                          }
                          otherClass="iconShadow-alt"
                        />
                      </div>
                      <div className={classNames("mt-4 max-w-3xl flex-1")}>
                        <div
                          className={classNames(
                            "text-sm font-semibold",
                            is2faEnabled ? "text-white" : ""
                          )}
                        >
                          {item.name}
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Divider classes={{ root: "!my-8 !-mx-4" }} />

      <div>
        <h5 className="mb-6">Recent devices</h5>
        <div className="rounded bg-slate-50 p-2">
          <SimpleTable
            columns={[
              { label: "Client", id: "client" },
              { label: "Device", id: "device" },
              { label: "OS", id: "os" },
              { label: "IP Address", id: "ipAddress" },
              { label: "Logged In", id: "time" },
            ]}
            rows={recentDevices?.map((i) => ({
              client: i?.client?.name?.split("/")[0],
              device: `${capitalize(i?.device?.type)} ${
                Boolean(i?.device?.brand) ? i?.device?.brand : ""
              }`,
              os: `${i?.os?.name?.split("/")[0]} ${
                i?.os?.name?.split("/")[1] ?? ""
              } ${i?.os?.version}`,
              ipAddress: `${i?.ipAddress ?? "unknown"}`,
              time: formatDate(i.time),
            }))}
          />
        </div>
      </div>

      <Drawer
        anchor="right"
        state={openDrawer}
        setState={setDrawer}
        title={title}
      >
        <div className="drawer-content">
          {Content && <Content setDrawer={setDrawer} pageId={pageId} />}
        </div>
      </Drawer>
    </div>
  );
}
