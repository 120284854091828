import { omit } from "lodash";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  classNames,
  formatInputNum,
  formatMoney,
  formatMoneyNoDec,
  symToCur,
} from "utils";

export const giftcardPriceCalc = (qty, rate, giftcard_rate_fac) => {
  if (giftcard_rate_fac) {
    const f = giftcard_rate_fac;
    // const sum = f.exchangeFiatRate * parseFloat(rate);
    const sum = f.exchangeRate?.["NGN"] * parseFloat(rate);
    const rateBVat = sum - f.commission;
    const vat = f.commission * (f.vatRate / 100);
    const actRate = rateBVat - vat;
    let fRate;

    switch (f.defaultCurrency) {
      case "naira":
        fRate = actRate;
        break;
      case "cedis":
        fRate = actRate * f.exchangeRate?.["GHS"];
        break;
      case "cefa":
        fRate = actRate * f.exchangeRate?.["XOF"];
        break;
      default:
        fRate = actRate;
        break;
    }

    // const fRate = Math.round(actRate / 5) * 5;
    return {
      rate: Math.round(fRate),
      price: Math.round(fRate) * parseInt(qty),
    };
  }
  return null;
};

export const calcTot = (qty) =>
  Object.entries(qty).reduce((acc, item) => {
    acc += parseInt(item[1].price) * parseInt(item[1].count);
    return acc;
  }, 0);

export const calculateQty = (args, isEdit) => {
  const { qty, type, getRateFac, cardRates, state, setState } = args;
  const rate = cardRates[qty];
  const calRate = giftcardPriceCalc(qty, rate, getRateFac);

  const computeVal = () => {
    let counter = state?.[qty] ? state?.[qty]?.count : 0;

    if (type === "add") {
      counter = counter += 1;
      return counter >= 10 ? 10 : counter;
    } else if (type === "remove") {
      counter = counter -= 1;
      if (isEdit) {
        return counter <= 0
          ? setState((prevS) => ({
              ...prevS,
              qty: omit(prevS.qty, qty),
            }))
          : counter;
      } else {
        return counter <= 0 ? setState((prevS) => omit(prevS, qty)) : counter;
      }
    }
  };

  setState((prevS) => {
    const qtyT = {
      ...prevS.qty,
      [qty]: {
        price: calRate?.price,
        rate: calRate?.rate,
        count: computeVal(),
      },
    };
    return {
      ...prevS,
      ...(!isEdit
        ? {
            [qty]: {
              price: calRate?.price,
              rate: calRate?.rate,
              count: computeVal(),
            },
          }
        : { qty: qtyT }),
    };
  });
};

export const displayQtyContent = (qty, cardType, defaultFiat) =>
  Object.entries(qty).reduce((acc, item) => {
    const t = item[0] * item[1].count;
    acc.push({
      id: item[0],
      label: (
        <>
          {symToCur(cardType)}
          {item[0]} * {item[1].count} @{" "}
          {formatMoney(item[1].price, defaultFiat, 0)}
        </>
      ),
    });
    return acc;
  }, []);

export const ValueButton = ({ handleClick, disabled, Icon, size }) => {
  return (
    <button
      className={classNames(
        size
          ? "h-5 w-5 bg-color-main !text-white"
          : "h-6 w-6 bg-card from-button_gradient_from to-button_gradient_to text-color-main  shadow hover:bg-gradient-to-br hover:text-white",
        "flex items-center justify-center rounded"
      )}
      onClick={handleClick}
      disabled={disabled}
    >
      <Icon {...(size && { sx: { fontSize: 12, color: "#fff" } })} />
    </button>
  );
};

export const QtyContent = ({ editList, increaseQty }) => {
  const { qty } = editList;
  const sym = symToCur(editList?.country);

  return (
    <ul className="my-3 mb-6 list-disc space-x-2 space-y-2">
      {Object.entries(qty || {}).map((i) => (
        <li key={i[0]} className="inline-flex rounded bg-slate-200 p-2">
          <div className="flex items-center">
            <div>
              {sym}
              {i[0]} x {i[1].count} @ {formatMoneyNoDec(i[1].price)}
            </div>
            <div className="mx-2 flex space-x-1">
              <ValueButton
                Icon={RemoveIcon}
                handleClick={() => {
                  increaseQty(i[0], "remove");
                }}
                size
                // disabled={qty?.i?.[0]?.count <= 0 || !qty?.i?.[0]?.count}
              />

              <ValueButton
                Icon={AddIcon}
                handleClick={() => {
                  increaseQty(i[0], "add");
                }}
                size
                disabled={qty?.[i?.[0]]?.count >= 10}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const symbolToCur = (cur) => symToCur(cur);

export const optionFuncs = (
  giftcard_rate,
  editList,
  selCard,
  getWRate,
  sym
) => ({
  productTypeOpt: giftcard_rate?.map((i) => ({
    title: i.title,
    name: i.name,
    id: i.id,
  })),

  currOpt: Object?.keys(selCard ?? [])?.map((i) => ({ name: i })),

  typeOpt: Object?.keys(selCard?.[editList?.["country"]] ?? [])?.map((i) => ({
    name: i,
  })),

  rateOpt: Object.entries(
    selCard?.[editList?.["country"]]?.[editList?.["type"]] ?? []
  )?.map((i) => {
    const wR = getWRate(i[0], i[1]);
    return {
      id: i[0],
      qty: i[0],
      rate: wR?.rate,
      price: wR?.price,
      rateFomat: `${sym}${i[0]} @ ${wR?.rate}/${sym}`,
      priceFormat: `₦${formatInputNum(wR?.price)}`,
    };
  }),
});
