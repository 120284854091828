import React from "react";
import Fade from "react-reveal/Fade";
import { Container, BackPage, CurrentData } from "components";

export default function SelectType({
  cardType,
  handleStep,
  addType,
  currentType,
}) {
  return (
    <div className="">
      <BackPage handleClick={handleStep} />

      <Container>
        <div className="flex flex-wrap gap-3 rounded-sm bg-white p-3">
          {cardType?.map((type) => (
            <Fade key={type}>
              <button
                className="relative flex w-20 scale-100 flex-col items-center justify-between rounded-sm bg-card p-1 text-center duration-300 ease-in hover:scale-105 hover:shadow-2xl"
                onClick={() => addType(type)}
              >
                <img
                  src={require("../../../assets/images/card-type.svg").default}
                  alt="card-type"
                  className="flag-icon w-full"
                />
                <div className="leading-2 p-1 text-center text-sm font-normal text-text-color">
                  {type}
                </div>

                <CurrentData currData={currentType} listData={type} />
              </button>
            </Fade>
          ))}
        </div>
      </Container>
    </div>
  );
}
