import React from "react";
import { Fade } from "react-reveal";
import { IconBox } from "components";
import { formatMoney } from "utils";
import { useSelector } from "react-redux";

export default function WalletBalance({ amount, sym = "NGN" }) {
  const { nairaWallet } = useSelector((state) => state.account || {});

  const amt = amount || nairaWallet?.amount;

  return (
    <Fade>
      <div className="col-span-1 md:col-span-1">
        <div className="rounded-sm border border-gray-200 bg-white p-4 shadow-card-box">
          <div className="flex items-center justify-between">
            <div>
              <h4 className="text-lg font-bold text-color-main">
                {formatMoney(amt || 0, sym)}
              </h4>
              <div className="mt-0 text-xs text-gray-400">Wallet Balance</div>
            </div>

            <IconBox icon={"AccountBalanceWalletOutlined"} gradient />
          </div>
        </div>
      </div>
    </Fade>
  );
}
