import { ImageGallery, SimpleIcon } from "components";
import { capitalize, startCase, toUpper } from "lodash";
import {
  formatCurrency,
  formatDate,
  formatInputNum,
  formatMoney,
  formatMoneyNoDec,
  symToCur,
} from "utils";
import { tradeActions } from "shared/constants";

const tradeStatus = {
  approved: {
    bg: "bg-emerald-100",
    text: "text-white",
    color: "text-emerald-500",
    icon: "CheckCircleOutlined",
  },
  declined: {
    bg: "bg-red-100",
    text: "text-white",
    color: "text-red-500",
    icon: "CancelOutlined",
  },

  pending: {
    bg: "bg-amber-100",
    text: "text-white",
    color: "text-amber-500",
    icon: "PendingOutlined",
  },
  active: {
    bg: "bg-cyan-100",
    text: "text-white",
    color: "text-cyan-500",
    icon: "PendingOutlined",
  },
};

const StatusObj = ({ type }) => {
  const { color, bg } = tradeStatus?.[type] || {};
  return (
    <div className={`flex items-center rounded-sm p-1  ${bg}`}>
      <span className={`text-xs ${color}`}>{type}</span>
    </div>
  );
};

const StatusIcon = ({ type }) => {
  const { color, bg, icon } = tradeStatus?.[type] || {};
  return (
    <div
      className={`flex h-9 w-9 flex-row items-center justify-center rounded-full ${bg}`}
    >
      <SimpleIcon icon={icon} frontColor={color} fontSize={18} otherClass="" />
    </div>
  );
};

const WalletBalance = ({ balance }) => {
  const cryptos = {
    tetherWallet: { sym: "USDT", type: "crypto", no: 7 },
    binanceWallet: { sym: "BNB", type: "crypto", no: 6 },
    bitcoinWallet: { sym: "BTC", type: "crypto", no: 4 },
    ethereumWallet: { sym: "ETH", type: "crypto", no: 5 },
    tetherTronWallet: { sym: "USDT_TRON", type: "crypto", no: 8 },
    nairaWallet: { sym: "NGN", type: "fiat", no: 1 },
    cedisWallet: { sym: "GHS", type: "fiat", no: 2 },
    cefaWallet: { sym: "XOF", type: "fiat", no: 3 },
  };

  return (
    <div className="p-2">
      {Object.entries(balance)
        ?.sort((b, c) => {
          return cryptos?.[c[0]].no < cryptos?.[b[0]].no ? 1 : -1;
        })
        ?.map((item, index) => (
          <div className="flex justify-between py-2" key={index}>
            <p className="text-sm text-gray-400">{startCase(item[0])}</p>
            <p className="text-sm text-gray-400">
              {formatInputNum(item[1], 2)} {cryptos?.[item[0]].sym}
            </p>
          </div>
        ))}
    </div>
  );
};

const Amount = ({ amount, walletType }) => {
  const sym = {
    nairaWallet: "NGN",
    nairaBonusWallet: "NGN",
    cedisWallet: "GHS",
    cedisBonusWallet: "GHS",
    cefaWallet: "XOF",
    cefaBonusWallet: "XOF",
  };

  return (
    <span className="">
      {formatInputNum(amount, 2)} {sym?.[walletType]}
    </span>
  );
};

export function WithdrawFiat() {
  const columns = [
    {
      field: "nos",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        const { status } = params?.row;
        return <StatusIcon type={status} />;
      },
    },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },

    {
      field: "product",
      headerName: "Product",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "beneficiary",
      headerName: "Beneficiary Details",
      width: 200,
      renderCell: (params) => (
        <span>{params?.value?.accountName || params?.value?.momoName}</span>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return (
      arr
        // ?.sort((b, c) => c.dateCreated - b.dateCreated)
        ?.map((item, index) => ({
          nos: index + 1,
          id: item.id,
          idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
          status: item.status,
          product: item.product,
          productType: item.productType,
          amount: item.totalAmount,
          beneficiary: item?.withdrawDetails?.accountDetails,
          date: item.dateCreated,
          amountToDebit: item.amountToDebit,
          walletToDebit: item.walletToDebit,
        }))
    );
  };

  const summary = (item) => {
    const { withdrawDetails, totalAmount, dateCreated } = item || {};
    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionID: {
            value: item?.transId,
            type: "text",
            clickable: true,
          },

          // transactionRef: {
          //   value: item?.transRef,
          //   type: "text",
          //   clickable: true,
          // },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          charges: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.charges,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          amount: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.amount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          beneficiaryMomoName: {
            value: withdrawDetails?.accountDetails?.momoName,
            type: "text",
          },

          beneficiaryMomoNumber: {
            value: withdrawDetails?.accountDetails?.momoNumber,
            type: "text",
          },
          beneficiaryMomoType: {
            value: withdrawDetails?.accountDetails?.momoType,
            type: "text",
          },

          beneficiaryName: {
            value: withdrawDetails?.accountDetails?.accountName,
            type: "text",
          },
          beneficiaryBank: {
            value: withdrawDetails?.accountDetails?.bankName,
            type: "text",
          },
          beneficiaryAccountNo: {
            value: withdrawDetails?.accountDetails?.bankAccountNo,
            type: "text",
          },
          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return {
    columns,
    rows,
    summary,
    pending_row: rows,
    pending_summary: summary,
  };
}

export function Giftcards() {
  const columns = [
    {
      field: "nos",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        const { status } = params?.row;
        return <StatusIcon type={status} />;
      },
    },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "productType",
      headerName: "Product",
      width: 150,
      renderCell: (params) => {
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 180,
      renderCell: (params) => {
        const par = params.row;
        return (
          <span className="">
            {formatMoney(params.value)} ({par.amountUSD})
          </span>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      renderCell: (params) => <span>{capitalize(params.value)}</span>,
    },
    {
      field: "date",
      headerName: "Date",
      width: 220,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    const amtUSD = (qty) =>
      qty
        ? Object?.entries(qty)?.reduce((acc, item) => {
            acc += item[0] * item[1].count;
            return acc;
          }, 0)
        : 0;

    const getUSDAmt = (arr, curr) => {
      const dd = amtUSD(arr);
      return formatCurrency(dd || 0, symToCur(curr), 0);
    };

    return (
      arr
        // ?.sort((b, c) => c.dateCreated - b.dateCreated)
        ?.map((item, index) => ({
          nos: index + 1,
          id: item.id,
          idNos: `KPO|${toUpper(item.id.slice(0, 7))}`,
          status: item.status,
          product: item.product,
          productType: item?.productType,
          amount: item?.transaction?.total,
          amountUSD: getUSDAmt(
            item?.transaction?.qty,
            item?.transaction?.country
          ),

          type: item?.transaction?.tradeType,
          date: item.dateCreated,
        }))
    );
  };

  const summary = (item, otherProps) => {
    const {
      product,
      productType,
      transaction,
      status,
      declineReason,
      dateCreated,
      comment,
      transactionUpload,
      declineConfirmationUpload,
    } = item || {};

    const { type, tradeType, total, country, qty } = transaction || {};

    const { msg, tradeStatus } = tradeActions;
    const { giftcardDeclineReasons: reason } = otherProps || {};

    return item
      ? {
          status: {
            value: (
              <div
                className={`rounded ${tradeStatus[status].color} w-fit p-1 text-xs text-white`}
              >
                {status}
              </div>
            ),
            type: "component",
          },
          dateCreated: { value: formatDate(dateCreated), type: "text" },
          transactionId: {
            value: item?.transId,
            type: "text",
            clickable: true,
          },

          message: {
            value: msg[status],
            type: "text",
          },
          reasonForDecline: {
            value: (
              <>
                {startCase(declineReason)}.
                <br />
                {reason?.[declineReason]}
              </>
            ),
            type: "component",
            disableDisplay: !(status === "declined"),
          },
          product: { value: capitalize(product), type: "text" },
          productType: { value: capitalize(productType), type: "text" },
          cardType: { value: capitalize(type), type: "text" },
          transactionType: { value: capitalize(tradeType), type: "text" },

          transactionTotal: {
            value: (
              <Amount
                {...{
                  amount: total,
                  walletType: item?.walletToCredit,
                }}
              />
            ),
          },
          cardQty: {
            value: (
              <>
                {qty
                  ? Object?.entries(qty)?.map((i) => (
                      <p key={i[0]}>
                        {symToCur(country)}
                        {i[0]} x {i[1].count} @
                        <Amount
                          {...{
                            amount: i[1].price,
                            walletType: item?.walletToCredit,
                          }}
                        />{" "}
                        - ({i[1].rate}/{symToCur(country)})
                      </p>
                    ))
                  : null}
              </>
            ),
            type: "component",
          },
          cardCurrency: { value: country, type: "text" },
          comment: {
            value: <>{comment === "" ? "No Comment" : comment}</>,
            type: "component",
          },
          transactionUpload: {
            value: (
              <ImageGallery
                images={transactionUpload}
                type={["view"]}
                className="flex space-x-4 p-3"
              />
            ),
            type: "component",
          },
          declineConfirmationUpload: {
            value: (
              <ImageGallery
                images={declineConfirmationUpload}
                type={["view"]}
                className="flex space-x-4 p-3"
              />
            ),
            type: "component",
            disableDisplay: !(status === "declined"),
          },
        }
      : {};
  };

  return {
    columns,
    rows,
    summary,
    pending_row: rows,
    pending_summary: summary,
  };
}

export function FundFiat() {
  const columns = [
    {
      field: "nos",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        const { status } = params?.row;
        return <StatusIcon type={status} />;
      },
    },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return <span className="">{formatMoney(params.value)}</span>;
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{params.value}</span>;
      },
    },

    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: "Fiat",
      assetType: item.productType?.split("-")[0],
      action: item.productType,
      amount: item.settlementAmount,
      date: item.dateCreated,
    }));
  };

  const summary = (item) => {
    const { accountDetails, dateCreated } = item || {};
    const { accountName, accountNumber, bankName } = accountDetails || {};
    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.transactionReference,
            type: "text",
            clickable: true,
          },
          totalAmount: {
            value: formatMoney(item?.totalAmountDeposit),
          },
          totalAmountFunded: {
            value: formatMoney(item?.settlementAmount),
          },
          charges: {
            value: formatMoney(item?.charges),
          },

          bankDetails: {
            value: `${accountName}/${accountNumber}/${bankName}`,
          },
          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function TransferFiat(current_user) {
  const columns = [
    {
      field: "nos",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        const { status } = params?.row;
        return <StatusIcon type={status} />;
      },
    },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: "Fiat",
      // assetType: item.productType?.split("-")[1],
      assetType: "naira",
      action: item.productType,
      amount: item.totalAmount,
      date: item.dateCreated,
      walletToDebit: item.walletToDebit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          charges: {
            value: (
              <Amount
                {...{
                  amount: 0,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          ...(current_user === item.customerId && {
            transferTo: {
              value: item?.transferDetails?.userTo?.email,
            },
          }),

          ...(current_user === item.transferCustomerId && {
            transferFrom: {
              value: item?.transferDetails?.userFrom?.email,
            },
          }),

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          ...(current_user === item.customerId && {
            walletsBalancesBefore: {
              value: <WalletBalance balance={item?.walletsBalance} />,
              type: "dropdown",
            },
          }),
        }
      : {};
  };

  return { columns, rows, summary };
}

export function Utilities() {
  const columns = [
    {
      field: "nos",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        const { status } = params?.row;
        return <StatusIcon type={status} />;
      },
    },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        const { productType } = params.row;
        return (
          <Amount
            {...{
              amount: params.value,
              walletType:
                params?.row?.[
                  productType === "convert-airtime"
                    ? "walletToCredit"
                    : "walletToDebit"
                ],
            }}
          />
        );
      },
    },

    {
      field: "product",
      headerName: "Product",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "beneficiary",
      headerName: "Beneficiary",
      width: 130,
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      product: item.product,
      productType: item.productType,
      amount: item.amount,
      beneficiary: item.beneficiary,
      date: item.dateCreated,
      walletToDebit: item.walletToDebit,
      walletToCredit: item.walletToCredit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
            type: "text",
          },
          date: {
            value: formatDate(dateCreated),
            type: "text",
          },

          transactionID: {
            value: item?.transId || item?.id,
            type: "text",
            clickable: true,
          },

          // transactionRef: {
          //   value: item?.transRef || item?.id,
          //   type: "text",
          //   clickable: true,
          // },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.amount,
                  walletType:
                    item?.[
                      item.productType === "convert-airtime"
                        ? "walletToCredit"
                        : "walletToDebit"
                    ],
                }}
              />
            ),
            type: "text",
          },
          totalAmountSold: {
            value: item?.amountSold ? (
              <Amount
                {...{
                  amount: item?.amountSold,
                  walletType:
                    item?.[
                      item.productType === "convert-airtime"
                        ? "walletToCredit"
                        : "walletToDebit"
                    ],
                }}
              />
            ) : null,
            type: "text",
          },
          productType: {
            value: capitalize(item?.productType),
            type: "text",
          },

          provider: {
            value: startCase(item?.provider),
            type: "text",
          },

          productPlan: {
            value: startCase(item?.productPlan),
            type: "text",
          },
          beneficiary: {
            value: item?.beneficiary,
            type: "text",
          },
          instructions: {
            value: Boolean(item?.utilityInstructions)
              ? item?.utilityInstructions
              : null,
            type: "text",
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
            type: "text",
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
            type: "text",
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return {
    columns,
    rows,
    summary,
    pending_row: rows,
    pending_summary: summary,
  };
}

export function TransferBonus() {
  const columns = [
    {
      field: "nos",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        const { status } = params?.row;
        return <StatusIcon type={status} />;
      },
    },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToCredit,
            }}
          />
        );
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize("fiat")}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{params.value}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: item.product,
      assetType: item.productType?.split("-")[1],
      action: item.productType,
      amount: item.amount,
      date: item.dateCreated,
      walletToCredit: item.walletToCredit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.amount,
                  walletType: item?.walletToCredit,
                }}
              />
            ),
          },
          bonusDescription: {
            value: item?.bonusDescription,
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
            type: "text",
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
            type: "text",
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };
  return { columns, rows, summary };
}

export function ConvertFiat() {
  const columns = [
    {
      field: "nos",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        const { status } = params?.row;
        return <StatusIcon type={status} />;
      },
    },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize("fiat")}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{params.value}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: item.product,
      assetType: item.productType?.split("-")[1],
      action: item.productType,
      amount: item.amount,
      date: item.dateCreated,
      walletToDebit: item.walletToDebit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.amount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          convertFrom: {
            value: `${formatInputNum(item?.amountToDebit, 2)} ${
              item?.convertDetails?.sym
            }`,
          },
          convertTo: {
            value: `${formatInputNum(item?.amountToCredit, 2)} ${
              item?.convertDetails?.symTo
            }`,
          },
          conversionRate: {
            value: `${item?.convertDetails?.rate} ${item?.convertDetails?.sym} - ${item?.convertDetails?.rateTo} ${item?.convertDetails?.symTo}`,
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function PendingWithdrawFiat() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        const par = params.row;
        return (
          <Amount
            {...{ amount: params.value, walletType: par.walletToDebit }}
          />
        );
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },

    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return (
      arr
        // ?.sort((b, c) => c.dateCreated - b.dateCreated)
        ?.map((item, index) => ({
          nos: index + 1,
          id: item.id,
          idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
          status: item.status,
          asset: "Fiat",
          assetType: item.productType?.split("-")[1],
          action: item.productType,
          amount: item.totalAmount,
          date: item.dateCreated,
          walletToDebit: item.walletToDebit,
        }))
    );
  };

  const summary = (item) => {
    const { withdrawDetails, totalAmount, dateCreated } = item || {};
    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          charges: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.charges,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          amount: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.amount,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          beneficiaryMomoName: {
            value: <>{withdrawDetails?.accountDetails?.momoName}</>,
            type: "text",
          },

          beneficiaryMomoNumber: {
            value: withdrawDetails?.accountDetails?.momoNumber,
          },
          beneficiaryMomoType: {
            value: withdrawDetails?.accountDetails?.momoType,
          },
          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}
