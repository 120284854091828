import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button } from "components";

import { formatInputNum } from "utils";

export function FormAccordion({ provider, amount, phoneList }) {
  const mapSendPhoneNo = phoneList;
  const providerCap = provider?.toUpperCase();

  const content = [
    {
      id: 1,
      title: `Steps To Transfer On ${providerCap}`,
      contents: [
        `Dial*777*${mapSendPhoneNo[provider]}*Amount*PIN# on your phone, eg -  *777*${mapSendPhoneNo[provider]}*1500*1234#. Click on send/press OK.`,
        "When the transfer has been completed, you will receive a message informing you that the transfer was successful.",
      ],
    },
    {
      id: 2,
      title: `Steps To Get Transfer Pin On ${providerCap}`,
      contents: [
        "Kindly note that your default Share Pin for airtime or data transfer is 0000.",
        `Simply dial *600*default PIN*unique PIN*Unique PIN#, that is - *600*0000*2345*2345#. The 2345 becomes your ${providerCap} Share PIN.`,
      ],
    },
  ];

  return (
    <>
      <div className="my-3 text-sm font-bold text-gray-500">
        Transfer ₦{formatInputNum(amount)} Airtime To:{" "}
        {mapSendPhoneNo[provider]}
      </div>
      <div className="rounded border border-gray-300">
        {content.map((item) => (
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              // id="panel1a-header"
            >
              <div className="text-sm text-gray-500">{item.title}</div>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="list-disc px-2">
                {item.contents.map((i) => (
                  <li className="text-sm leading-[1.8] text-gray-400 ">{i}</li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
}

export function FormInstruction({ setPage }) {
  return (
    <div>
      <h5 className="text-sm font-bold text-gray-500">
        Kindly read the terms/instructions below before you proceed.
      </h5>
      <div className="my-6">
        {[
          "Please note you must send airtime before submitting this form you're about to start.",
          "Submitting this form without sending the airtime will get your account disabled.",
          "You are expected to provide the phone number where you sent the airtime from.",
          "Ensure the airtime provided in the form corresponds to the airtime you actually sent.",
          "Failure to adhere to the above, you are at risk of losing your money and airtime.",
          "Once we have verified the airtime, your account will be credited and you can process withdrawals to your bank account or use it to purchase any of our products.",
          "Click the button below to get started.",
        ].map((content, ind) => {
          return (
            <div key={ind} className="mb-4 flex items-start text-text-color">
              <CheckCircleOutlineIcon
                sx={{ fontSize: 18 }}
                className="text-color-main"
              />
              <div className="ml-4">
                <p className="text-sm text-slate-400">{content}</p>
              </div>
            </div>
          );
        })}
      </div>
      <Button
        fullWidth
        label="Get Started"
        gradient
        handleClick={() => setPage(1)}
      />
    </div>
  );
}
