import { createTheme } from "@mui/material/styles";
const colors = require("tailwindcss/colors");

export const muiTheme = createTheme({
  palette: {
    primary: {
      // main: colors.indigo['600'],
      main: "#4174b2",
    },
    light: {
      main: "#f4f5fa",
      contrastText: "#4174b2",
    },
    default: {
      main: colors.slate["200"],
      contrastText: "#4174b2",
    },
    neutral: {
      main: "#959292",
    },
    text: {
      main: "#fff",
    },
    background: {
      main: "#fafaff",
    },
  },
  typography: {
    fontFamily: "inherit",
  },
});
