import React, { useCallback, useEffect, useState } from "react";
import {
  BackdropSection,
  Button,
  EnterPIN,
  SectionContent,
  TextField,
  WalletBalance,
  NoDisplay,
} from "components";
import {
  useGetFiatCharges,
  useGetSiteFiatRate,
  useGetUSDFiatRate,
  useTransferFund,
  useVerifyTransferEmail,
} from "api";
import { Fade, Slide } from "react-reveal";
import { useSelector } from "react-redux";

export default function TransferFiat({
  currentWalletName,
  displayPage,
  setPage,
  setOpenMobileDrawer,
}) {
  // useState hooks
  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [transferUserEmail, setTransferUserEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  // useSelector hooks

  const { nairaWallet, cedisWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  const {
    currentUser: { current_user: userId },
  } = useSelector((state) => state.auth || {});

  // Functions
  const { NGN = 0, GHS = 0, XOF = 0 } = useGetUSDFiatRate() || {};

  const [isLoading, getDetails, setGetDetails, verifyDetails] =
    useVerifyTransferEmail();

  const [isSubmitting, isSubmitted, submitTransfer] = useTransferFund();

  const fiatRate = useGetSiteFiatRate() || {};
  const { cedisRate, cefaRate } = fiatRate;

  const chargesObj = useGetFiatCharges(currentWalletName);
  const {
    minAmount: minAmt,
    maxAmount: maxAmt,
    maintainMinimum: maintainAmt,
  } = chargesObj || {};

  // Hooks
  const currentWalletObj = useCallback(() => {
    let amount, sym, symUnit, type, rate, maxAmount, minAmount, maintainMax;
    switch (currentWalletName) {
      case "naira":
        amount = nairaWallet?.amount || 0;
        symUnit = "₦";
        sym = "NGN";
        type = "naira";
        rate = NGN;
        minAmount = minAmt;
        maxAmount = maxAmt;
        maintainMax = maintainAmt;
        break;
      case "cedis":
        amount = cedisWallet?.amount || 0;
        symUnit = "₵";
        sym = "GHS";
        type = "cedis";
        rate = GHS;
        minAmount = Math.ceil(minAmt * cedisRate);
        maxAmount = Math.ceil(maxAmt * cedisRate);
        maintainMax = Math.ceil(maintainAmt * cedisRate);
        break;
      case "cefa":
        amount = cefaWallet?.amount || 0;
        symUnit = "₵";
        sym = "XOF";
        type = "cefa";
        rate = XOF;
        minAmount = Math.ceil(minAmt * cefaRate);
        maxAmount = Math.ceil(maxAmt * cefaRate);
        maintainMax = Math.ceil(maintainAmt * cefaRate);
        break;
      default:
        break;
    }
    return {
      amount,
      sym,
      symUnit,
      type,
      rate,
      maxAmount,
      minAmount,
      maintainMax,
    };
  }, [
    nairaWallet,
    cedisWallet,
    cefaWallet,
    currentWalletName,
    NGN,
    GHS,
    XOF,
    cedisRate,
    cefaRate,
    chargesObj,
  ])();

  useEffect(() => {
    const {
      amount: walletAmount,
      minAmount,
      maxAmount,
      maintainMax,
      symUnit,
    } = currentWalletObj;

    const total = parseInt(amount);
    const limitLow = parseInt(amount) < minAmount;
    const limitHigh = parseInt(amount) > maxAmount;
    const overlimit = total >= parseInt(walletAmount || 0);
    const maintainMaximum = parseInt(walletAmount || 0) - total < maintainMax;

    setTotalAmt(total);
    setErrorAmt(overlimit || maintainMaximum || limitLow || limitHigh);
    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? `Transfer must be ${symUnit}${minAmount} and above`
        : limitHigh
        ? `Transfer must be ${symUnit}${maxAmount} and below`
        : maintainMaximum
        ? `Maintain a minimum of ${symUnit}${maintainMax} in wallet`
        : ""
    );
  }, [amount]);

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setAmount("");
      setTotalAmt(0);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        setOpenMobileDrawer(false);
        setPage(0);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  // methods

  const handleAmtChange = (val) => {
    let e = val.replace(/[^0-9]/g, "");

    if (e.length > 12) {
      setAmount(e.slice(0, 12));
    } else {
      setAmount(e);
    }
  };

  const processTransfer = () => {
    const { type, maintainMax, minAmount, maxAmount } = currentWalletObj;
    const values = {
      productType: `transfer-${type}`,
      amountToDebit: parseInt(totalAmt),
      walletToDebit: `${type}Wallet`,
      amount: parseInt(amount),
      totalAmount: parseInt(totalAmt),
      type,
      accountType: type,
      transferDetails: {
        user: {
          email: transferUserEmail,
          name: getDetails?.details,
        },
        amount: {
          amount: parseInt(amount),
          totalAmount: parseInt(totalAmt),
          maintainMax: parseFloat(maintainMax),
          minAmount: parseFloat(minAmount),
          maxAmount: parseFloat(maxAmount),
        },
      },
    };
    submitTransfer(values);
  };

  if (!displayPage("transfer")) {
    return <NoDisplay type="transfer" />;
  }

  return (
    <>
      {fiatRate && chargesObj ? (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="col-span-2">
            <TextField
              name="amount"
              label={`Amount (${currentWalletObj?.sym})`}
              type="text"
              value={amount || ""}
              handleChange={(e) => {
                const v = e.target.value;
                handleAmtChange(v);
              }}
              // formatInput
              disableAutoComplete
              placeholder="0.00"
              disabled={isSubmitting}
            />
            {errorAmt && (
              <Slide down duration={300}>
                <div className="mt-2 text-xs font-normal text-red-500">
                  {errorText}
                </div>
              </Slide>
            )}
          </div>

          <div className="col-span-2">
            <TextField
              name="email"
              type="email"
              label="User Email"
              value={transferUserEmail}
              handleChange={(e) => {
                setTransferUserEmail(e.target.value);
                setGetDetails({ details: null, error: false });
              }}
              disableAutoComplete
              placeholder="johndoe@gmail.com"
              endAdornment={
                <Button
                  label="Verify"
                  gradient
                  handleClick={() => verifyDetails({ transferUserEmail })}
                  isSubmitting={isLoading}
                  disabled={!Boolean(transferUserEmail) || isSubmitting}
                />
              }
              disabled={isSubmitting}
            />
          </div>

          {getDetails.details && (
            <div className="col-span-2">
              <TextField
                name="details"
                // type="text"
                label="User Details"
                value={getDetails?.details}
                readOnly
                showError={getDetails.error}
                disabled={isSubmitting}
              />
            </div>
          )}

          <div className="col-span-2">
            <Button
              label="Continue"
              fullWidth
              gradient
              disabled={
                !(
                  parseInt(amount) &&
                  !errorAmt &&
                  !getDetails.error &&
                  getDetails.details
                )
              }
              handleClick={() => setOpen(true)}
            />
          </div>
        </div>
      ) : (
        <div className="relative h-[150px]">
          <BackdropSection open={true} />
        </div>
      )}
      <EnterPIN
        open={open}
        setOpen={setOpen}
        openIsSubmitted={openIsSubmitted}
        setIsSubmitted={setIsSubmitted}
        processSubmit={processTransfer}
        btnText="Transfer Fund"
        isSubmitting={isSubmitting}
      />
    </>
  );
}
