import React from "react";
import AppShortcutRoundedIcon from "@mui/icons-material/AppShortcutRounded";
import { Button, Container } from "components";
import { PageContainer } from "shared";

export default function DownloadApp() {
  return (
    <PageContainer footerPos="fixed">
      <div className="m-auto w-full md:w-2/3 lg:w-1/2">
        {/* <Container> */}
        <div className="flex flex-col items-center justify-center rounded-sm border border-gray-200 bg-white p-6 text-center shadow-card-box">
          <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
            <AppShortcutRoundedIcon
              sx={{ fontSize: 30 }}
              className="iconShadow"
            />
          </div>
          <div className="my-4">
            <h4 className="text-lg font-bold">Our Apps!</h4>
            <p className="my-4 text-base leading-7">
              In order to make transactions easier and more comfortable for our
              users, We have our mobile app that's available for both Android &
              IPhones users, the mobile app is available for download on both
              Apple Store and Play Store.
            </p>
          </div>

          <div className="m-auto flex w-full justify-center gap-4 text-center">
            <Button
              label="Apple Store"
              variant="outlined"
              labelIcon="Apple"
              handleClick={() => {
                return window.open(
                  "https://apps.apple.com/us/app/com.kaapo.mobile/id6444363402",
                  "_blank"
                );
              }}
            />
            <Button
              label="Google Play Store"
              variant="outlined"
              labelIcon="Android"
              handleClick={() => {
                return window.open(
                  "https://play.google.com/store/apps/details?id=com.kaapo.mobile",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
        {/* </Container> */}
      </div>
    </PageContainer>
  );
}
