import React, { useEffect, useState } from "react";
import { Select, TextField, Button, EnterPIN } from "components";
import { useGetUtilityList, useVerifyUtility, usePurchaseUtiliity } from "api";
import { Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";

export default function BuyBetting({ nairaWallet, userPhone }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  // const [currentTab, setCurrentTab] = useState(tabList[0]);

  const [values, setValues] = useState(null);
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [isSubmitting, isSubmitted, purchaseUtility] = usePurchaseUtiliity();

  const [utilityList, isLoadingList] = useGetUtilityList("betting");

  const [
    getVerifyDetails,
    setVerify,
    verifyBetting,
    isLoading,
    isErrorVerify,
    setErrorVerify,
  ] = useVerifyUtility();

  const handleBeneficiary = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");

    setValues((prevS) => ({
      // provider: prevS?.provider,
      // providerName: prevS?.providerName,
      // package: e.serviceName,
      ...prevS,
      beneficiary: val,
    }));
    setErrorAmt(false);
  };

  const handleAmtChange = (e) => {
    const total = parseInt(e);
    const limit = parseInt(e) < 500 || parseInt(e) > 200000;
    const overlimit = total >= parseInt(nairaWallet?.amount || 0);
    const overcharges = parseInt(nairaWallet?.amount || 0) - total < 50;

    setValues((prevS) => ({ ...prevS, amount: e }));
    setTotalAmt(total);
    setErrorAmt(overlimit || overcharges || limit);
    setErrorText(
      overlimit
        ? "You have exceeded balance amount"
        : limit
        ? "Purchase must be within ₦500 and ₦200000"
        : overcharges
        ? "Maintain a minimum of ₦50 in wallet"
        : ""
    );
  };

  const handlePurchase = () => {
    const {
      amount,
      provider,
      providerName,
      beneficiary,
      package: plan,
    } = values;

    const valuesAlt = {
      amountToDebit: parseInt(amount),
      provider,
      providerName,
      beneficiary,
      productType: "betting",
      productPlan: plan,
      amount: parseInt(amount),
      transactionDetails: {
        // service_type: provider,
        walletNumber: beneficiary,
        action: "WALLET_FUNDING",
        amount: parseInt(amount),
      },
    };

    purchaseUtility(valuesAlt);
  };

  const disabledTrans = !(
    values &&
    Object.values(values).every((item) => item) &&
    parseInt(values?.amount) &&
    values?.beneficiary?.length >= 5 &&
    !errorAmt
  );

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setValues(null);
      setVerify(null);
      setTotalAmt(0);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        navigate(-1);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  return (
    <div className="">
      <div className="rounded-sm border border-gray-200 bg-white p-4 shadow-card-box">
        <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-2">
            <Select
              titleValue="name"
              subTitleValue="serviceType"
              dropDown={utilityList}
              inputLabel="Provider"
              disabled={isSubmitting}
              handleChange={(e) => {
                setValues(() => ({
                  provider: e?.service_type,
                  serviceType: e?.service_type,
                  providerName: e.name,
                  package: e.name,
                }));
                setErrorAmt(false);
                setVerify(null);
                setErrorVerify(false);
              }}
              valueAlt={values?.providerName || ""}
            />
          </div>

          <div className="col-span-2">
            <TextField
              name="beneficiary-number"
              label="Wallet Number"
              placeholder="Input wallet number"
              value={values?.beneficiary || ""}
              handleChange={handleBeneficiary}
              disableAutoComplete
              endAdornment={
                <Button
                  label="Verify"
                  gradient
                  handleClick={() => {
                    verifyBetting({
                      serviceType: values?.serviceType,
                      customerId: values?.beneficiary,
                    });
                  }}
                  isSubmitting={isLoading}
                  disabled={
                    !(values?.provider && values?.beneficiary?.length >= 5)
                  }
                />
              }
            />

            {isErrorVerify && !getVerifyDetails && (
              <Slide down duration={300}>
                <div className="mt-2 text-xs font-normal text-red-500">
                  {isErrorVerify}
                </div>
              </Slide>
            )}
          </div>

          {getVerifyDetails && (
            <>
              <div className="col-span-2">
                <TextField
                  name="beneficiary-name"
                  label="Beneficiary Name"
                  value={getVerifyDetails?.user?.name || ""}
                  readOnly
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="amount"
                  label="Amount"
                  value={values?.amount || ""}
                  handleChange={handleAmtChange}
                  startAdornment="₦"
                  formatInput
                  disableAutoComplete
                  placeholder="0.00"
                  disabled={isSubmitting}
                />
                {errorAmt && (
                  <Slide down duration={300}>
                    <div className="mt-2 text-xs font-normal text-red-500">
                      {errorText}
                    </div>
                  </Slide>
                )}
              </div>

              <div className="col-span-2">
                <Button
                  label="Continue"
                  fullWidth
                  gradient
                  disabled={disabledTrans}
                  handleClick={() => setOpen(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <EnterPIN
        open={open}
        setOpen={setOpen}
        openIsSubmitted={openIsSubmitted}
        setIsSubmitted={setIsSubmitted}
        processSubmit={handlePurchase}
        btnText={"Fund Betting Wallet"}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}
