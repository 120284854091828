import React, { useState, useEffect, useRef } from "react";
import { Slide } from "react-reveal";
import { classNames } from "utils";
import Scrollbars from "react-custom-scrollbars-2";
import { SimpleIcon } from "components";

export function useDropDownVisible(initialIsVisible, dropdownId) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    try {
      if (
        event &&
        event.target.className?.includes(`dropdown-button-${dropdownId}`)
      ) {
        setIsDropdownVisible(!isDropdownVisible);
      } else if (ref.current && !ref.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isDropdownVisible]);

  return { ref, isDropdownVisible, setIsDropdownVisible };
}

export function CustomInputWithDropdown({
  currentWallet,
  setCurrentWallet,
  dropdownList,
  dropdownId,
  isTransactionListLoading,
}) {
  const { ref, isDropdownVisible, setIsDropdownVisible } = useDropDownVisible(
    false,
    dropdownId
  );

  const handleCurrentWallet = (item) => {
    setCurrentWallet(item);
    setIsDropdownVisible(false);
  };

  return (
    // rounded-lg bg-white p-1 shadow-md
    <div className="relative mt-2 h-full md:mt-0">
      <div className="z-[20] h-full">
        <button
          className={classNames(
            isTransactionListLoading ? "pointer-events-none" : "",
            // bg-slate-50
            `dropdown-button-${dropdownId} h-full w-full py-2 px-2`
          )}
        >
          <div className="pointer-events-none flex flex-row items-center justify-between">
            <div className="mr-2 flex items-center">
              <SimpleIcon
                icon="TableChart"
                // frontColor="text-gray-400"
                fontSize={16}
              />

              <div className="ml-1 text-sm font-bold text-primary">
                {currentWallet?.name || "Filter by Product"}
              </div>
            </div>

            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-primary">
              <SimpleIcon
                icon={!isDropdownVisible ? "ExpandMore" : "ExpandLess"}
                frontColor="text-white"
                fontSize={16}
              />
            </div>
          </div>
        </button>
      </div>
      {isDropdownVisible && dropdownId && (
        <div
          ref={ref}
          // absolute
          className={`dropdown-${dropdownId} absolute top-14 left-0 z-[20] w-[250px]`}
        >
          <div className="h-full w-full overflow-auto rounded-lg bg-white px-1 shadow-md">
            <Slide down duration={300}>
              <Scrollbars autoHeight>
                <div className="my-1 w-full overflow-auto rounded-md border border-slate-200 bg-white shadow-md">
                  {dropdownList.map((item, index) => (
                    <button
                      key={index}
                      className={classNames(
                        index === 0 ? "" : "border-t border-slate-100",
                        currentWallet?.id === item.id ? "bg-slate-100" : "",
                        "dropdown-list flex w-full flex-row items-center p-2 hover:rounded hover:bg-card"
                      )}
                      onClick={() => {
                        handleCurrentWallet(item);
                      }}
                    >
                      <div className="pointer-events-none flex flex-row items-center">
                        {/* text-gray-500 */}

                        <SimpleIcon
                          icon="TableChart"
                          frontColor="text-gray-400"
                          fontSize={16}
                        />
                        <div className="ml-3 flex flex-col items-start text-sm text-gray-500">
                          <span>{item.name}</span>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </Scrollbars>
            </Slide>
          </div>
        </div>
      )}
    </div>
  );
}
