export const products = [
  {
    title: "Buy Airtime",
    subtitle: "Top-up ypur phone with airtime.",
    subtitle_alt: "Tap to top-up airtime",
    linkTo: "buy-airtime",
    iconColor: "bg-cyan-600",
    textColor: "text-cyan-600",
    Icon: "PermPhoneMsg",
    IconToned: "PermPhoneMsgTwoTone",
    bgGradient: "from-[#0891b2] to-[#076c85]",
  },
  {
    title: "Buy Data",
    subtitle: "Purchase Internet and Data Bundles.",
    subtitle_alt: "Tap to buy data",
    linkTo: "buy-data",
    iconColor: "bg-lime-600",
    textColor: "text-lime-600",
    Icon: "CompassCalibration",
    IconToned: "CompassCalibrationTwoTone",
    bgGradient: "from-lime-600 to-lime-900",
  },
  {
    title: "Cable TV Subscription",
    subtitle: "Subscribe to favourite TV Channels.",
    subtitle_alt: "Tap to pay for cable subscription",
    linkTo: "buy-cables",
    iconColor: "bg-yellow-700",
    textColor: "text-yellow-600",
    Icon: "ConnectedTv",
    IconToned: "ConnectedTvTwoTone",
    bgGradient: "from-yellow-600 to-yellow-900",
  },
  {
    title: "Electricity",
    subtitle: "Pay electricity bills with ease.",
    subtitle_alt: "Tap to pay electricity bills",
    linkTo: "buy-electricity",
    iconColor: "bg-orange-600",
    textColor: "text-orange-600",
    Icon: "ElectricBolt",
    IconToned: "ElectricBoltTwoTone",
    bgGradient: "from-orange-500 to-orange-900",
  },
  // {
  //   title: "Education",
  //   subtitle: "Generate WAEC, JAMB Result Checker Pin.",
  //   subtitle_alt: "Tap to purchase scratch cards",
  //   linkTo: "buy-pin",
  //   iconColor: "bg-teal-600",
  //   textColor: "text-teal-600",
  //   Icon: "School",
  //   IconToned: "SchoolTwoTone",
  //   bgGradient: "from-teal-500 to-teal-900",
  // },
  {
    title: "Betting",
    subtitle: "Fund betting wallet.",
    subtitle_alt: "Tap to fund betting wallet",
    linkTo: "fund-betting-account",
    iconColor: "bg-[#576574]",
    textColor: "text-[#576574]",
    Icon: "Casino",
    IconToned: "CasinoTwoTone",
    bgGradient: "from-[#576574] to-[#c2b6b6]",
  },
  {
    title: "Airtime to Cash",
    subtitle: "Convert excess airtime to cash instantly.",
    subtitle_alt: "Tap to sell airtime",
    linkTo: "convert-airtime",
    iconColor: "bg-[#7f5a83]",
    textColor: "text-[#7f5a83]",
    Icon: "Money",
    IconToned: "MoneyTwoTone",
    bgGradient: "from-[#0d324d] to-[#7f5a83]",
  },
];

export const analytics = [
  {
    text: "pending",
    // textColor: 'text-[#febe0f]',
    textColor: "text-amber-600",
    border: "border-amber-600",
    bgColorLite: "bg-amber-50",
    bgColor: "bg-amber-500",
    Icon: "PendingActionsOutlined",
  },
  {
    text: "active",
    // textColor: 'text-[#28afd0]',
    textColor: "text-cyan-600",
    border: "border-cyan-600",
    bgColorLite: "bg-cyan-50",
    bgColor: "bg-cyan-500",
    // Icon: HourglassBottomRoundedIcon,
    Icon: "PendingActionsOutlined",
  },
  {
    text: "approved",
    // textColor: 'text-[#5ed84f]',
    textColor: "text-green-600",
    border: "border-green-600",
    bgColorLite: "bg-green-50",
    bgColor: "bg-green-500",
    Icon: "CheckCircleOutlineRounded",
  },
  {
    text: "declined",
    // textColor: 'text-[#f8313c]',
    textColor: "text-red-600",
    border: "border-red-600",
    bgColorLite: "bg-red-50",
    bgColor: "bg-red-500",
    Icon: "CancelOutlined",
  },
];

export const securitActions = [
  {
    Icon: "LockOutlined",
    title: "Create/Reset PIN",
    subtitle_alt: "Click to set or reset your PIN",
    btnText: "Change Pin",
    id: "changePin",
  },
  {
    Icon: "LockOutlined",
    title: "Change Password",
    subtitle_alt: "Click to change your password",
    btnText: "Change Password",
    id: "changePassword",
  },
  {
    Icon: "LockOutlined",
    title: "Change Phone Number",
    subtitle_alt: "Click to change your phone number",
    btnText: "Change Phone Number",
    id: "changePhoneNumber",
  },
];

export const createPinFormList = [
  {
    name: "pin",
    type: "password",
    // type: "numeric",
    label: "New PIN",
    inputType: "textfield",
  },
  {
    name: "confirmPin",
    type: "password",
    label: "Confirm New PIN",
    inputType: "textfield",
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    inputType: "textfield",
    helperText_alt: "Confirm your PIN reset with your password",
  },
];

export const changePinFormList = (email) => [
  {
    name: "code",
    type: "text",
    label: "OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your PIN change with the verification code send to the email - ${email}`,
    otherProps: {
      disableAutoComplete: true,
    },
  },
];

export const verifyPassFormList = [
  {
    name: "currentPass",
    type: "password",
    label: "Current Password",
    inputType: "textfield",
  },
];

export const changePassFormList = (email) => [
  {
    name: "newPass",
    type: "password",
    label: "New Password",
    inputType: "textfield",
  },
  {
    name: "confirmNewPass",
    type: "password",
    label: "Confirm New Password",
    inputType: "textfield",
  },
  {
    name: "code",
    type: "text",
    label: "Input OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your password change with the verification code send to the email - ${email}`,
    otherProps: {
      disableAutoComplete: true,
    },
  },
];

export const verifyEmailFormList = [
  {
    name: "newEmail",
    type: "email",
    label: "New Email",
    inputType: "textfield",
    otherProps: {
      disableAutoComplete: true,
    },
  },
  {
    name: "currentPass",
    type: "password",
    label: "Password",
    inputType: "textfield",
  },
];

export const changeEmailFormList = (email) => [
  {
    name: "code",
    type: "text",
    label: "Input OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your email change with the verification code send to the email - ${email}`,
    otherProps: {
      disableAutoComplete: true,
    },
  },
];

export const tradeActions = {
  msg: {
    approved: "Your transaction has been approved.",
    pending:
      "Your transaction will be processed shortly. We will get back to you on its status.",
    active:
      "Your transaction is already in process. We will get back to you shortly.",
    declined:
      "Your transaction was declined. Kindly view the decline upload box for more details.",
  },

  declineReason: {
    "wrong-channel":
      "Your card trade was uploaded through the wrong channel. Kindly submit your trade using the right card type mode.",
    "used-card":
      "The card/code has already been redeemed/used. Kindly reach out to the source of the card for more information.",
    "invalid-card":
      "The card/code is invalid. Kindly reach out to the source of the card for more information.",
  },

  tradeStatus: {
    approved: {
      bg: "bg-green-100/50",
      text: "text-green-500",
      color: "bg-green-500",
      Icon: "CheckCircleOutlineIcon",
    },
    declined: {
      bg: "bg-red-100/50",
      text: "text-red-500",
      color: "bg-red-500",
      Icon: "HighlightOffIcon",
    },
    pending: {
      bg: "bg-yellow-100/50",
      text: "text-yellow-500",
      color: "bg-amber-500",
      Icon: "CheckCircleOutlineIcon",
    },
    active: {
      bg: "bg-sky-100/50",
      text: "text-sky-500",
      color: "bg-cyan-500",
      Icon: "CheckCircleOutlineIcon",
    },
  },
};
