import React from "react";

import { SelectChip, TextField, VerifyBank } from "components";

export default function OwnAccount1({
  errorAmt,
  errorText,
  handleAmtChange,
  accounts,
  setBankDetails,
  bankDetails,
  amount,
  label,
}) {
  return (
    <>
      <VerifyBank
        {...{ bankDetails, setBankDetails, accounts, label }}
        accountType="own-account"
      />

      <div className="col-span-2">
        <SelectChip
          title="Select Amount"
          list={["5000", "10000", "20000", "50000", "100000"]}
          handleClick={(amt) => handleAmtChange(amt)}
          currData={amount}
        />
      </div>

      <div className="col-span-2">
        <TextField
          name="amount"
          type="numeric"
          label="Amount"
          value={amount}
          // handleChange={(e) => handleAmtChange(e.target.value)}
          handleChange={handleAmtChange}
          startAdornment="₦"
          formatInput
          disableAutoComplete
          error={errorAmt === true}
          showError={errorAmt === true}
          helperText={errorText}
          placeholder="0.00"
        />
      </div>
    </>
  );
}
