import React, { useState } from "react";
import { capitalize } from "lodash";
import { Fade, Slide } from "react-reveal";
import { Button, Container, BackPage, SimpleIcon } from "components";
import { classNames, symToCur, formatMoney, useBreakpoint } from "utils";

export default function Summary({
  tradeSummary,
  cardType,
  total,
  defaultFiat,
  showFullSummary,
  handleStep,
  processTrade,
  isProcessing,
  pushToAnalytics,
}) {
  const breakpoint = useBreakpoint();
  const [openSummary, setOpenSummary] = useState(true);

  const handleTransaction = () => {
    processTrade();
    pushToAnalytics(tradeSummary);
  };

  return (
    <div
      className={classNames(
        showFullSummary ? "md:col-span-3" : "md:col-span-1",
        "relative col-span-1"
      )}
    >
      {showFullSummary && <BackPage handleClick={handleStep} />}

      {showFullSummary && (
        <Fade center>
          <Container>
            <div className="bg-white p-4">
              <div>
                {[
                  [
                    "Important Info",
                    <>
                      Esetech will pay you{" "}
                      <span className="font-bold">
                        {formatMoney(total, defaultFiat)}
                      </span>
                      for a successful completion of this transaction.
                    </>,
                  ],
                  [
                    "Note",
                    "Processing time takes about 5 mins - 2 hrs. And after which, you'll be notified of the status of the transaction.",
                  ],
                ].map((content, ind) => {
                  return (
                    <div
                      key={ind}
                      className={classNames(
                        ind === 0 ? "mb-4" : "",
                        "flex items-start text-text-color"
                      )}
                    >
                      <SimpleIcon icon="CheckCircleOutlined" otherClass="" />

                      <div className="ml-4">
                        <div className="text-sm">{content[0]}</div>
                        <p className="text-gray-400">{content[1]}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Container>
        </Fade>
      )}

      <div className="mt-3 overflow-hidden">
        <Fade center spy={showFullSummary}>
          <Container>
            <div className="flex flex-row items-center justify-between bg-gray-50 p-4">
              <div className="flow-root">
                <span className="">Trade Summary</span>
              </div>

              <button
                className="flow-root"
                onClick={() => {
                  setOpenSummary(!openSummary);
                }}
              >
                <SimpleIcon
                  icon="ExpandCircleDownOutlined"
                  otherClass={openSummary ? "rotate-180" : "rotate-0"}
                />
              </button>
            </div>
            {openSummary ? (
              <div className="relative grid gap-6 bg-white px-4 py-4 sm:gap-8 sm:p-4">
                {Object.entries(tradeSummary).map((item) => {
                  return (
                    <Slide left key={`${item[0]}-${item[1]}`}>
                      <div className="-m-3 flex items-start rounded border-b border-gray-300 p-3 hover:bg-gray-50">
                        <SimpleIcon icon="CheckCircleOutlined" otherClass="" />

                        <div className="ml-2">
                          <p className="text-sm text-gray-900">
                            {capitalize(item[0])}
                          </p>
                          {item[0] === "quantity" ? (
                            <div className="mt-1 text-sm text-gray-500">
                              {Object.entries(item[1]).map((it) => (
                                <p className="font-medium">
                                  {symToCur(cardType)}
                                  {it[0]} * {it[1].count}pcs @{" "}
                                  {formatMoney(it[1].price, defaultFiat, 0)} /{" "}
                                  {cardType}
                                  {/* {symToCur(cardType)} */}
                                </p>
                              ))}
                            </div>
                          ) : (
                            <p className="mt-1 text-sm font-medium text-gray-500">
                              {item[0] === "total"
                                ? formatMoney(item[1], defaultFiat)
                                : item[1]}
                            </p>
                          )}
                        </div>
                      </div>
                    </Slide>
                  );
                })}
              </div>
            ) : null}
          </Container>
        </Fade>
      </div>

      {showFullSummary && (
        <>
          <div className="mt-4" />
          <div>
            <Container>
              <Button
                label="Process Trade"
                fullWidth
                gradient
                handleClick={() => handleTransaction()}
                isSubmitting={isProcessing}
              />
            </Container>
          </div>
        </>
      )}
    </div>
  );
}
