import React from "react";

export default function NoDisplay({ type = "withdrawal" }) {
  return (
    <div className="h-[150px] p-2">
      <div className="bg-card">
        <div className="p-4 text-sm">Not available for {type}.</div>
      </div>
    </div>
  );
}
