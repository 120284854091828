import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  completedTrans: null,
  pendingTrans: null,
  isCompletedTransLoading: false,
  isPendingTransLoading: false,
  transactionStatus: false,
  isTransCountLoaded: false,
  transCount: {},
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    getCompletedTrans: (state, { payload }) => {
      state.completedTrans = payload;
    },
    getPendingTrans: (state, { payload }) => {
      state.pendingTrans = payload;
    },
    getCompletedTransLoading: (state, { payload }) => {
      state.isCompletedTransLoading = payload;
    },
    getPendingTransLoading: (state, { payload }) => {
      state.isPendingTransLoading = payload;
    },
    getTransactionStatus: (state, { payload }) => {
      state.transactionStatus = payload;
    },
    getUserTransCount: (state, { payload }) => {
      state.transCount = { ...state.transCount, ...payload };
    },
    getTransCountLoaded: (state, { payload }) => {
      state.isTransCountLoaded = payload;
    },
  },
});

export const {
  getCompletedTrans,
  getPendingTrans,
  getCompletedTransLoading,
  getPendingTransLoading,
  getTransactionStatus,
  getUserTransCount,
  getTransCountLoaded,
} = transactionSlice.actions;

export default transactionSlice.reducer;
