import * as React from "react";
import Box from "@mui/material/Box";
import {
  AppBar,
  DialogTitle,
  Drawer as MuiDrawer,
  Toolbar,
} from "@mui/material";
import { classNames } from "utils";
import { SimpleIcon } from "./Icon";

export default function Drawer({
  anchor,
  state,
  setState,
  children,
  title,
  handleOnClose,
  closeOnOverlayClick,
}) {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const content = (
    <>
      {anchor === "bottom" && (
        <>
          <div className="relative top-0 left-0 flex justify-end !bg-transparent">
            <button
              className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-50 shadow-lg"
              onClick={toggleDrawer(false)}
            >
              <SimpleIcon icon="Close" otherClass="" />
            </button>
          </div>
          <div className="absolute top-3 left-1/2 h-2 w-12 -translate-x-1/2 rounded bg-slate-300" />
        </>
      )}
      <Box
        role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
        className={classNames(
          anchor === "top" || anchor === "bottom"
            ? "w-auto"
            : "w-screen md:w-[500px]",
          anchor === "bottom"
            ? "relative h-3/5 rounded-t-lg bg-background_main"
            : "",
          ""
        )}
      >
        {["left", "right"].includes(anchor) && (
          <AppBar
            sx={{ position: "relative" }}
            classes={{
              root: "h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow !sticky",
            }}
          >
            <Toolbar
              classes={{
                root: "justify-between items-center h-full",
              }}
            >
              <DialogTitle
                classes={{
                  root: "!font-semibold text-center !text-base ",
                }}
              >
                {title}
              </DialogTitle>

              <button
                className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-sm"
                onClick={() => {
                  setState(false);
                  if (handleOnClose) handleOnClose();
                }}
              >
                <SimpleIcon icon="Close" otherClass="" />
              </button>
            </Toolbar>
          </AppBar>
        )}
        {children}
      </Box>
    </>
  );

  return (
    <div>
      <MuiDrawer
        anchor={anchor}
        open={state}
        {...(closeOnOverlayClick && { onClose: toggleDrawer(false) })}
        classes={{
          paper: classNames(
            anchor === "bottom" ? "!bg-transparent !shadow-none" : ""
          ),
        }}
      >
        {content}
      </MuiDrawer>
    </div>
  );
}
