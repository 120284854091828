import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  giftcard_rate: null,
  giftcard_rate_fac: null,
  giftcard_currency: null,
  giftcardDeclineReasons: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    getGiftcardRate: (state, { payload }) => {
      state.giftcard_rate = payload.giftcard_rate;
    },
    getGiftcardRateFac: (state, { payload }) => {
      state.giftcard_rate_fac = payload.giftcard_rate_fac;
    },
    getGiftcardCurrency: (state, { payload }) => {
      state.giftcard_currency = payload;
    },
    getGiftcardDeclineReasons: (state, { payload }) => {
      state.giftcardDeclineReasons = payload;
    },
    addGiftcardSale: (state, { payload }) => {
      state.add_to_gf_sale = payload.add_to_gf_sale;
    },
    clearGiftcard: (state, { payload }) => {
      state.add_to_gf_sale = {};
    },
    addGiftcardSteps: (state, { payload }) => {
      state.steps = payload;
    },
  },
});

export const {
  getGiftcardRate,
  getGiftcardRateFac,
  getGiftcardCurrency,
  getGiftcardDeclineReasons,
  addGiftcardSale,
  clearGiftcard,
  addGiftcardSteps,
} = productSlice.actions;

export default productSlice.reducer;
