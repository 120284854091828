import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

import { Button, SimpleIcon } from "components";
import { useUpdateProfileImage } from "api";

export default function ProfileImage() {
  const { user_details } = useSelector((state) => state.profile);
  const [images, setImages] = useState([]);
  const onChange = (imageList) => {
    setImages(imageList);
  };

  const [isUpdatingUpload, isUpdatingReset, isUpdated, updateImage] =
    useUpdateProfileImage();

  const handleUploadImage = (type) => {
    updateImage({ profileImage: images, type });
  };

  useEffect(() => {
    if (isUpdated) {
      setImages([]);
    }
  }, [isUpdated]);

  return (
    <div className="flex flex-col items-center justify-center rounded-sm bg-white p-4 text-gray-500 md:border md:border-gray-200 md:shadow-card-box">
      <ImageUploading
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png"]}
      >
        {({ imageList, onImageUpload, onImageRemove }) => (
          <div className="flex flex-wrap rounded bg-white p-2">
            <div className="relative">
              <button onClick={onImageUpload}>
                <div className="flex h-28 w-28 flex-row items-center justify-center rounded-sm bg-background_main_alt p-2">
                  <img
                    src={
                      imageList?.[0]?.["data_url"] || user_details?.avatarUrl
                    }
                    alt="profile-image"
                    className="h-full w-full rounded object-cover"
                  />
                </div>
              </button>
              {imageList.length ? (
                <div className="absolute -right-3 -top-3">
                  <button
                    className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-50 text-gray-500 shadow-lg"
                    onClick={() => onImageRemove(0)}
                  >
                    <Close sx={{ fontSize: 16 }} />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </ImageUploading>

      <div className="desc my-4 text-center">
        <h5 className="leading-[0.5]">{user_details.displayName}</h5>
        <span className="text-xs">{user_details.email}</span>
      </div>
      <div className="action-buttons flex flex-col space-y-2">
        <Button
          label="Upload New Photo"
          gradient
          fullWidth
          handleClick={() => handleUploadImage("upload")}
          isSubmitting={isUpdatingUpload}
          disabled={!images.length}
        />
        <Button
          label="Reset"
          fullWidth
          handleClick={() => handleUploadImage("reset")}
          variant="outlined"
          color="error"
          disabled={images.length > 0}
          isSubmitting={isUpdatingReset}
        />
        <span className="mt-3 text-xs text-gray-400">
          Allowed JPG or PNG. Max size of 500KB
        </span>
      </div>
    </div>
  );
}
