import React, { useState } from "react";
import { useSelector } from "react-redux";
import { WalletIcon } from "components";
import { useUpdateCurrency } from "api";
import { walletFiatProps } from "utils/constants";
import { Checkbox } from "@mui/material";

export default function DefaultCurrency() {
  const {
    profile: { user_details },
  } = useSelector((state) => state);

  const [defaultCurrency, setDefaultCurrency] = useState(null);

  const [isUpdatingCurr, isUpdatedCurr, updateCurrency] = useUpdateCurrency();

  return (
    <>
      <div className="mt-2 grid grid-cols-1 p-2 md:grid-cols-2 lg:grid-cols-2">
        <div className="col-span-1">
          <div className="rounded-sm bg-primary/5 px-4">
            {walletFiatProps.map((item) => (
              <div className="flex flex-row items-start justify-between border-b border-slate-200 py-4">
                <div className="flex flex-row">
                  <WalletIcon type={item.id} fontSize="small" />
                  <div className="ml-2 text-sm">
                    <div>{item.otherName}</div>
                    <div className="text-xs">{item.sym}</div>
                  </div>
                </div>
                <Checkbox
                  checked={user_details?.defaultCurrency === item.id}
                  size="small"
                  classes={{ root: "!p-0" }}
                  onChange={() => {
                    updateCurrency({ defaultCurrency: item.id });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
