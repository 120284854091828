import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";

import { FullWidthTabs, SetTimerLogout } from "components";
import { PageContainer } from "shared";
import Account from "./sub-pages/Account";
import Security from "./sub-pages/Security";
import Referrals from "./sub-pages/Referrals";
import BankAccounts from "./sub-pages/BankAccounts";
import Notifications from "./sub-pages/Notifications";
import ProfileImage from "./sub-pages/ProfileImage";
import DefaultCurrency from "./sub-pages/DefaultCurrency";
import { useBreakpoint } from "utils";
import { useLocation } from "react-router-dom";

export default function Index() {
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const { state } = location || {};

  const tabList = [
    ...(!breakpoint.md
      ? [{ label: "Profile Image", Icon: "InsertPhoto", Comp: ProfileImage }]
      : []),
    { label: "Account", Icon: "PersonOutline", Comp: Account },
    {
      label: "Default Currency",
      Icon: "MonetizationOnOutlined",
      Comp: DefaultCurrency,
    },
    { label: "Security", Icon: "LockOutlined", Comp: Security },
    {
      label: "Bank Accounts",
      Icon: "AccountBalanceOutlined",
      Comp: BankAccounts,
    },
    { label: "Referrals", Icon: "AddLink", Comp: Referrals },
    {
      label: "Notification",
      Icon: "NotificationAddOutlined",
      Comp: Notifications,
    },
    // { label: 'Verification', Icon: BookmarkAddedOutlined, Comp:  },
  ];

  const { logoutTimer } = useSelector((state) => state.auth);
  const [currentTab, setCurrentTab] = useState(
    tabList[breakpoint.md ? state?.page - 1 || 0 : state?.page || 1]
  );

  return (
    <PageContainer header="Settings">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
        {breakpoint.md && (
          <div className="col-span-1 md:col-span-1">
            <Fade>
              <ProfileImage />
            </Fade>
          </div>
        )}
        <div className="col-span-1 md:col-span-3">
          <Fade>
            <div className="overflow-hidden rounded-sm border border-slate-200 bg-white p-4 shadow-card-box">
              <FullWidthTabs
                {...{ currentTab, setCurrentTab, tabList }}
                Comp={<currentTab.Comp {...currentTab} />}
              />
            </div>
          </Fade>
        </div>
      </div>
      <SetTimerLogout logoutTimer={logoutTimer} />
    </PageContainer>
  );
}
