import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  BackdropSection,
  Button,
  EnterPIN,
  IconBox,
  NoDisplay,
} from "components";
import { kebabCase, lowerCase, pick } from "lodash";
import { useGetFiatCharges, useWithdrawFundNaira } from "api";
import TotalTrans from "./shared/TotalTrans";
import OwnAccount from "./OwnAccount";
import OthersAccount from "./OthersAccount";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function WithdrawComp({
  label,
  account,
  setOpenMobileDrawer,
  setPage,
  displayPage,
  navigateBack,
}) {
  const {
    account: { withdrawalAccount = {} },
  } = useSelector((state) => state);

  const type = kebabCase(label);
  const accounts = account ? withdrawalAccount?.[account] : null;

  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [bankDetails, setBankDetails] = useState(null);
  const [amount, setAmount] = useState("");
  const [saveBene, setSaveBene] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [isSubmitting, isSubmitted, submitWithdraw] = useWithdrawFundNaira();

  const { nairaWallet } = useSelector((state) => state.account || {});

  const chargesObj = useGetFiatCharges("naira");
  const { minAmount, maxAmount, charges, maintainMinimum } = chargesObj || {};

  const navigate = useNavigate();

  const handleAmtChange = (val) => {
    const e = val;
    const total = parseInt(e) + charges;
    const limitLow = parseInt(e) < 1000;
    const limitHigh = parseInt(e) > 1000000;
    const overlimit = total >= parseInt(nairaWallet?.amount || 0);
    const overcharges = parseInt(nairaWallet?.amount || 0) - total < 50;

    setAmount(e);
    setTotalAmt(total);
    setErrorAmt(overlimit || overcharges || limitLow || limitHigh);
    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? "Withdrawal must be ₦1000 and above"
        : limitHigh
        ? "Withdrawal must be ₦1000000 and below"
        : overcharges
        ? "Maintain a minimum of ₦50 in wallet"
        : ""
    );
  };

  const processWithdraw = () => {
    const bankDetailsPicked = pick(bankDetails, [
      "bankName",
      "accountName",
      "bankAccountNo",
      "bankCode",
    ]);
    const values = {
      productType: `withdrawal-naira`,
      accountType: type,
      amountToDebit: parseInt(totalAmt),
      amount: parseInt(amount),
      totalAmount: parseInt(totalAmt),
      type: "naira",
      withdrawDetails: {
        amount: {
          amount: parseInt(amount),
          charges,
          totalAmount: parseInt(totalAmt),
          maintainMax: parseFloat(maintainMinimum),
          minAmount: parseFloat(minAmount),
          maxAmount: parseFloat(maxAmount),
        },
        accountDetails: bankDetailsPicked,
      },
    };

    submitWithdraw({
      values,
      code: bankDetails?.bankCode,
      saveBene,
    });
  };

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setAmount("");
      setTotalAmt(0);
      setSaveBene(false);
      setBankDetails(null);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        if (setOpenMobileDrawer) setOpenMobileDrawer(false);

        if (navigateBack) {
          navigate(-1);
        } else if (setPage) {
          setPage(0);
        }
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  useEffect(() => {
    setBankDetails(null);
    setAmount("");
    setSaveBene(false);
    setTotalAmt(0);
    setErrorAmt(false);
    setErrorText(null);
  }, [type]);

  const Block = type === "other-account" ? OthersAccount : OwnAccount;

  const accountL = ["own-account", "beneficiary-account"];

  const bankDetailsHasProps = (arr) => {
    return arr.every((item) => bankDetails?.hasOwnProperty(item));
  };

  const isDisabled = !(
    bankDetailsHasProps([
      "bankAccountNo",
      "accountName",
      "bankName",
      "bankCode",
      "isVerified",
    ]) &&
    bankDetails &&
    parseInt(amount) &&
    !errorAmt
  );

  if (!displayPage) {
    return <NoDisplay type="withdrawal" />;
  }

  return (
    <>
      <div className="mt-4 p-2">
        {chargesObj ? (
          <>
            {(accounts?.length && accountL.includes(type)) ||
            type === "other-account" ? (
              <div className="grid grid-cols-2 gap-6">
                <Block
                  {...{
                    errorAmt,
                    errorText,
                    handleAmtChange,
                    processWithdraw,
                    accounts,
                    setBankDetails,
                    bankDetails,
                    amount,
                    label,
                  }}
                />

                <TotalTrans {...{ totalAmt, charges }} />

                <div className="col-span-2">
                  {bankDetails && type === "other-account" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveBene}
                          size="small"
                          onChange={() => setSaveBene(!saveBene)}
                        />
                      }
                      label="Save Beneficiary"
                      classes={{ label: "!text-sm ml-2" }}
                    />
                  )}
                  <div className="mt-1">
                    <Button
                      label="Continue"
                      fullWidth
                      gradient
                      handleClick={() => setOpen(true)}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <IconBox icon={"PriorityHigh"} gradient />
                <p className="mt-3 text-sm">
                  No {lowerCase(type)} details saved.
                </p>
                {type === "own-account" && (
                  <div className="action mt-4">
                    <div className="action-buttons">
                      <Button
                        label="Save Own Account"
                        gradient
                        link="/settings"
                        state={{ page: 4 }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="relative h-[150px]">
            <BackdropSection open={true} />
          </div>
        )}
      </div>
      <EnterPIN
        setOpen={setOpen}
        open={open}
        processSubmit={processWithdraw}
        openIsSubmitted={openIsSubmitted}
        setIsSubmitted={setIsSubmitted}
        btnText="Withdraw Fund"
        isSubmitting={isSubmitting}
      />
    </>
  );
}
