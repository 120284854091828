/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Popover } from "@headlessui/react";
import Slide from "react-reveal/Slide";
import {
  MenuRounded as MenuIcon,
  CloseRounded as XIcon,
  GridView,
  AccountBalanceWalletOutlined,
  Addchart,
  CardGiftcardOutlined,
  BubbleChartOutlined,
  SettingsOutlined,
} from "@mui/icons-material";

import { classNames } from "utils";
import NavbarMobileDrawer from "./NavbarMobileDrawer";
import Avatar from "./Avatar";
import Notification from "./Notification";

const navigation = [
  { name: "Dashboard", linkTo: "/dashboard", Icon: GridView },
  { name: "Trade Giftcard", linkTo: "/giftcard", Icon: CardGiftcardOutlined },
  { name: "Wallets", linkTo: "/wallets", Icon: AccountBalanceWalletOutlined },
  { name: "Utilities", linkTo: "/utilities", Icon: BubbleChartOutlined },
  { name: "Transactions", linkTo: "/transactions", Icon: Addchart },
  { name: "Settings", linkTo: "/settings", Icon: SettingsOutlined },
];

export default function NavbarBottom({ logout, avatarUrl }) {
  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();

  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset > 24);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const Block = ({ children }) => {
    return <>{offset ? <div top>{children}</div> : <div>{children}</div>}</>;
  };

  return (
    <>
      <Block>
        <header
          className={classNames(
            // fixed
            offset ? "fixed top-0 left-0 right-0" : "relative animate-none",
            " h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow-header md:block"
          )}
        >
          <nav
            aria-label="Top"
            className="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            {/* large screen */}
            <div className="hidden md:block">
              <div className="flex h-16 items-center">
                <button
                  type="button"
                  className="rounded-md bg-white p-2 text-gray-400 md:hidden"
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Flyout menus */}
                {/* hidden  */}
                <Popover.Group className="md:block md:self-stretch lg:ml-8">
                  <div className="flex h-full space-x-6">
                    {navigation.map((page) => (
                      <Link
                        key={page.name}
                        to={page.linkTo}
                        className={classNames(
                          pathname.includes(page.linkTo)
                            ? // mt-2 rounded-t-lg
                              "bg-current-nav"
                            : "",
                          "nav-link flex items-center p-2 text-sm font-medium text-white hover:text-slate-50"
                        )}
                      >
                        <page.Icon className="h-4 w-4" sx={{ fontSize: 18 }} />

                        <span className="ml-1 text-sm font-bold leading-[5px]">
                          {page.name}
                        </span>
                      </Link>
                    ))}
                  </div>
                </Popover.Group>

                <div className="ml-auto hidden flex-1 items-center justify-end space-x-6 lg:flex">
                  <button
                    className="text-sm font-medium text-white hover:text-gray-200"
                    onClick={logout}
                  >
                    Sign Out
                  </button>
                  <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                  <Link
                    to="/download-app"
                    className="text-sm font-medium text-white hover:text-gray-200"
                  >
                    Download App
                  </Link>
                </div>
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="flex h-full items-center justify-between md:hidden">
              <div className="flex flex-row items-center justify-center">
                <Avatar avatarUrl={avatarUrl} logout={logout} />
                <Notification />
              </div>
              <button
                className="inline-flex h-10 w-10 items-center justify-center rounded-sm bg-transparent p-2 text-white focus:outline-none"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </nav>
        </header>
      </Block>
      <NavbarMobileDrawer {...{ open, setOpen, logout }} />
    </>
  );
}
