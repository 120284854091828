/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Slide } from "react-reveal";
import {
  TextField,
  SelectChip,
  Button,
  EnterPIN,
  Autocomplete,
} from "components";
import { useGetUtilityList, usePurchaseUtiliity } from "api";

import { useNavigate } from "react-router-dom";
import { toLower } from "lodash";

export default function BuyAirtime() {
  const navigate = useNavigate();

  // useState Hooks
  const [open, setOpen] = useState(false);
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [network, setNetwork] = useState(null);
  const [phoneN, setPhoneN] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const { nairaWallet } = useSelector((state) => state.account || {});

  const [isSubmitting, isSubmitted, purchaseUtility] = usePurchaseUtiliity();

  const [utilityList, isLoadingList] = useGetUtilityList("airtime");

  useEffect(() => {
    const total = parseInt(amount);
    const limitLow = parseInt(amount) < 100;
    const limitHigh = parseInt(amount) > 50000;
    const overlimit = total >= parseInt(nairaWallet?.amount || 0);
    const maintainMaximum = parseInt(nairaWallet?.amount || 0) - total < 50;

    setTotalAmt(total);
    setErrorAmt(overlimit || maintainMaximum || limitLow || limitHigh);
    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? `Purchase must be ₦100 and above.`
        : limitHigh
        ? `You can only purchase airtime amount of ₦50000 nd below`
        : maintainMaximum
        ? `Maintain a minimum of ₦50 in wallet`
        : ""
    );
  }, [amount]);

  const handlePhoneInput = (val) => {
    let e = val.target.value.replace(/[^0-9]/g, "");
    if (e.length > 10) {
      setPhoneN(e.slice(0, 10));
    } else {
      setPhoneN(e);
    }
  };

  const handleAmtChange = (val) => {
    let e = val.replace(/[^0-9]/g, "");

    if (e.length > 12) {
      setAmount(e.slice(0, 12));
    } else {
      setAmount(e);
    }
  };

  const handlePurchase = () => {
    const { name, product_id: productId, service_type: serviceType } = network;
    const values = {
      amountToDebit: parseInt(amount),
      provider: name,
      providerName: toLower(name),
      beneficiary: `0${phoneN}`,
      productType: "airtime",
      productPlan: null,
      amount: parseInt(amount),
      transactionDetails: {
        productId,
        phone: `0${phoneN}`,
        amount: parseInt(amount, 10),
        serviceType,
      },
    };

    purchaseUtility(values);
  };

  useEffect(() => {
    let timerId = () => {};
    if (isSubmitted) {
      setIsSubmitted(true);
      setAmount("");
      setTotalAmt(0);
      setOpen(false);
      timerId = setTimeout(() => {
        setIsSubmitted(false);
        setPhoneN("");
        navigate(-1);
      }, 2000);
    }
    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  return (
    <div className="">
      <div className="rounded-sm border border-gray-200 bg-white p-4 shadow-card-box">
        <div className="mt-2 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <div className="col-span-2">
            <Autocomplete
              inputLabel="Provider"
              options={utilityList}
              titleValue="name"
              handleChange={setNetwork}
              valueAlt={network}
              disabled={isSubmitting}
              isLoading={isLoadingList}
            />
          </div>

          <div className="col-span-2">
            <TextField
              name="phoneNumber"
              type="text"
              label="Recipient Phone Number"
              value={phoneN || ""}
              handleChange={handlePhoneInput}
              disableAutoComplete
              placeholder="8100200300"
              startAdornment={<div className="">+234</div>}
              disabled={isSubmitting}
            />
          </div>

          <div className="col-span-2">
            <SelectChip
              title="Select Amount"
              list={["100", "200", "500", "1000", "2000", "5000"]}
              handleClick={handleAmtChange}
              currData={amount || ""}
            />
          </div>

          <div className="col-span-2">
            <TextField
              name="amount"
              // type="text"
              label="Amount"
              value={amount}
              handleChange={handleAmtChange}
              startAdornment="₦"
              formatInput
              disableAutoComplete
              placeholder="0.00"
              disabled={isSubmitting}
            />
            {errorAmt && (
              <Slide down duration={300}>
                <div className="mt-2 text-xs font-normal text-red-500">
                  {errorText}
                </div>
              </Slide>
            )}
          </div>

          <div className="col-span-2">
            <Button
              label="Continue"
              fullWidth
              gradient
              disabled={
                !(
                  parseInt(amount) &&
                  !errorAmt &&
                  phoneN &&
                  phoneN.length === 10 &&
                  network
                )
              }
              handleClick={() => setOpen(true)}
            />
          </div>
        </div>
      </div>
      <EnterPIN
        open={open}
        setOpen={setOpen}
        openIsSubmitted={openIsSubmitted}
        setIsSubmitted={setIsSubmitted}
        processSubmit={handlePurchase}
        btnText={"Purchase Airtime"}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}
