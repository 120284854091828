import React, { useState, useEffect } from "react";
import { startCase } from "lodash";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import { Fade, Slide } from "react-reveal";
import { Divider } from "@mui/material";

import { classNames, useBreakpoint } from "utils";
import { SectionContent, SimpleIcon, Drawer } from ".";

export function HorizontalTab({ list, contents }) {
  const [active, setActive] = useState(0);

  const content = contents[active];

  return (
    <div>
      <ul className="flex items-center justify-start">
        {list.map((item, index) => (
          <li
            key={item}
            className={classNames(
              active === index
                ? " rounded bg-[#6967ce] text-white shadow-[0_5px_35px_0_rgba(0,0,0,0.3)]"
                : "",
              "text-center"
            )}
          >
            <button
              className="h-full w-full p-4 text-sm"
              onClick={() => setActive(index)}
            >
              {startCase(item)}
            </button>
          </li>
        ))}
      </ul>
      <div className="mt-6 p-2">{content}</div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-full rounded bg-slate-50 shadow-header"
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    classes: {
      root: "!capitalize !px-8 !items-start",
    },
  };
}

export function VerticalTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        // bgcolor: 'background.paper',
        display: "flex",
        // height: 224,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
        classes={{
          vertical: "mr-5 bg-slate-50 shadow-header rounded !py-4",
          indicator: "!w-[4px] rounded",
        }}
      >
        <Tab label="Profile" {...a11yProps(0)} />
        <Tab label="Referral" {...a11yProps(1)} />
        <Tab label="Item Three" {...a11yProps(2)} />
        <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </Box>
  );
}

export function FullWidthTabs2({ tabList, tabPanel, currentPage }) {
  const [value, setValue] = useState(currentPage ?? 0);
  const [showContent, setContent] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   const scroll = window.scrollTo(0, 0);
  //   return scroll;
  // }, [showContent]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <SectionContent>
      <Box sx={{ width: "100%", position: "relative" }}>
        <AppBar
          position="static"
          // !bg-white !shadow-card-box border border-slate-200
          classes={{
            root: "text-white",
          }}
        >
          <Tabs
            value={value}
            orientation={!matches ? "vertical" : "horizontal"}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            TabScrollButtonProps={{
              classes: { root: "!text-gray-700 !lowercase" },
            }}
            classes={{
              flexContainer: "!items-start",
              indicator: !matches ? "!w-1 rounded" : "!h-1 rounded",
            }}
          >
            {tabList.map((item, index) => (
              <Tab
                icon={<item.Icon sx={{ fontSize: 20 }} />}
                iconPosition="start"
                label={item.label}
                id={`full-width-tab-${index}`}
                classes={{
                  root: classNames(
                    !matches
                      ? "w-full !items-center !justify-start !capitalize"
                      : "",
                    "!min-h-[50px] !capitalize !text-white"
                  ),
                  iconWrapper: "mr-2",
                }}
                onClick={() => setContent(true)}
                {...(!matches && {
                  component: (props) => (
                    <div className="flex w-full flex-col">
                      <button {...props}>
                        <div className="">{props.children}</div>
                      </button>
                      {!matches && <Divider />}
                    </div>
                  ),
                })}
              />
            ))}
          </Tabs>
        </AppBar>

        {(matches || showContent) && (
          <Slide {...(!matches && { right: true, duration: 500 })}>
            <div
              className={classNames(
                !matches ? "absolute top-0 w-full" : "",
                ""
              )}
            >
              {tabPanel.map((item, index) => (
                <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`full-width-tabpanel-${index}`}
                  aria-labelledby={`full-width-tab-${index}`}
                  // dir={theme.direction}
                >
                  {!matches && (
                    <div className="flex items-center p-2 text-color-main">
                      <button onClick={() => setContent(false)}>
                        <ArrowCircleLeftOutlinedIcon className="iconShadow-alt !text-3xl" />
                      </button>
                      <h5 className="ml-2 font-bold">{item.label}</h5>
                    </div>
                  )}

                  {value === index && (
                    <Box>
                      <item.Comp {...(item.compProps && item.compProps)} />
                    </Box>
                  )}
                </div>
              ))}
            </div>
          </Slide>
        )}
      </Box>
    </SectionContent>
  );
}

export function FullWidthTabs({ tabList, currentTab, setCurrentTab, Comp }) {
  const breakpoint = useBreakpoint();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="overflow-hidden bg-white">
      <AppBar
        // color="light"
        position="static"
        classes={{
          root: classNames("text-white"),
        }}
      >
        <div
          className={classNames(
            "flex",
            breakpoint.md ? "flex-row items-center" : "flex-col items-start"
          )}
        >
          {tabList.map((item) => (
            <button
              key={item.label}
              className={classNames(
                "flex flex-row items-center pr-4 text-white",
                breakpoint.md
                  ? "p-4"
                  : "w-full border-b border-divider py-4 px-3",
                currentTab.label === item.label ? "bg-current-nav" : ""
              )}
              onClick={() => {
                setCurrentTab(item);
                setOpenDrawer(true);
              }}
            >
              <SimpleIcon
                icon={item.Icon}
                fontSize={18}
                frontColor="text-white"
              />
              <span className="ml-2 font-medium">{item.label}</span>
            </button>
          ))}
        </div>
      </AppBar>
      {breakpoint.md ? (
        <Fade spy={currentTab} duration={500}>
          {Comp}
        </Fade>
      ) : (
        <Drawer
          anchor="right"
          state={openDrawer}
          setState={setOpenDrawer}
          title={currentTab.label}
        >
          <div className="drawer-content p-2">{Comp}</div>
        </Drawer>
      )}
    </div>
  );
}
