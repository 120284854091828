import React from "react";
import { SimpleIcon } from "components";
import { useDropDownVisible } from "./components/CustomInput";
import { CustomDropdown } from "pages/Wallets/components/CustomInput";
import CalendarPicker from "./components/CalendarPicker";

export default function TransactionDrawer({
  tabList,
  setTrans,
  selectTrans,
  queryDates,
  setQueryDates,
  isTransactionListLoading,
}) {
  return (
    <div className="relative ml-0 mb-4 flex h-full min-w-[300px] flex-col rounded-sm bg-white md:ml-4 md:mb-0">
      <div className="mb-4 p-2 shadow-card-box">
        <div className="w-full rounded-sm bg-slate-50 p-2">
          <div className="mb-0 flex items-center">
            <h6 className="pb-0 text-sm font-bold uppercase tracking-wide text-gray-500">
              Filter by type
            </h6>
          </div>

          <CustomDropdown
            currentWallet={selectTrans}
            setCurrentWallet={(e) => {
              const eInd = tabList.find((item) => item.id === e.id);
              setTrans(eInd);
            }}
            dropdownList={tabList}
            dropdownId="transaction-select"
            ClickButtonComponent={() => (
              <div className="pointer-events-none relative mt-2 flex w-full flex-1 flex-row items-center justify-between">
                <div className="mr-2 flex flex-col items-start justify-between">
                  <div className="text-sm font-semibold text-primary">
                    {selectTrans?.name}
                  </div>
                </div>
              </div>
            )}
            DropdownComponent={(item) => (
              <div className="pointer-events-none flex flex-row items-center py-1">
                <SimpleIcon
                  fontSize={18}
                  icon={"TableChart"}
                  frontColor={"text-dark"}
                  otherClass=""
                />
                <div className="ml-2 text-sm">{item.name}</div>
              </div>
            )}
          />
        </div>
      </div>

      <div className="p-2 shadow-card-box">
        <div className="relative order-first md:order-last">
          <div className="rounded-sm bg-slate-50 p-2">
            <div className="mb-2 flex items-center">
              <h6 className="text-sm font-bold uppercase tracking-wide text-gray-500">
                Filter by date
              </h6>
              {/* <button>
              <CancelIcon className="text-color-main" />
            </button> */}
            </div>
            <div>
              <CalendarPicker
                {...{
                  queryDates,
                  setQueryDates,
                  isTransactionListLoading,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
