import { useCallback, useEffect, useState } from "react";
import { axiosCall } from "api/helpers";
import * as i from "../lib";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { walletFiatBonusProps, walletFiatProps } from "utils/constants";
import {
  getFiatAuth,
  getUtilityAuth,
  getCurrentWalletTab,
  getDrawerStatus,
  getPhoneList,
  getAdvertPopup,
  getPortalImages,
} from "redux/reducers/utilReducers";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}${endpoint}`;

export const useVerifyTransferEmail = () => {
  const [isLoading, setLoading] = useState(false);
  const [getDetails, setGetDetails] = useState({ details: null, error: false });

  const verifyDetails = async ({ transferUserEmail }) => {
    try {
      setLoading(true);
      setGetDetails({ details: null, error: false });

      const resp = await axiosCall(
        url("/fiat/check_tranfer_user"),
        { transferUserEmail },
        true
      );

      const resIsValid = resp.data.data;
      setLoading(false);
      setGetDetails({ details: resIsValid.transferUser, error: false });
    } catch (error) {
      setLoading(false);
      const msg =
        error === '"transferUserEmail" must be a valid email'
          ? "Invalid Email"
          : error ===
            "There is no user record corresponding to the provided identifier."
          ? "User does not exist"
          : error === "Cannot transfer funds to owner account"
          ? "Funds cannot be transfered to same account"
          : "Cannot fetch user. An error occured";
      setGetDetails({ details: msg, error: true });
    }
  };
  return [isLoading, getDetails, setGetDetails, verifyDetails];
};

export const useGetUSDFiatRate = () => {
  const [rate, setRate] = useState(null);

  async function requestPricesFiatCurr() {
    try {
      const usdFiatCurr = await axios.get(
        "https://api.coinbase.com/v2/exchange-rates"
      );

      // console.log(usdFiatCurr, "usdFiatCurr");
      const { NGN, GHS, XOF } = usdFiatCurr.data.data.rates;
      return {
        NGN: parseFloat(NGN),
        GHS: parseFloat(GHS),
        XOF: parseFloat(XOF),
      };
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    let getPrice = () => {};
    try {
      const getR = async () => {
        const data = await requestPricesFiatCurr();
        setRate(data);
      };

      getR();
      getPrice = setInterval(() => {
        getR();
      }, 60000 * 24);
    } catch (error) {
      setRate(null);
    }

    return () => clearInterval(getPrice);
  }, []);

  return rate;
};

export const useFiatBonusWalletListProps = () => {
  const { cedisWallet, nairaWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  const {
    amount: nairaAmount,
    nairaBonus,
    updated_at: updatedAtNaira,
  } = nairaWallet || {};
  const {
    amount: cedisAmount,
    cedisBonus,
    updated_at: updatedAtCedis,
  } = cedisWallet || {};
  const {
    amount: cefaAmount,
    cefaBonus,
    updated_at: updatedAtCefa,
  } = cefaWallet || {};

  const rate = useGetUSDFiatRate();

  return useCallback(() => {
    const wallet = walletFiatBonusProps.map((item) => {
      const r = rate?.[item?.sym] || 0;
      let amount, updatedAt;

      if (item.id === "naira") {
        amount = nairaAmount || 0;
        updatedAt = updatedAtNaira;
      } else if (item.id === "cedis") {
        amount = cedisAmount || 0;
        updatedAt = updatedAtCedis;
      } else if (item.id === "cefa") {
        amount = cefaAmount || 0;
        updatedAt = updatedAtCefa;
      } else if (item.id === "nairaBonus") {
        amount = nairaBonus || 0;
        updatedAt = updatedAtNaira;
      } else if (item.id === "cedisBonus") {
        amount = cedisBonus || 0;
        updatedAt = updatedAtCedis;
      } else if (item.id === "cefaBonus") {
        amount = cefaBonus || 0;
        updatedAt = updatedAtCefa;
      }

      const amountType = amount;
      const amountUSD = amount / r;

      return { ...item, amount: amountType, amountUSD, updatedAt };
    });

    const walletProps = wallet;
    let isLoadedWalletProps = false;
    if (rate) {
      isLoadedWalletProps = true;
    } else {
      isLoadedWalletProps = false;
    }
    return { walletProps, isLoadedWalletProps };
  }, [rate, cedisWallet, nairaWallet, cefaWallet])();
};

export const useFiatWalletListProps = () => {
  const { cedisWallet, nairaWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  const { amount: nairaAmount } = nairaWallet || {};
  const { amount: cedisAmount } = cedisWallet || {};
  const { amount: cefaAmount } = cefaWallet || {};

  const rate = useGetUSDFiatRate();

  return useCallback(() => {
    const wallet = walletFiatProps.map((item) => {
      const r = rate?.[item?.sym] || 0;
      let amount;

      if (item.id === "naira") {
        amount = nairaAmount || 0;
      } else if (item.id === "cedis") {
        amount = cedisAmount || 0;
      } else if (item.id === "cefa") {
        amount = cefaAmount || 0;
      }

      const amountType = amount;
      const amountUSD = amount / r;

      return { ...item, amount: amountType, amountUSD };
    });

    const walletProps = wallet;
    let isLoadedWalletProps = false;
    if (rate) {
      isLoadedWalletProps = true;
    } else {
      isLoadedWalletProps = false;
    }
    return { walletProps, isLoadedWalletProps };
  }, [rate, cedisWallet, nairaWallet, cefaWallet])();
};

export const useGetFiatTransAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        const q = i.doc(i.db, "utils", "fiat");

        unsub = i.onSnapshot(q, (doc) => {
          dispatch(getFiatAuth(doc.data()));
        });
      } catch (error) {
        dispatch(getFiatAuth(null));
      }
    } else {
      dispatch(getFiatAuth(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useGetUtilityTransAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        const q = i.doc(i.db, "utils", "utility");

        unsub = i.onSnapshot(q, (doc) => {
          dispatch(getUtilityAuth(doc.data()));
        });
      } catch (error) {
        dispatch(getUtilityAuth(null));
      }
    } else {
      dispatch(getUtilityAuth(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useGetSiteFiatRate = () => {
  const [rate, setRate] = useState(null);

  useEffect(() => {
    let unsub = () => {};
    try {
      const q = i.doc(i.db, "utils", "fiatRate");

      unsub = i.onSnapshot(q, (doc) => {
        const { GHS, XOF, NGN } = doc?.data();
        setRate({
          cedisRate: GHS.rate,
          cefaRate: XOF.rate,
          nairaRate: NGN.rate,
        });
      });
    } catch (error) {
      setRate(null);
    }
    return () => {
      unsub();
    };
  }, []);

  return rate;
};

export const useGetFiatCharges = (type) => {
  const [rate, setRate] = useState(null);

  useEffect(() => {
    let unsub = () => {};
    try {
      const q = i.doc(i.db, "utils", "charges");

      unsub = i.onSnapshot(q, (doc) => {
        const { maxAmount, minAmount, charges, maintainMinimum } = doc.data();
        setRate({
          maxAmount,
          minAmount,
          maintainMinimum,
          charges: charges?.[type],
        });
      });
    } catch (error) {
      setRate(null);
    }
    return () => {
      unsub();
    };
  }, []);

  return rate;
};

export const useGetPhoneList = () => {
  const dispatch = useDispatch();
  const user = auth?.currentUser;

  useEffect(() => {
    let unsub;

    try {
      if (user) {
        const q = i.doc(i.db, "utils", "transferPhoneNos");

        unsub = i.onSnapshot(q, (doc) => {
          dispatch(getPhoneList(doc.data()));
        });
      }
    } catch (error) {
      dispatch(getPhoneList(null));
    }
    return unsub;
  }, [dispatch, user]);
};

export const useGetPortalImage = () => {
  const dispatch = useDispatch();
  const user = auth?.currentUser;

  useEffect(() => {
    let unsub;

    try {
      if (user) {
        const q = i.doc(i.db, "utils", "portalImages");

        unsub = i.onSnapshot(q, (doc) => {
          dispatch(getPortalImages(doc.data()?.imagesWeb));
        });
      }
    } catch (error) {
      dispatch(getPortalImages(null));
    }
    return unsub;
  }, [dispatch, user]);
};

export const useGetUtilityCommission = () => {
  //  const dispatch = useDispatch();
  const user = auth?.currentUser;
  const [commission, setCommission] = useState(null);

  useEffect(() => {
    let unsub;

    try {
      if (user) {
        const q = i.doc(i.db, "utils", "charges");
        unsub = i.onSnapshot(q, (doc) => {
          //  dispatch(getPhoneList(doc.data()));

          const { airtimeToCashCommision } = doc?.data() || {};
          setCommission(airtimeToCashCommision);
        });
      }
    } catch (error) {
      setCommission(null);
      //  dispatch(getPhoneList(null));
    }
    return unsub;
  }, [user]);

  // useEffect(() => {
  //   let unsub = () => {};
  //   try {
  //     unsub = func.onSnapshotDoc(
  //       { collection: "utils", document: "charges" },
  //       (doc) => {
  //         const { airtimeToCashCommision } = doc?.data() || {};
  //         setCommission(airtimeToCashCommision);
  //       }
  //     );
  //   } catch (error) {
  //     setCommission(null);
  //   }
  //   return () => {
  //     if (typeof unsub === "function") unsub();
  //   };
  // }, []);

  return commission;
};

export const useGetAdvertPopup = () => {
  const dispatch = useDispatch();
  const user = auth?.currentUser;

  useEffect(() => {
    let unsub;

    try {
      if (user) {
        const q = i.doc(i.db, "utils", "siteAdvertPopup");

        unsub = i.onSnapshot(q, (doc) => {
          dispatch(getAdvertPopup(doc.data()));
        });
      }
    } catch (error) {
      dispatch(getAdvertPopup(null));
    }
    return unsub;
  }, [dispatch, user]);
};

export const appCheck = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const appCheckTokenResponse = await i.getToken(i.firebaseAppCheck, true);
      resolve(appCheckTokenResponse.token);
    } catch (err) {
      return reject(
        "Token cannot be retrieved. Kindly refresh page and try again."
      );
    }
  });
};

export const useGetDrawerStatus = () => {
  const dispatch = useDispatch();

  const getStatus = (state) => {
    dispatch(getDrawerStatus(state));
  };

  return [getStatus];
};

export const useGetCurrentWalletTab = () => {
  const dispatch = useDispatch();

  const getCurrentTab = (state) => {
    dispatch(getCurrentWalletTab(state));
  };

  return [getCurrentTab];
};
