import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nairaWallet: {},
  cedisWallet: {},
  cefaWallet: {},
  walletStatus: {
    currentWallet: null,
    currentWalletAction: null,
  },
  depositAccount: { isLoaded: false, userDepositAcct: null },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getNairaWallet: (state, { payload }) => {
      state.nairaWallet = payload.nairaWallet;
    },
    getCedisWallet: (state, { payload }) => {
      state.cedisWallet = payload.cedisWallet;
    },
    getCefaWallet: (state, { payload }) => {
      state.cefaWallet = payload.cefaWallet;
    },
    getWithdrawalAccount: (state, { payload }) => {
      state.withdrawalAccount = payload.withdrawalAccount;
    },
    getUtilitiesBeneficiary: (state, { payload }) => {
      state.utilityBeneficiary = payload.utilityBeneficiary;
    },
    getDepositAccount: (state, { payload }) => {
      state.depositAccount = { ...state.depositAccount, ...payload };
    },
    getWalletStatus: (state, { payload }) => {
      state.walletStatus = { ...state.walletStatus, ...payload };
    },
  },
});

export const {
  getNairaWallet,
  getCedisWallet,
  getCefaWallet,
  getCryptoWallet,
  getWithdrawalAccount,
  getUtilitiesBeneficiary,
  getDepositAccount,
  getWalletStatus,
} = walletSlice.actions;

export default walletSlice.reducer;
