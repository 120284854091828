import React from "react";
import { PageContainer } from "shared";
import { products } from "shared/constants";
import { UtilityCard } from "components";

export default function Index() {
  return (
    <PageContainer header="Utilities">
      <div className="overflow-hidden rounded-sm border border-slate-200 bg-white p-4 shadow-card-box">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-2 xl:p-0 ">
          {products.map((product) => (
            <UtilityCard product={product} key={product.title} />
          ))}
        </div>
      </div>
      <div className="mt-8" />
    </PageContainer>
  );
}
