import React from "react";

export default function Footer() {
  return (
    <footer className="footer bottom-0 flex h-14 w-full items-center justify-center bg-white px-6 text-sm text-gray-500 shadow">
      <div>
        Copyright © 2024{" "}
        <a href="/" className="text-decoration-none">
          Kaapo
        </a>
        <span className="d-none d-md-inline">. All Rights Reserved</span>
      </div>
    </footer>
  );
}
